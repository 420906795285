import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    clear () {
    }
  },
  actions: {

    async loadFinance (_, payload) {
      try {
        const { data } = await axios.get('/account/detailing', { params: payload });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
  }
};
