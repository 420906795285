<template>
  <teleport
    v-if="accountError"
    to="body"
  >
    <AppModal
      title="Сервис временно недоступен"
      @close="closAccountErrorMessage"
    >
      <p class="error__description">
        Обновите страницу или попробуйте зайти позже.
      </p>
      <AppButton
        fluid
        @click="closAccountErrorMessage"
      >
        Понятно
      </AppButton>
    </AppModal>
  </teleport>
  <div
    v-else-if="!isLoading"
    :class="['page', {'page_noaside': asideType==='noaside'}, 'mts-d-flex', 'flex-column']"
  >
    <the-navbar
      :show-account="needAccountInNavbar"
      :aside-type="asideType"
    />

    <router-view />

    <the-footer />
  </div>
  <AppLoader
    v-else
    class="main__loader"
    size="i"
  />
  <teleport to="body">
    <AppModal
      v-if="isDocumentModal"
      title="Документ на подпись"
      @close="isDocumentModal = false"
    >
      <p class="para popup__para">
        Необходимо ознакомиться и подписать документ
      </p>
      <AppButton
        fluid
        @click="$router.push('/settings/documents'); isDocumentModal = false"
      >
        К подписанию
      </AppButton>
    </AppModal>

    <AppModal
      v-if="isSetPasswordModal && !accountError"
      :max-width="(stagePasswordChange.stage.is(1) || stagePasswordChange.stage.is(2)) && 1012"
      :width-full="(stagePasswordChange.stage.is(1) || stagePasswordChange.stage.is(2))"
      @close="closeSetPassword"
    >
      <NewPasswordForm
        v-if="stagePasswordChange.stage.is(1)"
        class="set__password"
        close
        :description="changePasswordDescription"
        @close="closeSetPassword"
        @success="successSetPassword"
      />
      <CreatePasswordForm
        v-else-if="stagePasswordChange.stage.is(2)"
        class="set__password"
        title="Установка пароля для входа в Личный кабинет"
        close
        :description="setPasswordDescription"
        @close="closeSetPassword"
        @success="successSetPassword"
      />
      <AppSuccessInfo
        v-else
        title="Пароль установлен"
        title-close-btn="Закрыть"
        @ok="closeSetPassword"
      />
    </AppModal>
  </teleport>
  <AppMtsOpros v-if="userInfo" />
</template>

<script>
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TheFooter from '../components/TheFooter.vue';
import TheNavbar from '../components/TheNavbar.vue';
import AppModal from '@/components/ui/AppModal';
import AppButton from '@/components/ui/AppButton';
import AppMtsOpros from '@/components/ui/AppMtsOpros';
import AppLoader from '@/components/ui/AppLoader.vue';
import { useStage } from '@/hooks/stage';
import { useTypeLoginPassword, TYPE_LOGIN } from '@/hooks/useTypeLoginPassword';
const AppSuccessInfo = defineAsyncComponent(() => import('@/components/ui/AppSuccessInfo.vue'));
const NewPasswordForm = defineAsyncComponent(() => import('@/components/settings/entities/newPasswordForm.vue'));
const CreatePasswordForm = defineAsyncComponent(() => import('@/components/settings/entities/CreatePasswordForm.vue'));
export default {
  components: { CreatePasswordForm, AppSuccessInfo, NewPasswordForm, AppLoader, AppMtsOpros, AppButton, AppModal, TheFooter, TheNavbar },
  setup () {
    const route = useRoute();
    const store = useStore();

    const userInfo = computed(() => store.getters['user/userInfo']);
    const forSignedDocuments = computed(() => store.getters['settings/forSignedDocuments']);

    const isLoading = ref(true);

    const setPasswordDescription = 'Уважаемый абонент, с текущим паролем следующая попытка входа будет недоступна. Чтобы не потерять доступ установите надежный пароль и убедитесь, что установлены контактные данные.';
    const changePasswordDescription = 'Уважаемый абонент, рекомендуем сменить текущий пароль на надежный пароль для защиты доступа в личный кабинет.';
    const isDocumentModal = ref(false);
    const isSetPasswordModal = ref(false);
    const stagePasswordChange = useStage(1, 3, 1);
    const accountError = ref(false);

    const { getTypeLogin, getValidPassword } = useTypeLoginPassword();

    const closAccountErrorMessage = async () => {
      await store.dispatch('auth/logout');
      window.location.replace(`https://login.mts.ru/amserver/UI/Logout?goto=${process.env.VUE_APP_URI}`);
    };

    const watchMovePages = watch(() => route.path, newPath => {
      if (newPath === '/') {
        viewSetPassword();
      }
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        if (!userInfo.value) {
          const result = await Promise.allSettled([
            store.dispatch('user/load'),
            store.dispatch('user/getTargetGroup'),
            store.dispatch('settings/getDocuments'),
            store.dispatch('resource/load'),
            store.dispatch('services/specialOffers'),
            store.dispatch('cms/getCMSStock'),
            store.dispatch('services/dataForRequest'),
            store.dispatch('cms/getCMSBanners'),
            store.dispatch('cms/getCMSThemedBanners'),
            store.dispatch('cms/getSupportChapter')
          ]);
          result.forEach(el => {
            if (el.status === 'rejected') {
              viewError(el.reason);
            }
          });
          isDocumentModal.value = !!forSignedDocuments.value.length;
          const typeLogin = getTypeLogin();
          const passwordValid = getValidPassword();
          if (typeLogin === TYPE_LOGIN.chipId) {
            viewSetPassword();
            stagePasswordChange.stage.set(2);
            return;
          }
          if (typeLogin === TYPE_LOGIN.password && passwordValid === 'false') {
            viewSetPassword();
            stagePasswordChange.stage.set(1);
            return;
          }
          watchMovePages();
        }
      } catch (e) {
        viewError(e);
      } finally {
        if (userInfo.value) {
          isLoading.value = false;
        }
      }
    });

    function viewError (error) {
      if (error.name && error.name === 'account' && error.status !== 401) {
        accountError.value = true;
      }
    }

    const closeSetPassword = () => {
      isSetPasswordModal.value = false;
    };

    const viewSetPassword = () => {
      isSetPasswordModal.value = true;
    };

    const successSetPassword = () => {
      stagePasswordChange.stage.set(3);
      watchMovePages();
    };

    return {
      needAccountInNavbar: computed(() => route.meta.needAccountInNavbar),
      asideType: computed(() => route.meta.asideType),
      isDocumentModal,
      isSetPasswordModal,
      userInfo,
      accountError,
      closAccountErrorMessage,
      isLoading,
      closeSetPassword,
      stagePasswordChange,
      setPasswordDescription,
      changePasswordDescription,
      watchMovePages,
      successSetPassword
    };
  }
};
</script>

 <style scoped>
 .error__description {
   margin-bottom: 15px;
 }
 .main__loader {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }

 .set__password {
   padding: 10px;
 }
 </style>
