import { computed, reactive, ref, watch } from 'vue';
export const useCheckRulesPassword = () => {
  const isLoading = ref(false);
  const passwordNew = ref('');
  const passwordNewCopy = ref('');
  const rules = reactive({
    isSpace: false,
    isLength: false,
    isLatWords: false,
    isDigit: false,
    isOneUpperCase: false,
    isOneLowCase: false,
    isNotMatchPassword: false
  });

  const addInfoSpecialSymbol = 'Пароль может содержать следующие специальные символы: _ ! $ % & , ( ) ? # @ ^ . \' : ; + - < > = * \\ / } { [ ] | № ` ~ " ”';

  const validateList = computed(() => (
    [
      { rule: 'Нет пробелов', value: rules.isSpace },
      { rule: 'Минимум 8 символов', value: rules.isLength },
      { rule: 'Латинские буквы', value: rules.isLatWords, tooltip: addInfoSpecialSymbol },
      { rule: 'Цифры', value: rules.isDigit },
      { rule: 'Заглавные буквы', value: rules.isOneUpperCase },
      { rule: 'Строчные буквы', value: rules.isOneLowCase },
      { rule: 'Пароли не совпадают', value: rules.isNotMatchPassword }
    ]
  ));

  const isAllValid = computed(() => (
    rules.isSpace &&
    rules.isLength &&
    rules.isLatWords &&
    rules.isDigit &&
    rules.isOneUpperCase &&
    rules.isOneLowCase &&
    rules.isNotMatchPassword
  ));

  watch([passwordNewCopy, passwordNew], ([newValueMainPassword, newValuePassword]) => {
    const isUpperCase = /[A-Z]/.test(newValuePassword);
    const isLowerCase = /[a-z]/.test(newValuePassword);
    const isLatinLetters = /^[\w\s_!$%&,()?#@^.':;+<\->=*\\}{\[\]\/|№`~"”]+$/.test(newValuePassword);

    rules.isSpace = !/\s/.test(newValuePassword) && newValuePassword.length;
    rules.isLength = newValuePassword.length >= 8 && newValuePassword.length <= 30;
    rules.isOneUpperCase = isUpperCase && newValuePassword.length;
    rules.isOneLowCase = isLowerCase && newValuePassword.length;
    rules.isLatWords = isLatinLetters && newValuePassword.length && (rules.isOneUpperCase || rules.isOneLowCase);
    rules.isDigit = /\d/.test(newValuePassword);
    rules.isNotMatchPassword = newValuePassword === newValueMainPassword && newValuePassword && newValueMainPassword;
  });

  return {
    passwordNew,
    passwordNewCopy,
    validateList,
    isAllValid,
    isLoading
  };
};
