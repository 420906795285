<template>
  <button
    :class="['app__button',
             {'app__button-fluid':props.fluid},
             {'loading__button':props.loading},
             {'disabled__button': props.disabled && !props.loading},
             color,
             {'no-hover': noHover}]"
    :disabled="props.disabled || props.loading"
  >
    <AppLoader
      v-if="props.loading"
      :color="loadingColor"
      size="m"
    />
    <slot v-else />
  </button>
</template>

<script setup>
import AppLoader from '@/components/ui/AppLoader';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  fluid: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'button_primary'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loadingColor: {
    type: String,
    default: '#fff',
    required: false
  },
  noHover: {
    type: Boolean,
    default: false
  }
});
</script>

<style scoped>

.app__button{
  font-family: "MTS Sans", serif;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 24px;
  border-radius: 8px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: transform 0.1s ease;
  will-change: transform;
  width: fit-content;
  min-width: 142px;
}

.app__button:hover {
  transform: scale(1.04);
}

.app__button:hover:disabled {
  transform: none;
  cursor: not-allowed;
}

.no-hover:hover {
  transform: none;
}

.disabled__button {
  background-color: var(--secondary-grey-inactive);
  color: var(--secondary-grey-light);
}

.app__button-fluid {
  width: 100%;
}

@media (max-width: 767px) {
  .app__button {
    min-width: fit-content;
  }
}

</style>
