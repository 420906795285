<template>
  <app-page>
    <template #menu>
      <div class="page__aside">
        <div class="page__aside-nav">
          <router-link
            to="/settings/personal"
            class="page__aside-nav-link_personal page__aside-nav-link"
            href="#"
            @click="gtm.push_events({'event': 'vntNast', 'eventCategory': 'nastroiki', 'eventAction': 'element_click', 'eventLabel': 'lichnaya_informaciya'})"
          >
            Личная информация
          </router-link>
          <router-link
            v-if="signedDocuments.length || forSignedDocuments.length"
            to="/settings/documents"
            class="page__aside-nav-link_documents page__aside-nav-link"
            href="#"
            @click="gtm.push_events({'event': 'vntNast', 'eventCategory': 'nastroiki', 'eventAction': 'element_click', 'eventLabel': 'documenti'})"
          >
            Документы
          </router-link>
          <router-link
            to="/settings/password"
            class="page__aside-nav-link_password page__aside-nav-link"
            href="#"
            @click="gtm.push_events({'event': 'vntNast', 'eventCategory': 'nastroiki', 'eventAction': 'element_click', 'eventLabel': 'smena_parolya'})"
          >
            Смена пароля
          </router-link>
          <router-link
            to="/settings/informing"
            class="page__aside-nav-link_notices page__aside-nav-link"
            href="#"
            @click="gtm.push_events({'event': 'vntNast', 'eventCategory': 'nastroiki', 'eventAction': 'element_click', 'eventLabel': 'nastroiki_uvedomlenii'})"
          >
            Настройка уведомлений
          </router-link>
          <router-link
            to="/settings/notifications"
            class="page__aside-nav-link_notice-log page__aside-nav-link"
            href="#"
            @click="gtm.push_events({'event': 'vntNast', 'eventCategory': 'nastroiki', 'eventAction': 'element_click', 'eventLabel': 'istoriya_uvedomlenii'})"
          >
            История уведомлений
          </router-link>
        </div>
      </div>
    </template>

    <div
      v-if="$router.currentRoute.value.name === 'settings'"
      class="page__content page__content_regular"
    >
      <div class="page__nav" />
      <div class="blocks page__content-blocks">
        <div class="blocks__item">
          <h1 class="page__nav-title page__nav-title_margin">
            Настройки
          </h1>
          <div class="nav-page-menu">
            <router-link
              to="/settings/personal"
              class="nav-page-menu__link_personal nav-page-menu__link"
              href="#"
              @click="gtm.push_events({'event': 'vntStv', 'eventCategory': 'nastroiki', 'eventAction': 'tab_click', 'eventLabel': 'lichnaya_informaciya', 'buttonLocation': 'screen'})"
            >
              Личная информация
            </router-link>
            <router-link
              v-if="signedDocuments.length || forSignedDocuments.length"
              to="/settings/documents"
              class="nav-page-menu__link_documents nav-page-menu__link"
              href="#"
            >
              Документы
            </router-link>
            <router-link
              to="/settings/password"
              class="nav-page-menu__link_password nav-page-menu__link"
              href="#"
              @click="gtm.push_events({'event': 'vntStv', 'eventCategory': 'nastroiki', 'eventAction': 'tab_click', 'eventLabel': 'smena_parolya', 'buttonLocation': 'screen'})"
            >
              Смена пароля
            </router-link>
            <router-link
              to="/settings/informing"
              class="nav-page-menu__link_notices nav-page-menu__link"
              href="#"
              @click="gtm.push_events({'event': 'vntStv', 'eventCategory': 'nastroiki', 'eventAction': 'tab_click', 'eventLabel': 'nastroiki_uvedomlenii', 'buttonLocation': 'screen'})"
            >
              Настройка уведомлений
            </router-link>
            <router-link
              to="/settings/notifications"
              class="nav-page-menu__link_notice-log nav-page-menu__link"
              href="#"
              @click="gtm.push_events({'event': 'vntStv', 'eventCategory': 'nastroiki', 'eventAction': 'tab_click', 'eventLabel': 'istoriya_uvedomlenii', 'buttonLocation': 'screen'})"
            >
              История уведомлений
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <router-view />
  </app-page>
</template>

<script>
import { onMounted, onUpdated, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AppPage from '../components/ui/AppPage.vue';
import { useWindowSize } from '@/hooks/window-size';
import { useGTM } from '@/hooks/gtm';

export default {
  components: { AppPage },
  setup () {
    const store = useStore();

    const forSignedDocuments = computed(() => store.getters['settings/forSignedDocuments']);
    const signedDocuments = computed(() => store.getters['settings/signedDocuments']);
    const { windowSize } = useWindowSize();
    const router = useRouter();
    const gtm = useGTM();

    const needRedirectToPersonal = () => {
      return router.currentRoute.value.name === 'settings' && windowSize.value >= 1280;
    };

    const goToPersonal = () => {
      router.push('/settings/personal');
    };

    onMounted(async () => {
      try {
        if (needRedirectToPersonal()) {
          goToPersonal();
        }
      } catch (e) {

      }
    });

    onUpdated(() => {
      if (needRedirectToPersonal()) {
        goToPersonal();
      }
    });

    return { gtm, forSignedDocuments, signedDocuments };
  }
};
</script>

<style>

</style>
