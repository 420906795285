<template>
  <AppSwitch
    v-model="switchFlag"
    class="switch"
    :disabled="switchFlag"
    @click="onClick"
  />
  <p :class="[{'active': !switchFlag}]">
    {{ switchFlag ? 'Активировано':'Активировать' }}
  </p>
</template>
<script setup>
import AppSwitch from '@/components/ui/AppSwitch.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  equipment: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits(['activated']);
const switchFlag = ref(props.equipment.isActiveTimer || false);

watch(() => props.equipment.isActiveTimer, newValue => {
  switchFlag.value = newValue;
});

const onClick = (e) => {
  if (!switchFlag.value) {
    emit('activated', props.equipment);
    e.preventDefault();
  }
};

</script>

<style scoped>
.switch {
  align-self: flex-end;
}
.active {
  color: var(--primary-light-negative);
}
</style>
