<template>
  <div>
    <div
      class="swiper__container"
      @touchstart="emits('handleTouchStart')"
      @touchend="emits('handleTouchEnd')"
    >
      <slot />
    </div>
    <div class="swiper__container-btn-wrapper">
      <AppSwiperButton
        v-if="isNavigate"
        @click="emits('prevSlide')"
      />
      <AppSwiperButton
        v-if="isNavigate"
        duration="next"
        @click="emits('nextSlide')"
      />
    </div>
  </div>
</template>

<script setup>

import AppSwiperButton from './AppSwiperButton.vue';
import { defineEmits } from 'vue';

const emits = defineEmits(['handleTouchStart', 'handleTouchEnd', 'prevSlide', 'nextSlide']);
const props = defineProps({
  isNavigate: {
    type: Boolean,
    default: true
  }
});
</script>

<style scoped>
.swiper__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 40px;
}
.swiper__container-btn-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  position: absolute;
  bottom: 12px;
  right: 40px;
}
@media (max-width: 768px) {
  .swiper__container {
    align-items: center;
  }
  .swiper__container-btn-wrapper {
    display: none;
  }
}

</style>
