<template>
  <component
    :is="layout + '-layout'"
    v-if="layout"
  />
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import MainLayout from './layouts/MainLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import AuthMobileLayout from './layouts/AuthMobileLayout';

export default {
  components: { MainLayout, AuthLayout, AuthMobileLayout },
  setup () {
    const route = useRoute();

    return {
      layout: computed(() => route.meta.layout)
    };
  }
};
</script>

<style>
#app,
html, body {
  height: 100%;
}
</style>
