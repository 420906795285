<template>
  <AppBaner
    v-if="$route.path==='/'"
    class="banners"
  />
  <div class="page__container flex-fill">
    <AppBreadcrumbs />

    <div :class="['page__main', {'page__main_noaside': asideType === 'noaside'}, {'content__center': contentCenter}]">
      <slot name="menu" />
      <slot
        v-if="showBanners"
        name="banners"
      >
        Список баннеров для всех страниц
      </slot>
      <slot />
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import AppBaner from '@/components/baner/AppBaner';
import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs';

export default {
  components: { AppBreadcrumbs, AppBaner },
  props: {
    showBanners: {
      type: Boolean,
      required: false
    },
    contentCenter: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const route = useRoute();

    const asideType = computed(() => route.meta.asideType);

    return { asideType };
  }

};
</script>
<style scoped>

.banners {
  margin-bottom: 32px;
}
.content__center {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
@media (max-width: 1279px) {
  .banners {
    margin: 0;
  }
}

</style>
