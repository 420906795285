import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/css/reset.css';
import './assets/fonts/Text/MTSText-Regular/MTSText-Regular.css';
import './assets/fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.css';
import './assets/fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.css';
import './assets/fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.css';
import './assets/fonts/Wide/MTSWide-Regular/MTSWide-Regular.css';
import './assets/fonts/Wide/MTSWide-Bold/MTSWide-Bold.css';

import '@vuepic/vue-datepicker/dist/main.css';
import './assets/css/styles.css';
import './assets/css/main.css';
import './assets/css/lk.css';
import './assets/css/datePicker.css';

import directives from './directives';

const app = createApp(App);

directives.forEach(directive => {
  app.directive(directive.name, directive);
});

app
  .use(store)
  .use(router)
  .mount('#app');
