<template>
  <form
    class="form"
    @submit.prevent
  >
    <div class="form__pre">
      <p class="para">
        Мы отправили код на {{ mobileFormatted(props.propContact) }}
      </p>
    </div>
    <div class="form__input-field">
      <div :class="['digital__input' ,{'digital-input_invalid':incorrectСode, 'input-field_invalid': incorrectСode}]">
        <AppConfirmationCode
          ref="codeCOM"
          v-model="code"
          :is-disabled="isDisabled"
          :is-error="touchError"
          @input="touchError = false"
        />
        <div
          v-if="incorrectСode"
          class="input-field__description"
        >
          <p>Неверный код</p>
          <p>{{ attemptsLeft !==0 ? `Осталось попыток: ${attemptsLeft}`: 'Отправьте код повторно' }}</p>
        </div>
        <div
          v-else-if="isInvalidCode"
          class="input-field__description"
        >
          <p>{{ INVALID_CODE }} Отправьте код повторно</p>
        </div>
        <div class="input-field__description-extra">
          Код действителен в течение 5 минут с момента получения
        </div>
      </div>
    </div>

    <div class="form__input-group-mid">
      <p
        v-if="inProcess"
        class="para para_gray"
      >
        Отправить код через {{ seconds }} сек.
      </p>
      <p
        v-else
        class="para para_gray"
      >
        <a
          :class="['link', {'link_not-clickable': isSubmitting || isResendCodeSubmitting}]"
          href="#"
          @click.prevent="resendCode"
        >Отправить код повторно</a>
      </p>
    </div>

    <div class="form__input-field checkbox__wrapper">
      <AppCheckbox
        id="agreement"
        v-model="agreement"
      />
      <p class="para">
        Я ознакомлен и согласен с <a
          class="link"
          href="/statements/terms_of_services_042014.pdf"
          target="_blank"
        >условиями пользования услугой</a>
      </p>
    </div>
    <div class="form__bottom">
      <div class="form__btn-stack">
        <div class="form__btn-stack-item">
          <AppButton
            fluid
            :disabled="!formValid"
            :loading="isSubmitLoader"
            @click="onSubmit"
          >
            Далее
          </AppButton>
        </div>
        <div class="form__btn-stack-item">
          <AppButton
            fluid
            color="button_secondary"
            @click.prevent="$emit('cancel')"
          >
            Отмена
          </AppButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useConfirmationCode } from '../../hooks/confirmation-code';
import AppCheckbox from '@/components/ui/AppCheckbox';
import AppButton from '@/components/ui/AppButton';
import AppConfirmationCode from '@/components/ui/AppConfirmationCode.vue';

export default {
  components: { AppConfirmationCode, AppButton, AppCheckbox },
  props: {
    propType: {
      type: String,
      required: true
    },
    propContact: {
      type: String,
      required: true
    },
    attemptsLeftProp: {
      type: String,
      required: true
    }
  },
  emits: ['cancel', 'submit'],
  setup (props, { emit }) {
    const successCallback = () => emit('submit');

    const resetCodePayload = { type: props.propType, value: props.propContact };
    const confirmationCode = useConfirmationCode(successCallback, resetCodePayload, props.attemptsLeftProp);

    return { ...confirmationCode, props };
  }

};
</script>

<style scoped>
.checkbox__wrapper {
  display: flex;
  gap: 8px;
}
.digital__input {
  display: flex;
  flex-direction: column;
  font-size: 0;
}
</style>
