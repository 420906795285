import { useRoute } from 'vue-router';
import { useStore } from 'vuex';


export function useGTM() {

    const route = useRoute();
    const store = useStore();
    const dataLayer = window.dataLayer;

    const push_pages = async (data) => {
        dataLayer.push({
            'event': 'scrn',
            'grclientId': data.personalAccount || null,  // Первая загрузка страницы будет с null, не успевает прогрузится информация
            'userAuth': data.userAuth === true ? 1 : 0,
            'screenName': data.screenName,
            'eventContent': null,
            'buttonLocation': null,
            'actionGroup': 'non_interactions',
            'touchPoint': 'web',
            'currentTariff': null  // TODO: есть ли такая информация
        });
    };

    const push_events = async (data) => {
        const isAuthenticated = store.getters['auth/isAuthenticated'];
        const userInfo = store.getters['user/userInfo'];
        dataLayer.push({
            'event': data.event || null,
            'eventCategory' : data.eventCategory || null,
            'eventAction' : data.eventAction || null,
            'eventLabel' : data.eventLabel || null,
            'eventValue' : null,
            'userId': null,  // TODO: "Id пользователя из профиля web SSO userId={{webSSO guid}}, где ""webSSO guid"" - это уникальный идентификатор пользователя, который webSSO присваивает и хранит на своей стороне (profile:guid)
            'userAuth': isAuthenticated === true ? 1 : 0,
            'grclientId': userInfo?.personalAccount || null,
            'screenName': route.path,
            'projectName': 'stv',
            'eventContent': data.eventContent || null,
            'eventContext': data.eventContext || null,
            'buttonLocation': data.buttonLocation || null,
            'filterName': null,
            'actionGroup': data.actionGroup || 'interactions',
            'productName': data.productName || null,
            'productId': null,
            'accountType': null, // TODO: тип аккаунта в разрезе принадлежности бизнеса (для myMTS) определяется по полю SSO profile:type Мобильная связь - mobile, Фиксированная связь и СТВ - stv, Фиксированная связь - fix, Виртуальный номер - mobile, МТС Коннект - mobile, Безопасное детство - mobile, "&"Госуслуги - other, mgts, mrmsk - mgts, Домашний интернет - fix")
            'touchPoint': 'web',
            'bannerName': null,
            'bannerId': null,
            'region': null,
            'currentTariff': null,  // TODO: есть ли такая информация
            'grId': null, // TODO:
            'prtp': null // TODO:
        });
    };

    const translit_ru_en = (ru_str) => {
        const trans_dict = { 'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e',
            'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'i', 'к': 'k', 'л': 'l',
            'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
            'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'sch',
            'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'u', 'я': 'ya',
            // 'А': 'A',
            // 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Е': 'E', 'Ё': 'E', 'Ж': 'Zh',
            // 'З': 'Z', 'И': 'I', 'Й': 'I', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
            // 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U', 'Ф': 'F',
            // 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Sch', 'Ъ': '', 'Ы': 'Y',
            // 'Ь': '', 'Э': 'E', 'Ю': 'U', 'Я': 'Ya',
            ' ': '_', '.': '', ',': ''
        }

        let en_str = '';
        [...ru_str.toLowerCase()].forEach(c => {
            en_str += trans_dict[c] || trans_dict[c] === '' ? trans_dict[c] : c;
        });
        return en_str
    }

    return {
        push_pages,
        push_events,
        translit_ru_en
    };
}
