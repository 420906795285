<template>
  <div class="page__content page__content_regular">
    <div class="page__nav">
      <div
        class="page__nav-btn-back"
        @click.prevent="$router.push({name: 'settings'})"
      />
    </div>
    <div class="blocks page__content-blocks">
      <div class="blocks__item">
        <h1 class="page__nav-title page__nav-title_margin">
          Личная информация
        </h1>

        <div
          v-if="userInfo"
          class="card-set card-set__personal"
        >
          <div class="card-set__card card-set__card_personal">
            <header class="card-set__card-header">
              Персональные данные
            </header>
            <div class="card-set__card-body card-set__card-body_personal">
              <div class="card-set__item">
                <div class="card-set__item-title">
                  ФИО
                </div>
                <div class="card-set__item-value">
                  {{ userInfo.familyName + ' ' + userInfo.firstName + ' ' + userInfo.fatherName }}
                </div>
              </div>
              <div class="card-set__item">
                <div class="card-set__item-title">
                  Дата рождения
                </div>
                <div class="card-set__item-value">
                  {{ dateFormatter(userInfo.birthDate, 2) }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-set__card card-set__card_phone">
            <header class="card-set__card-header mts-d-flex mts-align-items-center">
              Контактный номер
              <div
                v-if="userInfo.mobile && !userInfo.mobileCnfd"
                style="margin-left: 7px"
                class="mts-d-flex mts-align-items-center"
              >
                <AppTooltipNew
                  tooltip-position="top"
                  max-width="250"
                >
                  Указанный номер не подтвержден
                  <template #tooltip>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.68597 1.68597C0.373095 2.99884 0.275748 4.30684 0.0810531 6.92282C0.0305833 7.60095 0 8.30048 0 9C0 9.69952 0.0305834 10.399 0.0810532 11.0772C0.275748 13.6932 0.373095 15.0012 1.68597 16.314C2.99884 17.6269 4.30684 17.7243 6.92282 17.9189C7.60095 17.9694 8.30048 18 9 18C9.69952 18 10.399 17.9694 11.0772 17.9189C13.6932 17.7243 15.0012 17.6269 16.314 16.314C17.6269 15.0012 17.7243 13.6932 17.9189 11.0772C17.9694 10.399 18 9.69952 18 9C18 8.30048 17.9694 7.60095 17.9189 6.92282C17.7243 4.30684 17.6269 2.99884 16.314 1.68597C15.0012 0.373095 13.6932 0.275748 11.0772 0.0810531C10.399 0.0305833 9.69952 0 9 0C8.30048 0 7.60095 0.0305834 6.92282 0.0810532C4.30684 0.275748 2.99884 0.373095 1.68597 1.68597ZM10.0001 4.99887C10.0001 5.68859 9.44092 6.24773 8.75119 6.24773C8.06146 6.24773 7.50233 5.68859 7.50233 4.99887C7.50233 4.30914 8.06146 3.75 8.75119 3.75C9.44092 3.75 10.0001 4.30914 10.0001 4.99887ZM9.70711 8.29289C9.89464 8.48043 10 8.73479 10 9L9.99999 13C9.99999 13.5523 9.55227 14 8.99998 14C8.4477 14 7.99999 13.5523 7.99999 13L8 10H7.5C6.94772 10 6.5 9.55228 6.5 9C6.5 8.44772 6.94772 8 7.5 8H9C9.26522 8 9.51957 8.10536 9.70711 8.29289Z"
                        fill="#FAC031"
                      />
                    </svg>
                  </template>
                </AppTooltipNew>
              </div>
              <div
                v-else-if="userInfo.mobileCnfd && userInfo.mobile"
                class="card-set__card-header-icon card-set__card-header-icon_ok"
              />
            </header>
            <div class="card-set__card-body card-set__card-body_regular">
              <div class="card-set__card-contact">
                <div
                  :class="['card-set__card-contact-value', 'card-set__card-contact-value_with-icon', 'card-set__card-contact_phone', {'card-set__card-contact_not-defined': !userInfo.mobile}]"
                  @click.prevent="mobileModal = !userInfo.mobile; gtmNomerSendEvent('dobavit')"
                >
                  {{ userInfo.mobile ? mobileFormatted(userInfo.mobile): 'Добавить номер' }}
                </div>
                <div
                  v-if="userInfo.mobile"
                  class="card-set__card-contact-change-btn"
                  @click.prevent="mobileModal = true; userInfo.mobileCnfd ? gtmNomerSendEvent('izmenit') : gtmNomerSendEvent('podtverdit')"
                >
                  {{ userInfo.mobileCnfd ? 'Изменить':'Подтвердить' }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-set__card card-set__card_email">
            <header class="card-set__card-header mts-d-flex mts-align-items-center">
              Контактная почта
              <div
                v-if="userInfo.email && !userInfo.emailCnfd"
                style="margin-left: 7px"
                class="mts-d-flex mts-align-items-center"
              >
                <AppTooltipNew
                  tooltip-position="top"
                  max-width="250"
                >
                  Указанная почта не подтверждена
                  <template #tooltip>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.68597 1.68597C0.373095 2.99884 0.275748 4.30684 0.0810531 6.92282C0.0305833 7.60095 0 8.30048 0 9C0 9.69952 0.0305834 10.399 0.0810532 11.0772C0.275748 13.6932 0.373095 15.0012 1.68597 16.314C2.99884 17.6269 4.30684 17.7243 6.92282 17.9189C7.60095 17.9694 8.30048 18 9 18C9.69952 18 10.399 17.9694 11.0772 17.9189C13.6932 17.7243 15.0012 17.6269 16.314 16.314C17.6269 15.0012 17.7243 13.6932 17.9189 11.0772C17.9694 10.399 18 9.69952 18 9C18 8.30048 17.9694 7.60095 17.9189 6.92282C17.7243 4.30684 17.6269 2.99884 16.314 1.68597C15.0012 0.373095 13.6932 0.275748 11.0772 0.0810531C10.399 0.0305833 9.69952 0 9 0C8.30048 0 7.60095 0.0305834 6.92282 0.0810532C4.30684 0.275748 2.99884 0.373095 1.68597 1.68597ZM10.0001 4.99887C10.0001 5.68859 9.44092 6.24773 8.75119 6.24773C8.06146 6.24773 7.50233 5.68859 7.50233 4.99887C7.50233 4.30914 8.06146 3.75 8.75119 3.75C9.44092 3.75 10.0001 4.30914 10.0001 4.99887ZM9.70711 8.29289C9.89464 8.48043 10 8.73479 10 9L9.99999 13C9.99999 13.5523 9.55227 14 8.99998 14C8.4477 14 7.99999 13.5523 7.99999 13L8 10H7.5C6.94772 10 6.5 9.55228 6.5 9C6.5 8.44772 6.94772 8 7.5 8H9C9.26522 8 9.51957 8.10536 9.70711 8.29289Z"
                        fill="#FAC031"
                      />
                    </svg>
                  </template>
                </AppTooltipNew>
              </div>
              <div
                v-else-if="userInfo.emailCnfd && userInfo.email"
                class="card-set__card-header-icon card-set__card-header-icon_ok"
              />
            </header>
            <div class="card-set__card-body card-set__card-body_regular">
              <div class="card-set__card-contact">
                <div
                  :class="['card-set__card-contact-value', 'card-set__card-contact-value_with-icon', 'card-set__card-contact_email', {'card-set__card-contact_not-defined': !userInfo.email}]"
                  @click.prevent="emailModal = !userInfo.emailCnfd; gtmPochtaSendEvent('dobavit')"
                >
                  {{ userInfo.email ? userInfo.email: 'Добавить почту' }}
                </div>
                <div
                  v-if="userInfo.email"
                  class="card-set__card-contact-change-btn"
                  @click.prevent="emailModal = true; userInfo.emailCnfd ? gtmPochtaSendEvent('izmenit') : gtmPochtaSendEvent('podtverdit')"
                >
                  {{ userInfo.emailCnfd ? 'Изменить':'Подтвердить' }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="1===2"
            class="card-set__card card-set__card_b1"
          >
            <header class="card-set__card-header">
              Адрес регистрации
            </header>
            <div class="card-set__card-body card-set__card-body_regular">
              {{ userInfo.address }}
            </div>
          </div>
          <div class="card-set__card card-set__card_b2 card-set__card_unwrap">
            <header class="card-set__card-header">
              Данные лицевого счета
            </header>
            <div class="card-set__card-body card-set__card-body_account-data">
              <div class="card-set__item">
                <div class="card-set__item-title">
                  № лицевого счета
                </div>
                <div class="card-set__item-value">
                  {{ userInfo.personalAccount }}
                </div>
              </div>
              <div class="card-set__item">
                <div class="card-set__item-title">
                  Дата заключения договора
                </div>
                <div class="card-set__item-value">
                  {{ dateFormatter(userInfo.contrDate, 2) }}
                </div>
              </div>
              <div
                v-if="needShowConvergent"
                class="card-set__item"
              >
                <div class="card-set__item-title">
                  Номер для пополнения баланса
                </div>
                <div class="card-set__item-value">
                  {{ userInfo.mobileConvergent.trim() }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContractsList />
      </div>
    </div>

    <teleport to="body">
      <app-modal
        v-if="mobileModal"
        title="Изменение контактных данных"
        @close="mobileModal = false"
      >
        <mobile-change-modal
          :mobile-default="userInfo.mobile"
          @success="onSuccess('mobile')"
          @cancel="mobileModal = false"
        />
      </app-modal>
      <app-modal
        v-else-if="emailModal"
        title="Изменение контактных данных"
        @close="emailModal = false"
      >
        <email-change-modal
          :email-default="userInfo.email"
          @success="onSuccess('email')"
          @cancel="emailModal = false"
        />
      </app-modal>
    </teleport>

    <app-toast
      v-if="showToast"
      :text="toastText"
      :type="toastType"
    />
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { mobileFormatted, dateFormatter } from '@/utils/helpers';
import { useToast } from '@/hooks/toast';
import AppModal from '../ui/AppModal.vue';
import MobileChangeModal from './modals/MobileChangeModal';
import EmailChangeModal from './modals/EmailChangeModal.vue';
import AppToast from '../ui/AppToast.vue';
import { useToggle } from '@/hooks/toggle';
import { useGTM } from '@/hooks/gtm';
import AppTooltipNew from '@/components/ui/AppTooltipNew';
const ContractsList = defineAsyncComponent(() => import('@/components/settings/entities/ContractsList/ContractsList.vue'));
export default {
  components: { ContractsList, AppTooltipNew, MobileChangeModal, EmailChangeModal, AppModal, AppToast }, // TO DO: Изменить адрес регистрации на адрес подключение. Сейчас адрес закомментирован
  setup () {
    const store = useStore();
    const userInfo = computed(() => store.getters['user/userInfo']);
    const needShowConvergent = computed(() => store.getters['user/needShowConvergent']);
    const gtm = useGTM();

    const mobileModal = ref(false);
    const emailModal = ref(false);

    const toast = useToast();
    const { toggle: toggleMobile } = useToggle(false);
    const { toggle: toggleEmail } = useToggle(false);

    const onSuccess = (type) => {
      if (type === 'mobile') {
        mobileModal.value = false;
        toast.triggerToast('Номер изменен', 'ok');
      } else if (type === 'email') {
        emailModal.value = false;
        toast.triggerToast('Почта изменена', 'ok');
      }
    };

    const gtmPochtaSendEvent = async (eventType) => {
      let eventLabel;

      if (eventType === 'izmenit') {
        eventLabel = 'izmenit';
      } else if (eventType === 'podtverdit') {
        eventLabel = 'podtverdit';
      } else if (eventType === 'dobavit') {
        eventLabel = 'dobavit_pochtu';
      }

      gtm.push_events({ event: 'vntLich', eventCategory: 'lichnaya_informaciya', eventAction: 'element_click', eventLabel: eventLabel, eventContent: 'pochta' });
    };

    const gtmNomerSendEvent = async (eventType) => {
      let eventLabel;

      if (eventType === 'izmenit') {
        eventLabel = 'izmenit';
      } else if (eventType === 'podtverdit') {
        eventLabel = 'podtverdit';
      } else if (eventType === 'dobavit') {
        eventLabel = 'dobavit_nomer';
      }
      gtm.push_events({ event: 'vntLich', eventCategory: 'lichnaya_informaciya', eventAction: 'element_click', eventLabel: eventLabel, eventContent: 'nomer' });
    };

    return {
      userInfo,
      mobileFormatted,
      dateFormatter,
      mobileModal,
      emailModal,
      onSuccess,
      ...toast,
      toggleMobile,
      toggleEmail,
      needShowConvergent,
      gtm,
      gtmPochtaSendEvent,
      gtmNomerSendEvent
    };
  }
};
</script>

<style>

</style>
