import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useWindowSize } from '@/hooks/window-size';

export const useSwiper = (items, timerProps) => {
  const currentIndex = ref(0);
  const touchDuration = reactive({
    touchStartX: 0,
    touchEndX: 0
  });
  const swipeThreshold = 50;
  const right = ref(false);
  const left = ref(false);
  const { windowSize } = useWindowSize();
  const timerSeconds = computed(() => +items[currentIndex.value]?.attributeCategories.osnovnoy_banner.attributes.taymer?.value.value || timerProps);
  let timer;
  let length = items.length;
  const currentItem = computed(() => items[currentIndex.value]?.attributeCategories.osnovnoy_banner.attributes);

  const handleTouchStart = (event) => {
    if (length <= 1) return;
    touchDuration.touchStartX = event.touches[0].clientX;
  };
  const handleTouchEnd = (event) => {
    if (length <= 1) return;
    touchDuration.touchEndX = event.changedTouches[0].clientX;
    handleSwipe();
  };
  const handleSwipe = () => {
    if (touchDuration.touchStartX - touchDuration.touchEndX > swipeThreshold) {
      nextSlide();
    } else if (touchDuration.touchStartX - touchDuration.touchEndX < -swipeThreshold) {
      prevSlide();
    }
  };

  const nextSlide = () => {
    right.value = true;
    setTimeout(() => {
      currentIndex.value = (currentIndex.value + 1) % length;
      right.value = false;
      resetTimer();
    }, currentItem.value.zagolovok?.value || currentItem.value.soderzhaniye?.value ? 300 : 0);
  };

  const prevSlide = () => {
    left.value = true;
    setTimeout(() => {
      currentIndex.value = (currentIndex.value - 1 + length) % length;
      left.value = false;
      resetTimer();
    }, currentItem.value.zagolovok?.value || currentItem.value.soderzhaniye?.value ? 300 : 0);
  };

  const startTimer = () => {
    timer = setInterval(() => {
      nextSlide();
    }, timerSeconds.value * 1000);
  };

  const resetTimer = () => {
    clearInterval(timer);
    timer = null;
    if (windowSize.value <= 768) {
      startTimer();
    }
  };

  const setLengths = (size) => {
    length = size;
  };

  watch(windowSize, newSize => {
    if (length <= 1) return;
    if (newSize > 768) {
      clearInterval(timer);
      timer = null;
    } else if (newSize <= 768 && !timer) {
      startTimer();
    }
  });

  onMounted(() => {
    if (windowSize.value <= 768 && length > 1) {
      startTimer();
    }
  });
  onUnmounted(() => {
    clearInterval(timer);
  });

  return {
    currentIndex,
    right,
    left,
    handleTouchStart,
    handleTouchEnd,
    prevSlide,
    nextSlide,
    timerSeconds,
    setLengths
  };
};
