<template>
  <teleport to="body">
    <app-modal
      v-if="!showConfirmModal"
      title="Смена тарифа"
      @close="$emit('cancel')"
    >
      <div class="popup__body">
        <div class="form">
          <div class="form__el-mt24">
            <p class="para form__el-header-17">
              Новый тарифный план
            </p>
            <p class="para form__el-secondary-14 form__el-mt4">
              {{ task.tariffTo }}
            </p>
          </div>
          <!--          <div class="form__el-mt24">-->
          <!--            <p class="para form__el-header-17">Ресурс</p>-->
          <!--            <p class="para form__el-secondary-14 form__el-mt4">Спутниковое ТВ - {{ resName }}</p>-->
          <!--          </div>-->
          <div class="form__el-mt24">
            <p class="para form__el-header-17">
              Дата смены тарифного плана
            </p>
            <p class="para form__el-secondary-14 form__el-mt4">
              {{ dateFormatter(task.date, 2) }}
            </p>
          </div>
          <div class="form__bottom page__hidden page__visible_t">
            <button
              class="form__submit button popup__button button_fluid button_negative"
              @click.prevent="showConfirmModal = true; gtm.push_events({'event': 'vntMain', 'eventCategory': 'smena_tarifa', 'eventAction': 'button_click', 'eventLabel': 'otmenit_smenu_tarifa', 'buttonLocation': 'popup'})"
            >
              Отменить смену тарифа
            </button>
          </div>
          <div class="form__bottom page__hidden_t">
            <button
              class="form__submit button popup__button button_fluid button_negative button_small"
              @click.prevent="showConfirmModal = true; gtm.push_events({'event': 'vntMain', 'eventCategory': 'smena_tarifa', 'eventAction': 'button_click', 'eventLabel': 'otmenit_smenu_tarifa', 'buttonLocation': 'popup'})"
            >
              Отменить смену тарифа
            </button>
          </div>
        </div>
      </div>
    </app-modal>

    <confirm-dialog
      v-if="showConfirmModal"
      title="Отмена смены тарифа"
      text="Вы действительно хотите отменить запланированную смену?"
      ok-button-text="Да, отменить"
      cancel-button-text="Закрыть"
      :ok-action="okButtonClick"
      @ok="onDialogSuccess"
      @cancel="onDialogCancel"
      @error="onDialogError"
    />
  </teleport>
</template>

<script>

import { useStore } from 'vuex';
import ConfirmDialog from '@/components/shared/modals/ConfirmDialog.vue';
import AppModal from '@/components/ui/AppModal.vue';
import { useConfirmDialog } from '@/hooks/confirm-dialog';
import { dateFormatter } from '@/utils/helpers';
import { useGTM } from '@/hooks/gtm';

export default {
  components: { ConfirmDialog, AppModal },
  props: {
    task: {
      type: Object
    },
    resName: {
      required: true
    }
  },
  emits: ['success', 'cancel', 'error'],
  setup (props, { emit }) {
    const store = useStore();
    const gtm = useGTM();

    const okButtonClick = async () => {
      await store.dispatch('tariffs/tariffChangeDelete', { taskId: props.task.taskId });
    };

    const sucessCallback = () => {
      gtm.push_events({ event: 'vntMain', eventCategory: 'smena_tarifa', eventAction: 'button_click', eventLabel: 'da_otmenit', buttonLocation: 'popup' });
      emit('success');
    };

    const cancelCallback = () => {
      gtm.push_events({ event: 'vntMain', eventCategory: 'smena_tarifa', eventAction: 'button_click', eventLabel: 'zakryt', buttonLocation: 'popup' });
      emit('cancel');
    };

    const errorCallback = () => {
      emit('error');
    };

    const dialog = useConfirmDialog({ sucessCallback, cancelCallback, errorCallback });

    return { dateFormatter, ...dialog, okButtonClick, gtm };
  }
};
</script>
