import { ref, computed, reactive, nextTick, onMounted, onUnmounted } from 'vue';
import yup from '../utils/validation';
import { useField, useForm } from 'vee-validate';
import { useStore } from 'vuex';
import { useStage } from '@/hooks/stage';
import { useTimer } from '@/hooks/timer';
import { useGTM } from '@/hooks/gtm';
export function usePasswordRecovery (initialLogin) {
  const code = ref('');
  const codeCOM = ref(null);
  const agreement = ref(true);
  const touchError = ref(false);
  const isSubmitLoader = ref(false);
  const { count: seconds, inProcess, startTimer } = useTimer();
  const { stage } = useStage(1, 3, 1);
  const store = useStore();
  const gtm = useGTM();
  const attemptsLeft = ref(0);
  const isDisabled = ref(false);
  const isInvalidCode = ref(false);
  const isTimeNotEnd = reactive({
    flag: false,
    value: ''
  });
  const INVALID_CODE = 'Истек срок действия кода подтверждения.';
  const TIME_CODE_NOT_END = 'Не прошло установленное время с момента предыдущей отправки кода.';

  const smsName = 'По SMS на телефон';

  const recoveryTypes = [
    { type: 'mobile', name: smsName },
    { type: 'email', name: 'Через сообщение на почту' }
  ];

  const schema = computed(() => {
    return yup.object({
      recoveryType: yup.string().required(),
      login: yup
        .string()
        .trim()
        .length(11, 'Номер должен содержать 11 символов')
        .matches('^[0-9]*$', 'Допустимые символы: 0-9')
        .required('Необходимо указать номер ЛС')
    });
  });

  const {
    handleSubmit,
    isSubmitting,
    meta: formMeta
  } = useForm({
    validationSchema: schema,
    initialValues: {
      recoveryType: smsName,
      login: initialLogin
    }
  });

  const { value: recoveryType } = useField('recoveryType');
  const {
    value: login,
    errorMessage: lError,
    handleBlur: lBlur,
    meta: lMeta
  } = useField('login');

  const emailOrMobile = ref('');
  const impossible = ref(false);

  const submitFunc1 = async (fields, isResend, actions) => {
    try {
      isTimeNotEnd.flag = false;
      const result = await store.dispatch('auth/createPasswordRecoverCode', { type: fields.recoveryType, login: fields.login });
      emailOrMobile.value = result.data;
      attemptsLeft.value = +result.attemptsLeft;
      if (!isResend) {
        stage.next(2);
      }
      startTimer(30);
      isDisabled.value = false;
      isInvalidCode.value = false;
      touchError.value = false;
      await nextTick(() => codeCOM.value.toFocusInput());
    } catch (e) {
      if (e.data.message === TIME_CODE_NOT_END) {
        isTimeNotEnd.flag = true;
        isTimeNotEnd.value = e.data.message;
        return;
      }
      impossible.value = true;
    }
  };

  const onSubmit1 = handleSubmit(async (fields, actions) => {
    recoveryTypes.forEach((objects) => {
      if (fields.recoveryType === objects.name) {
        fields.recoveryType = objects.type;
      }
    });
    gtm.push_events({ event: 'vntVoss', eventCategory: 'vosstanovlenie_dostupa', eventAction: 'button_click', eventLabel: 'poluchit_kod', buttonLocation: 'popup' });
    await submitFunc1(fields, false, actions);
  });

  const resetFields = () => {
    code.value = '';
  };

  const resendCode = handleSubmit(async (fields, actions) => {
    resetFields();
    if (incorrectСode.value) {
      incorrectСode.value = false;
    }
    recoveryTypes.forEach((objects) => {
      if (fields.recoveryType === objects.name) {
        fields.recoveryType = objects.type;
      }
    });
    await submitFunc1(fields, true, actions);
  });

  const onRecoveryTypeItemClick = (e) => {
    recoveryType.value = e.target.innerText;
  };

  const incorrectСode = ref(false);

  const onSubmit2 = async () => {
    const payload = {
      login: login.value,
      code: code.value
    };
    try {
      isSubmitLoader.value = true;
      gtm.push_events({ event: 'vntVoss', eventCategory: 'vosstanovlenie_dostupa', eventAction: 'button_click', eventLabel: 'voiti', buttonLocation: 'popup' });
      await store.dispatch('auth/recoverPassword', payload);
      stage.next(3);
    } catch (e) {
      attemptsLeft.value--;
      resetFields();
      touchError.value = true;
      const isNotValid = e.data.message === INVALID_CODE;
      if (attemptsLeft.value === 0 || isNotValid) {
        isDisabled.value = true;
        isInvalidCode.value = isNotValid;
        return;
      }
      incorrectСode.value = true;
    } finally {
      isSubmitLoader.value = false;
    }
  };

  const form2Valid = computed(() => code.value.length === 4 && agreement.value);

  const ac = new AbortController();
  onMounted(() => {
    if ('OTPCredential' in window) {
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal
        })
        .then(async (otp) => {
          code.value = otp.code.toString();
          if (form2Valid.value) await onSubmit2();
          ac.abort();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    startTimer(30);
    isDisabled.value = false;
  });

  onUnmounted(() => ac.abort());

  return {
    recoveryTypes,
    recoveryType,
    smsName,
    login,
    lError,
    lBlur,
    lMeta,
    onRecoveryTypeItemClick,
    onSubmit1,
    isSubmitting,
    emailOrMobile,
    impossible,
    resendCode,
    stage,
    seconds,
    inProcess,
    onSubmit2,
    form2Valid,
    incorrectСode,
    attemptsLeft,
    isDisabled,
    isInvalidCode,
    isTimeNotEnd,
    INVALID_CODE,
    code,
    agreement,
    touchError,
    isSubmitLoader,
    codeCOM
  };
}
