import { ref, onMounted, onUnmounted, watch } from 'vue';

export function useWindowSize (cb) {
  const windowSize = ref(window.innerWidth);

  const changeWindowSize = () => {
    windowSize.value = window.innerWidth;

    if (cb) {
      cb();
    }
  };

  onMounted(() => {
    window.addEventListener('resize', changeWindowSize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', changeWindowSize);
  });

  const isDesktop = ref(windowSize.value >= 960);
  watch(windowSize, value => isDesktop.value = value >= 960);

  return { windowSize, isDesktop };
}
