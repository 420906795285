<template>
  <Card
    v-if="forSignedDocuments.length"
    title="Документы на подпись"
    :title-bottom="24"
  >
    <DocumentForSigned
      v-for="document in items"
      :key="document.docID"
      :document="document"
      @setDocument="setDocument"
    />
    <AppPaginate
      v-model="page"
      :view-page-size="false"
      :view-arrow="false"
      :total-rows="totalRows"
      :page-count="pageCount"
      :page-size="pageSize"
      :click-handler="pageChangeHandler"
      :click-size-handler="pageSizeChangeHandler"
    />
  </Card>
  <teleport to="body">
    <AppModal
      v-if="isModalActive"
      :view-close="isSigned"
      @close="close"
    >
      <SigningDocumentModal
        :document="documentModal"
        @signed="isSigned = false"
        @close="close"
      />
    </AppModal>
  </teleport>
</template>

<script>

import { usePaginate } from '@/hooks/paginate';
import { computed, ref, watch } from 'vue';
import DocumentForSigned from '@/components/settings/entities/DocumentForSigned';
import AppPaginate from '@/components/ui/AppPaginate';
import Card from '@/components/settings/entities/Card';
import { useStore } from 'vuex';
import AppModal from '@/components/ui/AppModal';
import SigningDocumentModal from '@/components/settings/modals/SigningDocumentModal';

export default {
  components: { SigningDocumentModal, AppModal, Card, AppPaginate, DocumentForSigned },
  setup () {
    const store = useStore();
    const forSignedDocuments = computed(() => store.getters['settings/forSignedDocuments']);
    const paginate = usePaginate(() => {}, 3);
    const isModalActive = ref(false);
    const isSigned = ref(true);
    const documentModal = ref(null);

    paginate.setupPagination(forSignedDocuments.value);

    watch(forSignedDocuments, newValue => {
      paginate.reset(newValue, 3);
    });
    const setDocument = (doc) => {
      documentModal.value = doc;
      isModalActive.value = true;
    };
    const close = () => {
      isModalActive.value = false;
      isSigned.value = true;
    };
    return { ...paginate, forSignedDocuments, isModalActive, setDocument, documentModal, isSigned, close };
  }
};

</script>

<style scoped>

</style>
