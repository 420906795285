<template>
  <teleport to="body">
    <app-modal
      :title="title"
      @close="$emit('cancel')"
    >
      <div class="popup__body">
        <div class="form">
          <div class="form__pre">
            {{ text }}
          </div>
          <div class="form__btn-stack">
            <div class="form__btn-stack-item">
              <button
                :class="['form__submit', 'button', 'button_fluid', 'button_small-m', `button_${okButtonNegative ? 'negative': 'primary'}`, 'button_noscale']"
                @click.prevent="onButton1Click"
              >
                {{ okButton1Text }}
              </button>
            </div>
            <div class="form__btn-stack-item">
              <button
                class="form__submit button button_fluid button_small-m button_secondary button_noscale"
                @click.prevent="onButton2Click"
              >
                {{ okButton2Text }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-modal>
  </teleport>
</template>

<script>
import AppModal from '../../ui/AppModal';

export default {
  components: { AppModal },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    okButton1Text: {
      type: String,
      default: 'Да'
    },
    okButtonNegative: {
      type: Boolean,
      default: false
    },
    okButton2Text: {
      type: String,
      default: 'Да'
    },
    okAction1: {
      type: Function,
      required: false
    },
    okAction2: {
      type: Function,
      required: false
    }
  },
  emits: ['cancel', 'ok1', 'ok2', 'error'],
  setup (props, { emit }) {
    const onButton1Click = async () => {
      try {
        await props.okAction1?.();
        emit('ok1');
      } catch (ex) {
        emit('error', ex);
      }
    };

    const onButton2Click = async () => {
      try {
        await props.okAction2?.();
        emit('ok2');
      } catch (ex) {
        emit('error', ex);
      }
    };

    return { onButton1Click, onButton2Click };
  }
};

</script>

<style>

</style>
