<template>
  <div class="page__content page__content_regular">
    <div class="page__nav">
      <div
        class="page__nav-btn-back"
        @click.prevent="$router.push({name: 'settings'})"
      />
    </div>
    <div class="blocks page__content-blocks">
      <div class="blocks__item">
        <h1 class="page__nav-title page__nav-title_margin">
          Настройка уведомлений
        </h1>

        <form
          v-if="userInfo && loaded"
          class="form"
          @submit.prevent="onSubmit"
        >
          <div class="card-set card-set__notifying">
            <div class="card-set__card">
              <header class="card-set__card-header card-set__card-header_checked">
                <div
                  v-if="informingStatus.sms || !userInfo.mobile"
                  class="sms__none-block"
                >
                  <AppTooltipNew
                    tooltip-position="right"
                    :max-width="155"
                  >
                    <template #tooltip>
                      <svg
                        width="24"
                        height="24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.68597 4.68597C3.3731 5.99884 3.27575 7.30684 3.08105 9.92282C3.03058 10.601 3 11.3005 3 12C3 12.6995 3.03058 13.399 3.08105 14.0772C3.27575 16.6932 3.3731 18.0012 4.68597 19.314C5.99884 20.6269 7.30684 20.7243 9.92282 20.9189C10.601 20.9694 11.3005 21 12 21C12.6995 21 13.399 20.9694 14.0772 20.9189C16.6932 20.7243 18.0012 20.6269 19.314 19.314C20.6269 18.0012 20.7243 16.6932 20.9189 14.0772C20.9694 13.399 21 12.6995 21 12C21 11.3005 20.9694 10.601 20.9189 9.92282C20.7243 7.30684 20.6269 5.99884 19.314 4.68597C18.0012 3.3731 16.6932 3.27575 14.0772 3.08105C13.399 3.03058 12.6995 3 12 3C11.3005 3 10.601 3.03058 9.92282 3.08105C7.30684 3.27575 5.99884 3.3731 4.68597 4.68597ZM13.0001 7.99887C13.0001 8.68859 12.4409 9.24773 11.7512 9.24773C11.0615 9.24773 10.5023 8.68859 10.5023 7.99887C10.5023 7.30914 11.0615 6.75 11.7512 6.75C12.4409 6.75 13.0001 7.30914 13.0001 7.99887ZM12.7071 11.2929C12.8946 11.4804 13 11.7348 13 12L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 13H10.5C9.94772 13 9.5 12.5523 9.5 12C9.5 11.4477 9.94772 11 10.5 11H12C12.2652 11 12.5196 11.1054 12.7071 11.2929Z"
                          fill="#969FA8"
                        />
                      </svg>
                    </template>
                    <template #default>
                      Уведомления по оплатам, событиям, изменениям и т.п.
                    </template>
                  </AppTooltipNew>
                  <span class="sms__none-block-text">
                    Номер для сервисных уведомлений
                  </span>
                </div>
                <AppCheckbox
                  v-else
                  v-model="mobileChecked"
                  label-size="l"
                  label="Получать уведомления в SMS"
                  @change="checkBoxTouched = true"
                />
              </header>
              <div class="card-set__card-body">
                <div class="card-set__item card-set__item_contact">
                  <div class="card-set__item-value">
                    {{ userInfo.mobile ? mobileFormatted(userInfo.mobile): '' }}
                    <a
                      class="link card-set__card-contact-change-link"
                      href="#"
                      @click.prevent="mobileModal = true; userInfo.mobile ? gtmNomerSendEvent('izmenit') : gtmPochtaSendEvent('dobavit')"
                    >{{ userInfo.mobile ? 'Изменить': 'Добавить номер' }}</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-set__card">
              <header class="card-set__card-header card-set__card-header_checked">
                <AppCheckbox
                  v-model="emailChecked"
                  label-size="l"
                  :disabled="!userInfo.email"
                  label="Получать уведомления на почту"
                  @change="checkBoxTouched = true"
                />
              </header>
              <div class="card-set__card-body">
                <div class="card-set__item card-set__item_contact">
                  <div class="card-set__item-value">
                    {{ userInfo.email ? userInfo.email: '' }}
                    <a
                      class="link card-set__card-contact-change-link"
                      href="#"
                      @click.prevent="emailModal = true; userInfo.email ? gtmPochtaSendEvent('izmenit') : gtmPochtaSendEvent('dobavit')"
                    >{{ userInfo.email ? 'Изменить': 'Добавить почту' }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form__bottom form__bottom_fixed">
            <button
              class="form__submit button button_primary"
              :disabled="!checkBoxTouched || isSubmitting"
            >
              Сохранить изменения
            </button>
          </div>
        </form>
      </div>
    </div>
    <teleport to="body">
      <app-modal
        v-if="mobileModal"
        title="Изменение контактных данных"
        @close="mobileModal = false"
      >
        <mobile-change-modal
          @success="onSuccess('mobile')"
          @cancel="mobileModal = false"
        />
      </app-modal>
      <app-modal
        v-else-if="emailModal"
        title="Изменение контактных данных"
        @close="emailModal = false"
      >
        <email-change-modal
          @success="onSuccess('email')"
          @cancel="emailModal = false"
        />
      </app-modal>
    </teleport>
    <app-toast
      v-if="showToast"
      :text="toastText"
      :type="toastType"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { mobileFormatted } from '@/utils/helpers';
import { useToast } from '@/hooks/toast';
import AppModal from '../ui/AppModal.vue';
import MobileChangeModal from './modals/MobileChangeModal';
import EmailChangeModal from './modals/EmailChangeModal.vue';
import AppToast from '../ui/AppToast.vue';
import { useGTM } from '@/hooks/gtm';
import AppCheckbox from '@/components/ui/AppCheckbox';
import AppTooltipNew from '@/components/ui/AppTooltipNew';

export default {
  components: { AppTooltipNew, AppCheckbox, AppModal, MobileChangeModal, EmailChangeModal, AppToast },
  setup () {
    const store = useStore();
    const gtm = useGTM();

    const userInfo = computed(() => store.getters['user/userInfo']);

    const informingStatus = computed(() => store.getters['settings/informingStatus']);

    const mobileChecked = ref(false);
    const emailChecked = ref(false);

    const loaded = ref(false);

    onMounted(async () => {
      try {
        if (!informingStatus.value) {
          await store.dispatch('settings/loadInformingStatus');
        }

        mobileChecked.value = informingStatus.value.sms;
        emailChecked.value = informingStatus.value.email;

        loaded.value = true;
      } catch (e) {
        // исключение не обрабатываем. Переменная loaded останется в значении false и значит на экране не будет отображаться информация
      }
    });

    const toast = useToast();

    const isSubmitting = ref(false);

    const onSubmit = async () => {
      gtm.push_events({ event: 'vntNast', eventCategory: 'nastroiki_uvedomlenii', eventAction: 'button_click', eventLabel: 'sohranit_izmeneniya' });

      isSubmitting.value = true;

      checkBoxTouched.value = false;

      const payload = {
        sms: mobileChecked.value,
        email: emailChecked.value
      };

      try {
        await store.dispatch('settings/enableDisableInforming', payload);
        toast.triggerToast();
      } catch (e) {
        toast.triggerToast('Произошла ошибка', 'error');
      } finally {
        isSubmitting.value = false;
      }
    };

    const checkBoxTouched = ref(false);

    const mobileModal = ref(false);
    const emailModal = ref(false);

    const onSuccess = (type) => {
      if (type === 'mobile') {
        mobileModal.value = false;
        toast.triggerToast('Номер изменен', 'ok');
      } else if (type === 'email') {
        emailModal.value = false;
        toast.triggerToast('Почта изменена', 'ok');
      }
    };

    const gtmPochtaSendEvent = async (eventType) => {
      let eventLabel;

      if (eventType === 'izmenit') {
        eventLabel = 'izmenit';
      } else if (eventType === 'dobavit') {
        eventLabel = 'dobavit_pochtu';
      }

      gtm.push_events({ event: 'vntNast', eventCategory: 'nastroiki_uvedomlenii', eventAction: 'element_click', eventLabel: eventLabel, eventContent: 'pochta' });
    };

    const gtmNomerSendEvent = async (eventType) => {
      let eventLabel;

      if (eventType === 'izmenit') {
        eventLabel = 'izmenit';
      } else if (eventType === 'dobavit') {
        eventLabel = 'dobavit_nomer';
      }

      gtm.push_events({ event: 'vntNast', eventCategory: 'nastroiki_uvedomlenii', eventAction: 'element_click', eventLabel: eventLabel, eventContent: 'nomer' });
    };

    return {
      userInfo,
      mobileFormatted,
      onSubmit,
      checkBoxTouched,
      mobileChecked,
      emailChecked,
      loaded,
      mobileModal,
      emailModal,
      onSuccess,
      ...toast,
      isSubmitting,
      gtmPochtaSendEvent,
      gtmNomerSendEvent,
      informingStatus
    };
  }

};
</script>

<style scoped>

.sms__none-block {
  display: flex;
}

.sms__none-block-text {
  margin-left: 12px;
}
</style>
