<template>
  <div class="input-field">
    <label
      class="input-field__label"
      :for="uid"
    >{{ labelName }}</label>
    <div
      ref="ddBox"
      class="input-field__dropdown-box"
      @click="toggle.switch()"
    >
      <input
        :id="uid"
        :value="modelValue.displayName"
        class="input-field__dropdown-value input-field__dropdown-value-container"
        type="text"
        readonly
      >
      <div :class="['input-field__dropdown-list-container', {'input-field__dropdown-list-container_visible': toggle.is(true)}]">
        <div class="input-field__dropdown-list">
          <div
            v-for="item in availableItems"
            :key="item.id"
            :class="['input-field__dropdown-list-item', {'input-field__dropdown-list-item_selected': modelValue.id === item.id}]"
            @click="onItemClick(item)"
          >
            {{ item.displayName }}
          </div>
        </div>
      </div>
    </div>
    <div class="input-field__description" />
  </div>
</template>

<script>
import { useToggle } from '@/hooks/toggle';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    labelName: {
      type: String
    },
    availableItems: {
      type: Array,
      defaut: []
    }
  },
  emits: ['update:modelValue', 'select'],
  setup (props, { emit }) {
    const { toggle } = useToggle(false);
    const ddBox = ref();

    onMounted(() => {
      document.addEventListener('click', hideList);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', hideList);
    });

    const onItemClick = (item) => {
      emit('update:modelValue', item);
      emit('select');
    };

    function hideList (e) {
      if (!e.composedPath().includes(ddBox.value)) {
        toggle.off();
      }
    }
    return { toggle, onItemClick, uid: Math.random(), ddBox };
  }

};
</script>

<style>

</style>
