import { checkValidPassword } from '@/utils/helpers';

export const TYPE_LOGIN = {
  password: 'password',
  chipId: 'ChipID'
};

export const VARIABLES_LOCAL_LOGIN = {
  typeLogin: 'typeLogin',
  validPassword: 'validPassword'
};
export const useTypeLoginPassword = () => {
  const removeItem = (item) => {
    localStorage.removeItem(item);
  };

  const setItem = (item, value) => {
    localStorage.setItem(item, value);
  };

  const getItem = (item) => {
    return localStorage.getItem(item);
  };

  const setTypeLogin = (typeLogin) => {
    setItem(VARIABLES_LOCAL_LOGIN.typeLogin, typeLogin);
  };

  const setValidPassword = (typePass) => {
    setItem(VARIABLES_LOCAL_LOGIN.validPassword, typePass);
  };

  const getTypeLogin = () => {
    return getItem(VARIABLES_LOCAL_LOGIN.typeLogin);
  };

  const getValidPassword = () => {
    return getItem(VARIABLES_LOCAL_LOGIN.validPassword);
  };

  const removeTypeLoginPassword = () => {
    removeItem(VARIABLES_LOCAL_LOGIN.typeLogin);
    removeItem(VARIABLES_LOCAL_LOGIN.validPassword);
  };

  const authSetType = (type, password) => {
    setTypeLogin(type);
    if (type === TYPE_LOGIN.password) {
      setValidPassword(checkValidPassword(password).toString());
    }
  };

  return {
    setTypeLogin,
    getTypeLogin,
    getValidPassword,
    removeTypeLoginPassword,
    authSetType,
    removeItem,
    setItem,
    getItem
  };
};
