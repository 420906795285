<template>
  <div class="page__content page__content_regular">
    <div class="page__nav">
      <div
        class="page__nav-btn-back"
        @click.prevent="!selectArticle ? $router.push({name: 'support'}) : selectArticle = null"
      />
    </div>
    <div class="blocks page__content-blocks">
      <div
        v-if="isDesktop"
        class="blocks__item"
      >
        <h1 class="page__nav-title page__nav-title_margin">
          {{ category.name }}
        </h1>
        <AppCMSContent
          v-for="item in items"
          :key="item.id"
          :content="item.content"
          class="article"
        />
        <div
          v-if="!items?.length"
          class="page__nav-title"
        >
          {{ text.notContent }}
        </div>
        <AppPaginate
          v-model="page"
          :view-page-size="false"
          :view-arrow="false"
          :click-handler="pageChangeHandler"
          :click-size-handler="pageSizeChangeHandler"
          :page-size="pageSize"
          :page-count="pageCount"
          :total-rows="totalRows"
          class="page__nav-pagination"
        />
      </div>
      <div
        v-else-if="!selectArticle"
        class="page__content"
      >
        <div class="page__nav" />
        <div class="blocks page__content-blocks">
          <div class="blocks__item">
            <h1 class="page__nav-title page__nav-title_margin">
              {{ category.name }}
            </h1>
            <div class="nav-page-menu">
              <div
                v-for="item in category.articles"
                :key="item.id"
                class="nav-page-menu__link nav-page-menu_link-not-img"
                @click="toSelectArticle(item)"
              >
                <span class="text-ellipsis">
                  {{ item.name }}
                </span>
              </div>
              <div
                v-if="!category.articles.length"
                class="page__nav-title"
              >
                {{ text.notContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppCMSContent
        v-else
        class="article"
        :content="selectArticle.content"
      />
    </div>
  </div>
</template>

<script setup>

import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useWindowSize } from '@/hooks/window-size';
import AppPaginate from '@/components/ui/AppPaginate.vue';
import { usePaginate } from '@/hooks/paginate';
import AppCMSContent from '@/components/ui/AppCMSContent.vue';

const text = {
  notContent: 'В данном разделе статьи отсутствуют'
};

const page = ref(1);
const { windowSize } = useWindowSize();
const {
  pageSize,
  pageCount,
  totalRows,
  setupPagination,
  pageChangeHandler,
  pageSizeChangeHandler,
  items,
  reset
} = usePaginate(() => {}, 1);
const store = useStore();
const route = useRoute();
const router = useRouter();
const category = computed(() => store.getters['cms/getSupportChapter'].find(el => el.code === route.params.category) || { articles: [] });

setupPagination(category.value.articles);

const isDesktop = computed(() => windowSize.value >= 1280);
const selectArticle = ref(null);

const toSelectArticle = (item) => {
  selectArticle.value = item;
  window.scroll({
    top: 0
  });
};

watch(() => route.params.category, () => {
  if (!isDesktop.value) return;
  page.value = 1;
  reset(category.value?.articles.length ? category.value.articles : [], 1);
});

onMounted(() => {
  if (!category.value.articles.length) router.push({ name: 'home' });
});
</script>

<style scoped>
.article {
  padding: 0 !important;
}
.page__nav-pagination {
  margin-top: 20px;
}
</style>
