<template>
  <Card
    v-if="signedDocuments.length"
    title="Подписанные документы"
    :title-bottom="24"
  >
    <DocumentSigned
      v-for="document in items"
      :key="document.docID"
      :document="document"
    />
    <AppPaginate
      v-model="page"
      class="paginate"
      :view-page-size="false"
      :view-arrow="false"
      :total-rows="totalRows"
      :page-count="pageCount"
      :page-size="pageSize"
      :click-handler="pageChangeHandler"
      :click-size-handler="pageSizeChangeHandler"
    />
  </Card>
</template>

<script>

import { usePaginate } from '@/hooks/paginate';
import { computed, watch } from 'vue';
import DocumentSigned from '@/components/settings/entities/DocumentSigned';
import AppPaginate from '@/components/ui/AppPaginate';
import Card from '@/components/settings/entities/Card';
import { useStore } from 'vuex';

export default {
  components: { Card, AppPaginate, DocumentSigned },
  setup () {
    const store = useStore();
    const signedDocuments = computed(() => store.getters['settings/signedDocuments']);
    const paginate = usePaginate(() => {}, 3);

    paginate.setupPagination(signedDocuments.value);

    watch(signedDocuments, newValue => {
      paginate.reset(newValue, 3);
    });
    return { ...paginate, signedDocuments };
  }
};

</script>

<style scoped>

.paginate {
  margin-top: 32px;
}

</style>
