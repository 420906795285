<template>
  <div class="tbody">
    <div
      v-for="(week, index) in month"
      :key="index"
      class="row"
    >
      <button
        v-for="(day, indexDay) in week"
        :key="indexDay"
        :class="['mobile__cell',
                 {'select__day': isSelectDay(day, monthValue, year, selectDate[0])},
                 {'select__day_start': isSelectDay(day, monthValue, year, selectDate[0]) && selectDate[1] && props.range},
                 {'select__day-end': isSelectDay(day, monthValue, year, selectDate[1])},
                 {'today__day': isToday(day, monthValue, year)},
                 {'range__mobile-date': day && isRangeDates(selectDate[0], selectDate[1], new Date(year, monthValue, day)) }]"
        :disabled="new Date(year, monthValue, day) > new Date() || !day || new Date(year, monthValue, day)<min || new Date(year, monthValue, day)>max"
        @click.prevent="handleCLick(day)"
      >
        <span
          :class="{'select__day-content':isSelectDay(day, monthValue, year, selectDate[0]) || isSelectDay(day, monthValue, year, selectDate[1])}"
        >
          {{ day }}
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue';
import { scroll } from '@/components/ui/AppDatePickerMobile/helpers/scroll';
import { isSelectDay } from '@/components/ui/AppDatePickerMobile/helpers/isSelectDay';
import { isToday } from '@/components/ui/AppDatePickerMobile/helpers/isToday';
import { isRangeDates } from '@/components/ui/AppDatePickerMobile/helpers/isRangeDates';

const props = defineProps({
  year: Number,
  month: [String, Number],
  selectDate: Array,
  range: Boolean,
  min: Date,
  max: Date
});

const monthValue = computed(() => {
  if (typeof props.month === 'number') return props.month;
  switch (props.month) {
    case 'Январь': return 0;
    case 'Февраль': return 1;
    case 'Март': return 2;
    case 'Апрель': return 3;
    case 'Май': return 4;
    case 'Июнь': return 5;
    case 'Июль': return 6;
    case 'Август': return 7;
    case 'Сентябрь': return 8;
    case 'Октябрь': return 9;
    case 'Ноябрь': return 10;
    case 'Декабрь': return 11;
  }
  return 0;
});

let lastDateOfMonth = new Date(props.year, monthValue.value + 1, 0).getDate();
let month = [];
let week = [];

watch(monthValue, newValue => {
  month = [];
  week = [];
  lastDateOfMonth = new Date(props.year, newValue + 1, 0).getDate();
  drawMonth(newValue);
});

const drawMonth = (monthArg) => {
  for (let i = 1; i <= lastDateOfMonth; i++) {
    const dayWeek = new Date(props.year, monthArg, i).getDay();
    week.push(new Date(props.year, monthArg, i).getDate());
    if (i === lastDateOfMonth && week.length !== 7) {
      while (week.length !== 7) {
        week.push('');
      }
      month.push(Array.from(week));
      week.length = 0;
      break;
    }
    if (dayWeek === 0) {
      while (week.length !== 7) {
        week.unshift('');
      }
      month.push(Array.from(week));
      week.length = 0;
    }
  }
};

drawMonth(monthValue.value);
const emit = defineEmits(['selectDay', 'setTop']);

const handleCLick = (day) => {
  emit('selectDay', new Date(props.year, monthValue.value, day));
};

onMounted(() => {
  const select = document.querySelector('.select__day');
  const today = document.querySelector('.today__day');
  if (!select && !today) {
    scroll('smooth');
    return;
  }
  if (select) {
    emit('setTop', select?.offsetTop);
    return;
  }
  emit('setTop', today.offsetTop);
});

</script>

<style scoped>
.tbody {
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row:not(:last-child) {
  margin-bottom: 8px;
}

.select__day {
  color: white;
  border-radius: 6px;
  position: relative;
}

.select__day_start {
  color: white;
  border-radius: 6px;
  position: relative;
}

.select__day_start:after {
  content: '';
  width: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: rgba(188, 195, 208, 0.25);
  display: block;
  height: 100%;
}

.select__day-end {
  color: white;
  border-radius: 6px;
  position: relative;
}

.select__day-end:before {
  content: '';
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(188, 195, 208, 0.25);
  display: block;
  height: 100%;
}

.select__day_start.select__day-end:before,
.select__day-end.select__day_start:after{
  display: none;
}

.select__day-content {
  background-color: #1D2023;
  position: absolute;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 32px;
  z-index: 5;
}

.disabled__day {
  color: #969FA8;
}

.today__day {
  font-weight: 500;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
}

.range__mobile-date {
  background-color: rgba(188, 195, 208, 0.25);
}

.range__mobile-date:hover {
  border-radius: 0;
}
</style>
