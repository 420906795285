<template>
  <teleport to="body">
    <div :class="['mts__opros', {'mts__opros-hide': !openedFrame}]">
      <div class="iframe__wrapper">
        <iframe
          id="mts__opros"
          ref="iFrame"
          class="opros__frame"
          frameborder="false"
        />
        <div
          class="popup__close-frame"
          @click="openedFrame = false"
        />
      </div>
    </div>
  </teleport>
</template>

<script setup>

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { computed, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const iFrame = ref(null);
const openedFrame = ref(false);
const userInfo = computed(() => store.getters['user/userInfo']);
const randomString = (Math.random() + 1).toString(36).substring(2);
const { storage } = useLocalStorage('mts-opros-token', randomString);

const script = document.createElement('script');
script.src = 'https://mtsopros.mts.ru/libsdk/sdk.v6.js';
document.head.append(script);

const closeFrame = (e) => {
  if (e.data === 'pollPlayerClose') {
    openedFrame.value = false;
  }
};

script.onload = () => {
  const appMetaData = {
    appId: process.env.VUE_APP_ID_OPROS,
    token: storage.value,
    msisdnMain: userInfo.value.personalAccount,
    msisdnSlave: userInfo.value.mobile || '',
    operatingSystem: 'Windows',
    operatingSystemVersion: '18907',
    appVersion: '14.43'
  };

  const launchPoll = (url) => {
    iFrame.value.setAttribute('src', url);
    iFrame.value.onload = () => {
      openedFrame.value = true;
    };
  };

  tNPS.start(
    launchPoll,
    appMetaData
  );

  window.addEventListener('message', closeFrame);
  setTimeout(() => {
    tNPS.registerEvent(process.env.VUE_APP_EVENT_ID);
  }, 120_000);
};

onUnmounted(() => {
  script.remove();
  window.removeEventListener('message', closeFrame);
});

</script>

<style scoped>
.mts__opros-hide {
  visibility: hidden;
  opacity: 0;
}

.popup__close-frame {
  cursor: pointer;
  position: absolute;
  z-index: 0;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  background: var(--secondary-grey-ultra-light) url("../../assets/svg/close-btn.svg") center center no-repeat;
  border-radius: 32px;
}

.opros__frame {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.mts__opros {
  position: fixed;
  z-index: 99;
  bottom: 50px;
  right: 50px;
  width: 400px;
  height: 620px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s ease;
}

.iframe__wrapper {
  position: relative;
  max-width: 400px;
  max-height: 620px;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .mts__opros {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(29, 32, 35, 0.4);
    width: unset;
    height: unset;
    align-items: flex-end;
  }
  .iframe__wrapper {
    max-width: 100%;
    margin: 20px;
  }
}
</style>
