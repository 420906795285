<template>
  <div class="page__content_regular">
    <div class="page__nav">
      <div
        class="page__nav-btn-back"
        @click.prevent="$router.push({name: 'settings'})"
      />
    </div>
    <div class="blocks page__content-blocks">
      <div class="blocks__item">
        <h1 class="page__nav-title page__nav-title_margin">
          История уведомлений
        </h1>
        <div class="notice-log log">
          <form
            class="form notice-log__form"
            @submit.prevent="onSubmit"
          >
            <div class="input-field">
              <label
                class="input-field__label"
                for="finance__range"
              >Начало периода</label>
              <AppDatePicker
                v-model="date1"
                :max="date2"
              />
            </div>
            <div class="input-field">
              <label
                class="input-field__label"
                for="finance__range"
              >Конец периода</label>
              <AppDatePicker
                v-model="date2"
                :min="date1"
              />
            </div>
            <div class="form__bottom notice-log__form-submit form__bottom_fixed">
              <button
                class="form__submit button button_primary button_fluid"
                :disabled="!formValid || loading"
              >
                Применить
              </button>
            </div>
          </form>

          <table class="notice-log__items log__items">
            <thead class="notice-log__items-header">
              <tr class="notice-log__item">
                <th class="log__header-col notice-log__header-col">
                  Дата и время
                </th>
                <th class="log__header-col notice-log__header-col">
                  Тип
                </th>
                <th class="log__header-col notice-log__header-col">
                  Статус
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="notification in items"
                :key="notification"
                class="notice-log__item log__item"
              >
                <td class="log__item-col notice-log__datetime">
                  <div class="notice-log__date">
                    {{ dateFormatter(notification.date, 1) }}
                  </div>
                  <div class="notice-log__time">
                    {{ timeFormatter(notification.date) }}
                  </div>
                </td>
                <td class="log__item-col notice-log__message">
                  {{ notification.type }}
                </td>
                <td class="log__item-col notice-log__status">
                  {{ notification.message ? notification.message : notification.error ? 'Ошибка' : 'Выполнено' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="notice-log__pagination">
            <app-paginate
              v-model="page"
              :total-rows="totalRows"
              :page-count="pageCount"
              :page-size="pageSize"
              :click-handler="pageChangeHandler"
              :click-size-handler="pageSizeChangeHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { dateFormatter, timeFormatter } from '../../utils/helpers';
import AppPaginate from '../ui/AppPaginate.vue';
import { usePaginate } from '../../hooks/paginate.js';
import { FormatInputDate } from '@/utils/FormatInputDate';
import { getMonth, getMonthSmall } from '@/utils/getMounth';
import AppDatePicker from '@/components/ui/AppDatePickerMobile/AppDatePicker';
import { useGTM } from '@/hooks/gtm';

export default {
  components: { AppDatePicker, AppPaginate },
  setup () {
    const store = useStore();
    const paginate = usePaginate();
    const isCalendarMobile = ref(window.innerWidth <= 600);
    const gtm = useGTM();

    const loading = ref(true);
    const date1 = ref();
    const date2 = ref();

    const resize = () => {
      isCalendarMobile.value = window.innerWidth <= 600;
    };

    onMounted(async () => {
      window.addEventListener('resize', resize);
      try {
        const notifications = await store.dispatch('settings/loadNotifications');
        paginate.setupPagination(notifications);
      } catch (e) {} finally {
        loading.value = false;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resize);
    });

    const onSubmit = async () => {
      try {
        gtm.push_events({ event: 'vntNast', eventCategory: 'istoriya_uvedomlenii', eventAction: 'button_click', eventLabel: 'primenit' });
        loading.value = true;

        const payload = { start: dateFormatter(date1.value, 4), end: dateFormatter(date2.value, 4), lines: 9999 };
        const notifications = await store.dispatch('settings/loadNotifications', payload);

        paginate.reset(notifications);
      } catch (e) {
      } finally {
        loading.value = false;
      }
    };

    const formValid = computed(() => {
      return date1.value && date2.value && date1.value <= date2.value;
    });

    watch(date1, async () => {
      gtm.push_events({ event: 'vntNast', eventCategory: 'istoriya_uvedomlenii', eventAction: 'filter_apply', eventLabel: 'nachalo_perioda' });
    });

    watch(date2, async () => {
      gtm.push_events({ event: 'vntNast', eventCategory: 'istoriya_uvedomlenii', eventAction: 'filter_apply', eventLabel: 'konec_perioda' });
    });

    return { isCalendarMobile, date1, date2, loading, dateFormatter, timeFormatter, onSubmit, formValid, ...paginate };
  },
  methods: { FormatInputDate, getMonth, getMonthSmall }
};

</script>
