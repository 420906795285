<template>
  <div class="year__select-wrapper">
    <button
      v-for="year in listYears"
      :key="year"
      :class="['year__select-button',{'now-year':year===props.year},
               {'select-year':(selectDate[0] && selectDate[0].getFullYear()===year || selectDate[1] && selectDate[1].getFullYear()===year)}]"
      :disabled="new Date().getFullYear()<year"
      @click="handleCLick(year)"
    >
      {{ year }}
    </button>
  </div>
</template>

<script setup>

import { computed, onMounted } from 'vue';

const props = defineProps({
  year: Number,
  selectDate: Array
});
const emit = defineEmits(['setYear']);

const listYears = computed(() => {
  const temp = [];
  for (let i = props.year - 99; i < props.year + 99; i++) {
    temp.push(i);
  }
  return temp;
});

const handleCLick = (newYear) => {
  emit('setYear', newYear);
};

onMounted(() => {
  document.querySelector('.now-year')?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
});

</script>

<style scoped>
.year__select-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.year__select-button {
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  font-size: 17px;
  line-height: 24px;
  color: #1D2023;
  background-color: transparent;
  border: none;
  width: calc((100% / 3) - 16px);
  padding: 3px;
  margin: 8px;
  border-radius: 6px;
}

.year__select-button:disabled {
  background-color: #f6f6f6;
}

.now-year {
  font-family: "MTSCompactMedium", "MTS Sans", serif;
  font-weight: 500;
}

.select-year {
  background-color: #1D2023;
  color: #fff;
}

</style>
