<template>
  <div class="create__rate-wrapper">
    <svg
      v-if="!isSubmit"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8060_319704)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.194233 16.9251C0.670174 10.5302 0.908144 7.33275 4.11752 4.12337C7.3269 0.913998 10.5243 0.676029 16.9192 0.20009C18.577 0.0767148 20.287 0.00195312 21.997 0.00195312C23.707 0.00195312 25.417 0.0767161 27.0747 0.200092C33.4696 0.676033 36.6671 0.914004 39.8765 4.12338C43.0858 7.33276 43.3238 10.5302 43.7997 16.9251C43.9231 18.5828 43.9979 20.2928 43.9979 22.0029C43.9979 23.7129 43.9231 25.4229 43.7997 27.0806C43.3238 33.4755 43.0858 36.6729 39.8765 39.8823C36.6671 43.0917 33.4696 43.3297 27.0747 43.8056C25.417 43.929 23.707 44.0037 21.997 44.0037C20.287 44.0037 18.577 43.929 16.9192 43.8056C10.5243 43.3297 7.32689 43.0917 4.11751 39.8823C0.908139 36.6729 0.670169 33.4755 0.194231 27.0806C0.0708554 25.4229 -0.00390625 23.7129 -0.00390625 22.0028C-0.00390625 20.2928 0.0708567 18.5828 0.194233 16.9251Z"
          fill="#FAC031"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.0027 24.1317C23.3294 24.1317 24.4049 23.0394 24.4049 21.6921L24.4049 11.9396C24.4049 10.5922 23.3294 9.5 22.0027 9.5C20.676 9.5 19.6005 10.5923 19.6005 11.9396L19.6005 21.6921C19.6005 23.0394 20.676 24.1317 22.0027 24.1317ZM25 31.4533C25 29.7706 23.6569 28.4065 22 28.4065C20.3431 28.4065 19 29.7706 19 31.4533C19 33.1359 20.3431 34.5 22 34.5C23.6569 34.5 25 33.1359 25 31.4533Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8060_319704">
          <rect
            width="44"
            height="44"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-else
      class="success-icon"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7311_89358)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.194233 16.9251C0.670174 10.5302 0.908144 7.33275 4.11752 4.12337C7.3269 0.913998 10.5243 0.676029 16.9192 0.20009C18.577 0.0767148 20.287 0.00195312 21.997 0.00195312C23.707 0.00195312 25.417 0.0767161 27.0747 0.200092C33.4696 0.676033 36.6671 0.914004 39.8765 4.12338C43.0858 7.33276 43.3238 10.5302 43.7997 16.9251C43.9231 18.5828 43.9979 20.2928 43.9979 22.0029C43.9979 23.7129 43.9231 25.4229 43.7997 27.0806C43.3238 33.4755 43.0858 36.6729 39.8765 39.8823C36.6671 43.0917 33.4696 43.3297 27.0747 43.8056C25.417 43.929 23.707 44.0037 21.997 44.0037C20.287 44.0037 18.577 43.929 16.9192 43.8056C10.5243 43.3297 7.32689 43.0917 4.11751 39.8823C0.908139 36.6729 0.670169 33.4755 0.194231 27.0806C0.0708554 25.4229 -0.00390625 23.7129 -0.00390625 22.0028C-0.00390625 20.2928 0.0708567 18.5828 0.194233 16.9251Z"
          fill="#26CD58"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.2837 17.6173C33.2384 16.6626 33.2384 15.1149 32.2837 14.1603C31.3291 13.2057 29.7814 13.2057 28.8268 14.1603L19.5553 23.4318L16.3949 20.2714C15.4402 19.3168 13.8925 19.3168 12.9379 20.2714C11.9833 21.226 11.9833 22.7738 12.9379 23.7284L17.8268 28.6173C18.7814 29.5719 20.3291 29.5719 21.2837 28.6173L32.2837 17.6173Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7311_89358">
          <rect
            width="44"
            height="44"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
    <p class="create__rate-title">
      <span v-if="!isSubmit">
        Вы уверены, что хотите<br>
        перейти на новый тариф?
      </span>
      <span v-else>
        Заявка на смену тарифа принята.<br>
        Ожидайте SMS-уведомления
      </span>
    </p>
    <p
      v-if="!isSubmit && resources[0].tariffArchive"
      class="create__rate-archive"
    >
      Ваш тарифный план является архивным, после перехода возврат к нему будет невозможен
    </p>
    <p
      v-if="isSubmit"
      class="create__rate-archive"
    >
      Не забудьте пополнить баланс,  иначе часть услуг может быть заблокирована
    </p>
    <div class="create__rate-wrapper-button">
      <AppButton
        fluid
        color="button_secondary-bg-gray"
        @click="onOut"
      >
        {{ !isSubmit? "Отмена": "Пополнить баланс" }}
      </AppButton>
      <AppButton
        :loading="isLoading"
        fluid
        @click="onSubmit"
      >
        {{ !isSubmit? "Да, перейти": "На главную" }}
      </AppButton>
    </div>
  </div>
</template>

<script setup>
import AppButton from '@/components/ui/AppButton';
import { computed, ref } from 'vue';
import { useGTM } from '@/hooks/gtm';
import { useStore } from 'vuex';
import { dateFormatter } from '@/utils/helpers';

const props = defineProps({
  resId: {
    type: String,
    required: true
  },
  tariffId: {
    type: String,
    required: true
  },
  tariff: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(['close', 'cancel', 'ok', 'error', 'onSubmit']);
const store = useStore();
const resources = computed(() => store.getters['resource/resources']);
const isSubmit = ref(false);
const isLoading = ref(false);
const gtm = useGTM();

const onSubmit = async () => {
  if (!isSubmit.value) {
    try {
      isLoading.value = true;

      gtm.push_events({ event: 'vntTarif', eventCategory: 'tarif', eventAction: 'button_click', eventLabel: 'smenit_tarif', eventContent: gtm.translit_ru_en(props.tariff.name) });
      const payload = { resId: props.resId, tariffId: props.tariffId, date: dateFormatter(new Date(), 4) };
      await store.dispatch('tariffs/tariffChangeCreate', payload);
      gtm.push_events({ event: 'vntTarif', eventCategory: 'tarif', eventAction: 'confirmed', eventLabel: 'smena_tarifa_zaplanirovana', eventContent: gtm.translit_ru_en(props.tariff.name), actionGroup: 'conversions' });

      isSubmit.value = true;
      emit('onSubmit');
    } catch (e) {
      emit('error', e);
    } finally {
      isLoading.value = false;
    }
    return;
  }

  emit('ok');
};

const onOut = () => {
  if (!isSubmit.value) {
    emit('close');
    return;
  }
  emit('cancel');
};
</script>

<style scoped>

.create__rate-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create__rate-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 16px;
  text-align: center;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
}

.create__rate-archive {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  margin-top: 8px;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
}

.create__rate-wrapper-button {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 20px;
}

.success-icon {
  animation: success .5s 1 ease;
}

@keyframes success {
  0%, 100%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
}

@media (max-width: 767px) {
  .create__rate-wrapper-button {
    flex-direction: column;
  }
}

</style>
