<template>
  <div class="swiper-mobile-loader-wrapper">
    <div class="swiper-mobile-loader" />
    <div
      ref="loader"
      class="swiper-mobile-loader-red"
    />
  </div>
</template>

<script setup>

import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  timerSeconds: {
    type: Number
  }
});

const loader = ref(null);
watch(() => props.timerSeconds, newValue => {
  loader.value.style.setProperty('--timer', `${newValue}s`);
});
onMounted(() => loader.value.style.setProperty('--timer', `${props.timerSeconds}s`));

</script>

<style scoped>

.swiper-mobile-loader-wrapper {
  --height: 8px;
  position: absolute;
  top: calc(100% - var(--height));
  left: 0;
  right: 0;
  height: var(--height);
  display: block;
}

.swiper-mobile-loader {
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255, .5);
}
.swiper-mobile-loader-red {
  content: '';
  --timer: 9;
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: var(--primary-light);
  animation: swiperMobileLoader var(--timer) 1 linear;
}

@keyframes swiperMobileLoader {
  0%{
    width: 0;
  }
  100%{
    width: 100%;
  }
}

</style>
