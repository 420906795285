<template>
  <div class="select__year">
    <button
      v-for="year in yearList"
      :key="year"
      :class="['year',
               {'active':isActive(year)},
               {'year__range': isRange(year)},
               {'start__select':selectDate[0] && selectDate[1] && selectDate[0].getFullYear()===year && selectDate[0].getFullYear()!==selectDate[1].getFullYear()}]"
      :disabled="max && max.getFullYear()<year || min && min.getFullYear()>year || (new Date()).getFullYear()<year"
      @click.prevent="$emit('setYear', year)"
    >
      <span>
        {{ year }}
      </span>
    </button>
  </div>
</template>

<script setup>
import { isRangeDates } from '@/components/ui/AppDatePickerMobile/helpers/isRangeDates';
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  year: Number,
  selectDate: Array,
  yearsRange: Array,
  min: Date,
  max: Date
});
const yearList = ref();

const init = () => {
  yearList.value = [props.yearsRange[0]];
  for (let i = yearList.value[0] + 1; i < yearList.value[0] + 12; i++) {
    yearList.value.push(i);
  }
};

watch(props, () => {
  init();
});

onMounted(() => {
  init();
});

const isActive = (year) => {
  if (!props.selectDate.length) return false;
  if (props.selectDate.length === 1) return props.selectDate[0].getFullYear() === year;
  if (!props.selectDate[1]) return props.selectDate[0] && props.selectDate[0].getFullYear() === year;
  return props.selectDate[0] && props.selectDate[1] && props.selectDate[0].getFullYear() === year || props.selectDate[1].getFullYear() === year;
};

const isRange = (year) => {
  if (props.selectDate.length < 2) return false;
  return isRangeDates(props.selectDate[0], props.selectDate[1], new Date(year, 0, 0));
};

</script>

<style scoped>

.select__year {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.year {
  width: 33%;
  height: 32px;
  flex: 1 1 auto;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  position: relative;
  transition: background-color .1s ease;
}

.year:hover {
  background-color: rgba(188, 195, 208, 0.25);
  border-radius: 6px;
}

.year:hover:disabled {
  cursor: not-allowed;
  background-color: transparent;
}

.year:not(:nth-last-child(-n+3)){
  margin-bottom: 8px;
}

.active span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #1D2023;
  border-radius: 6px;
  width: 72px;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.year__range {
  background-color: rgba(188, 195, 208, 0.25);
}

.year__range:hover {
  border-radius: 0;
}

.active:hover {
  background-color: transparent;
}

.active.year__range{
  background-color: transparent;
  position: relative;
}
.active.year__range:after {
  content:'';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background-color: rgba(188, 195, 208, 0.25);
  z-index: -1;
}

.start__select:after {
  content:'';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  background-color: rgba(188, 195, 208, 0.25);
  z-index: -1;
}

</style>
