<template>
  <div :class="['page__header-container-white',{'page__header-container': route.path!=='/'}]">
    <div :class="['page__header', `page__header_${asideType}`]">
      <div class="page__header-top">
        <div class="page__header-nav">
          <router-link
            to="/"
            @click="gtm.push_events({'event': 'vntCross', 'eventCategory': 'kross_elementy', 'eventAction': 'element_click', 'eventLabel': 'logotip', 'buttonLocation': 'header'})"
          >
            <picture class="mts-logo">
              <source
                srcset="../assets/svg/logo_new-desktop.svg"
                media="(min-width:1280px )"
              >
              <img
                src="../assets/svg/logo_new.svg"
                alt="Логотип"
              >
            </picture>
          </router-link>
          <div class="page__header-nav-items">
            <router-link
              to="/"
              class="page__header-nav-link"
              href="#"
              @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'glavnaya', 'buttonLocation': 'header'})"
            >
              Главная
            </router-link>
            <router-link
              to="/finance"
              class="page__header-nav-link"
              href="#"
              @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'finansy', 'buttonLocation': 'header'})"
            >
              Финансы
            </router-link>
            <router-link
              to="/services"
              class="page__header-nav-link"
              href="#"
              @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'uslugi', 'buttonLocation': 'header'})"
            >
              Услуги
            </router-link>
            <router-link
              to="/settings"
              class="page__header-nav-link"
              href="#"
              @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'nastroiki', 'buttonLocation': 'header'})"
            >
              Настройки
            </router-link>
            <router-link
              v-if="specialOffers?.length || stockPartners?.length"
              to="/stock"
              class="page__header-nav-link"
              href="#"
              @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'bonusi', 'buttonLocation': 'header'})"
            >
              Бонусы
            </router-link>
            <router-link
              v-if="supportsNavList.length"
              to="/support"
              class="page__header-nav-link"
              href="#"
              @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'podderzhka', 'buttonLocation': 'header'})"
            >
              Поддержка
            </router-link>
          </div>
        </div>
        <div class="page__header-bar">
          <button
            v-if="specialOffers?.length || stockPartners?.length"
            class="page__header-bar-stock"
            @click="gtm.push_events({'event': 'vntMenu', 'eventCategory': 'menu', 'eventAction': 'menu_click', 'eventLabel': 'bonusi', 'buttonLocation': 'header'}); isStockModal = true"
          >
            Акции
          </button>
          <router-link
            to="/settings/notifications"
            class="page__header-bar-notices"
          />
          <a
            class="page__header-bar-logout"
            href="#"
            @click.prevent="showConfirmDialog"
          />
        </div>
      </div>

      <div
        v-if="showAccount && userInfo"
        class="page__header-account"
      >
        <div
          class="page__account-selector"
          @click.prevent="toggle.switch"
        >
          <div class="page__account-selector-value">
            <div class="page__account-selector-item">
              <div class="page__account-avatar">
                <img
                  v-if="!isConvegrentSelected"
                  class="page__account-avatar-img"
                  src="../assets/content/icon-avatar.svg"
                >
                <img
                  v-else
                  class="page__account-avatar-img"
                  src="../assets/svg/icon-link.svg"
                >
              </div>
              <div class="page__account-description">
                <div class="page__account-title">
                  {{ !isConvegrentSelected ? displayName: 'Мобильный интернет' }}
                </div>{{ !isConvegrentSelected ? userInfo.personalAccount: 'ЛС №' + mobileFormatted(userInfo.mobileConvergent) }}
              </div>
            </div>
          </div>
          <div :class="['page__account-selector-items-container', {'page__account-selector-items-container_visible': toggle.is(true)}]">
            <div
              v-if="canScrolls.canScrollPrev"
              class="page__account-selector-nav_prev page__account-selector-nav"
              @click="scrollPrev"
            />
            <div
              v-if="canScrolls.canScrollNext"
              class="page__account-selector-nav_next page__account-selector-nav"
              @click="scrollNext"
            />

            <div
              ref="accountItems"
              class="page__account-selector-items"
              @scroll="onScroll"
            >
              <div
                v-for="item in jwtData.contracts"
                :key="item"
                :class="['page__account-selector-item', {'page__account-selector-item_available': !(!isConvegrentSelected && userInfo.personalAccount === item.PersonalAccount)}, {'page__account-selector-item_selected': !isConvegrentSelected && userInfo.personalAccount === item.PersonalAccount}]"
                @click.stop.prevent="accountClicked(item)"
              >
                <div class="page__account-avatar">
                  <img
                    class="page__account-avatar-img"
                    src="../assets/content/icon-avatar.svg"
                  >
                </div>
                <div class="page__account-description">
                  <div class="page__account-title">
                    {{ displayName }}
                  </div>ЛС №{{ item.PersonalAccount }}
                </div>
              </div>

              <div
                v-if="needShowConvergent"
                :class="['page__account-selector-item', {'page__account-selector-item_available': !isConvegrentSelected}, {'page__account-selector-item_selected': isConvegrentSelected}]"
                @click.stop.prevent="convergentClicked"
              >
                <div class="page__account-avatar page__account-avatar_link" />
                <div class="page__account-description">
                  <div class="page__account-title">
                    Мобильный интернет
                  </div>{{ mobileFormatted(userInfo.mobileConvergent) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialog
      v-if="showConfirmModal"
      title="Хотите выйти?"
      text=""
      ok-button-text="Выйти"
      cancel-button-text="Отменить"
      :ok-action="() => {}"
      @ok="onDialogSuccess"
      @cancel="onDialogCancel"
      @error="onDialogError"
    />
  </div>
  <swipe-modal
    v-model="isStockModal"
    background-color="#1D202366"
    contents-height="90%"
    border-top-radius="16px"
  >
    <StockListSection scroll-content />
  </swipe-modal>
</template>

<script>
import { computed, defineAsyncComponent, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mobileFormatted } from '@/utils/helpers';
import { useToggle } from '@/hooks/toggle';
import { useConfirmDialog } from '@/hooks/confirm-dialog';
import ConfirmDialog from '@/components/shared/modals/ConfirmDialog.vue';
import { useGTM } from '@/hooks/gtm';
import swipeModal from '@/utils/swipeModal';
import { useWindowSize } from '@/hooks/window-size';
const StockListSection = defineAsyncComponent(() => import('@/components/specialStock/stockListSection.vue'));
export default {
  components: { StockListSection, ConfirmDialog, swipeModal },
  props: {
    showAccount: {
      required: false,
      type: Boolean,
      default: false
    },
    asideType: {
      type: String,
      validator (value) {
        return ['noaside', 'aside'].includes(value);
      },
      default: 'aside'
    }
  },
  setup () {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isStockModal = ref(false);

    const { windowSize } = useWindowSize();

    const gtm = useGTM();

    const { toggle } = useToggle(false);

    const userInfo = computed(() => store.getters['user/userInfo']);

    const specialOffers = computed(() => store.getters['services/specialOffers']);
    const stockPartners = computed(() => store.getters['cms/getPartnerStock']);
    const needShowConvergent = computed(() => store.getters['user/needShowConvergent']);
    const supportsNavList = computed(() => store.getters['cms/getSupportChapter']);
    const displayName = computed(() => userInfo.value.firstName + ' ' + userInfo.value.familyName);

    const jwtData = computed(() => store.getters['auth/jwtData']);

    const accountItems = ref(null);
    const canScrolls = reactive({ canScrollNext: false, canScrollPrev: false });

    watch(accountItems, (newValue) => {
      const elem = newValue;
      updateCanScrolls(elem);
    });

    const scrollNext = () => (accountItems.value.scrollLeft += 200);
    const scrollPrev = () => (accountItems.value.scrollLeft -= 200);

    const onScroll = () => {
      const elem = accountItems.value;
      updateCanScrolls(elem);
    };

    const updateCanScrolls = elem => {
      if (!elem) return;
      const elemWidth = elem.getBoundingClientRect().width;
      const maxScrollWidth = elem.scrollWidth - elemWidth;
      canScrolls.canScrollNext = elem.scrollWidth !== elem.clientWidth && elem.scrollLeft < maxScrollWidth;
      canScrolls.canScrollPrev = elem.scrollWidth !== elem.clientWidth && elem.scrollLeft > 0;
    };

    const accountClicked = () => {
      router.push({ name: 'home' });

      // TO DO: Реализовать переключение ЛС
      // console.log(item);
      toggle.off();
    };

    const convergentClicked = () => {
      router.push({ name: 'convergent' });

      toggle.off();
    };

    const isConvegrentSelected = computed(() => router.currentRoute.value.name === 'convergent');

    const linkIsAvailable = computed(() => !isConvegrentSelected.value);

    const logout = () => {
      store.dispatch('auth/logout');
      window.location.replace(`https://login.mts.ru/amserver/UI/Logout?goto=${process.env.VUE_APP_URI}`);
    };

    const dialog = useConfirmDialog({ sucessCallback: () => logout() });

    watch(windowSize, newValue => {
      if (newValue >= process.env.VUE_APP_XL) {
        isStockModal.value = false;
        document.documentElement.removeAttribute('style');
      }
    });

    return {
      userInfo,
      displayName,
      needShowConvergent,
      mobileFormatted,
      toggle,
      jwtData,
      accountItems,
      logout,
      scrollNext,
      scrollPrev,
      canScrolls,
      onScroll,
      accountClicked,
      convergentClicked,
      isConvegrentSelected,
      linkIsAvailable,
      ...dialog,
      gtm,
      route,
      isStockModal,
      specialOffers,
      stockPartners,
      supportsNavList
    };
  }
};
</script>

<style scoped>
</style>
