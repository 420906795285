import { computed } from 'vue';
import { useStore } from 'vuex';

export const CMS_CATEGORIES = {
  bonusy_i_predlozheniya: 'bonusy_i_predlozheniya',
  osnovnoy_banner: 'osnovnoy_banner',
  tematicheskiye_bannery: 'tematicheskiye_bannery'
};

export const selectColor = (color) => {
  switch (color) {
    case 'Чёрный': return 'var(--primary-black)';
    case 'Белый': return 'white';
    case 'Красный': return 'var(--primary-light)';
    case 'Зелёный': return 'var(--green)';
    case 'Серый': return 'var(--secondary-grey)';
    default: return 'white';
  }
};

export function isTargetGroup (item, key) {
  const store = useStore();
  const targetGroups = computed(() => store.getters['user/targetGroup']);

  const elAttributes = item.attributeCategories?.[key].attributes;
  return !elAttributes.targetirovannyye_gruppy ||
    targetGroups.value.some(el => el.group === Number(elAttributes.targetirovannyye_gruppy?.value.value));
};
