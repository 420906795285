<template>
  <div class="page mts-d-flex flex-column">
    <div class="page__content flex-fill mts-d-flex-t mts-align-items-center mts-justify-content-center">
      <div class="auth-area">
        <div class="auth-area__header">
          <router-link
            class="mts-logo"
            to="/auth"
          >
            <img
              src="../assets/svg/logo_new-desktop.svg "
              alt="Логотип"
            >
          </router-link>
        </div>
        <router-view />
      </div>
    </div>
    <the-footer :bottom-hidden="true" />
  </div>
</template>

<script>
import TheFooter from '../components/TheFooter.vue';
export default { components: { TheFooter } };
</script>
