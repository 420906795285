import { computed, onMounted, onUnmounted, ref, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useTimer } from './timer';
import { mobileFormatted } from '@/utils/helpers';

export function useConfirmationCode (successCallback, resetCodePayload, attemptsLeftProp) {
  const code = ref('');
  const codeCOM = ref(null);
  const { count: seconds, inProcess, startTimer } = useTimer();
  const attemptsLeft = ref(Number(attemptsLeftProp));
  const isDisabled = ref(false);
  const isInvalidCode = ref(false);
  const INVALID_CODE = 'Истек срок действия кода подтверждения.';
  const agreement = ref(true);
  const touchError = ref(false);
  const isSubmitLoader = ref(false);

  const store = useStore();

  const firstInput = ref(null);
  const resetFields = () => {
    code.value = '';
  };
  const isResendCodeSubmitting = ref(false);
  const resendCode = async () => {
    touchError.value = false;
    if (isResendCodeSubmitting.value) {
      return;
    }
    try {
      isResendCodeSubmitting.value = true;

      resetFields();
      if (incorrectСode.value) {
        incorrectСode.value = false;
      }

      const response = await store.dispatch('settings/sendConfirmCode', resetCodePayload);
      attemptsLeft.value = +response.attemptsLeft;
      startTimer(30);
      isDisabled.value = false;
      isInvalidCode.value = false;
    } catch (e) {

    } finally {
      isResendCodeSubmitting.value = false;
      await nextTick(() => codeCOM.value.toFocusInput());
    }
  };

  const incorrectСode = ref(false);
  const formValid = computed(() => code.value.length === 4 && agreement.value);

  const onSubmit = async () => {
    try {
      isSubmitLoader.value = true;
      const payload = {
        code: code.value,
        contact: resetCodePayload.value,
        contactType: resetCodePayload.type
      };
      await store.dispatch('settings/confirmCode', payload);
      successCallback();
    } catch (e) {
      attemptsLeft.value--;
      resetFields();
      touchError.value = true;
      const isNotValid = e.data.message === INVALID_CODE;
      if (attemptsLeft.value === 0 || isNotValid) {
        isDisabled.value = true;
        isInvalidCode.value = isNotValid;
        return;
      }
      incorrectСode.value = true;
    } finally {
      isSubmitLoader.value = false;
    }
  };

  const ac = new AbortController();
  onMounted(() => {
    codeCOM.value.toFocusInput();

    if ('OTPCredential' in window) {
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal
        })
        .then(async (otp) => {
          code.value = otp.code.toString();
          if (formValid.value) await onSubmit();
          ac.abort();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    startTimer(30);
    isDisabled.value = false;
  });

  onUnmounted(() => ac.abort());
  return {
    onSubmit,
    isResendCodeSubmitting,
    formValid,
    resendCode,
    seconds,
    inProcess,
    startTimer,
    agreement,
    incorrectСode,
    firstInput,
    mobileFormatted,
    attemptsLeft,
    isDisabled,
    isInvalidCode,
    INVALID_CODE,
    code,
    touchError,
    codeCOM,
    isSubmitLoader
  };
}
