<template>
  <teleport to="body">
    <app-modal
      :title="title"
      @close="$emit('cancel')"
    >
      <div class="popup__body">
        <div class="form">
          <div class="form__pre">
            {{ text }}
          </div>
          <div class="form__bottom">
            <button
              class="form__submit button button_primary button_fluid"
              :disabled="okButtonDisabled"
              @click.prevent="onButtonClick"
            >
              {{ okButtonText }}
            </button>
          </div>
        </div>
      </div>
    </app-modal>
  </teleport>
</template>

<script>
import AppModal from '../../ui/AppModal';

export default {
  components: { AppModal },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    okButtonText: {
      type: String,
      default: 'Да'
    },
    okButtonDisabled: {
      type: Boolean
    },
    okAction: {
      type: Function,
      required: false
    }
  },
  emits: ['cancel', 'ok', 'error'],
  setup (props, { emit }) {
    const onButtonClick = async () => {
      try {
        await props.okAction?.();
        emit('ok');
      } catch (ex) {
        emit('error', ex);
      }
    };

    return { onButtonClick };
  }
};

</script>

<style>

</style>
