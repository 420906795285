<template>
  <div
    v-if="totalRows > pageSize"
    class="pagination"
  >
    <div class="pagination__page-box">
      <a
        v-if="modelValue > 1 && viewArrow"
        class="pagination__prev"
        href="#"
        @click.prevent="goPrev"
      />
      <a
        v-for="n in links"
        :key="n"
        :class="['pagination__btn', {'pagination__btn_active': n === modelValue }]"
        href="#"
        @click.prevent="onClick(n)"
      >{{ n }}</a>
      <a
        v-if="modelValue < pageCount && viewArrow"
        class="pagination__next"
        href="#"
        @click.prevent="goNext"
      />
    </div>
    <div
      v-if="viewPageSize"
      class="pagination__limit-box"
    >
      <div
        v-if="pageRanges.length"
        class="pagination__limit-label"
      >
        Выводить по:
      </div>
      <div class="pagination__limit-items">
        <a
          v-for="n in pageRanges"
          :key="n"
          :class="['pagination__btn', {'pagination__btn_active': n === pageSize}]"
          href="#"
          @click.prevent="onClickSize(n)"
        >{{ n }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
  props: {
    modelValue: Number,
    totalRows: {
      required: true,
      type: Number
    },
    pageCount: {
      required: true,
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    },
    clickHandler: {
      required: true,
      type: Function
    },
    clickSizeHandler: {
      required: true,
      type: Function
    },
    viewPageSize: {
      type: Boolean,
      default: true,
      required: false
    },
    viewArrow: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  emits: ['update:modelValue', 'clickPage'],
  setup (props, { emit }) {
    const availableRanges = [10, 20, 30, 50, 100, 500, 1000, 2000, 4000];

    const pageRanges = computed(() => {
      const result = [];
      for (const i in availableRanges) {
        if (props.totalRows >= availableRanges[i] && result.indexOf(availableRanges[i]) === -1) {
          result.push(availableRanges[i]);
        }
      }
      return result;
    });

    const goPrev = () => {
      if (props.modelValue !== 1) {
        props.clickHandler(props.modelValue - 1);
        emit('update:modelValue', props.modelValue - 1);
        emit('clickPage', props.modelValue - 1);
      }
    };

    const goNext = () => {
      if (props.modelValue !== props.pageCount) {
        props.clickHandler(props.modelValue + 1);
        emit('update:modelValue', props.modelValue + 1);
        emit('clickPage', props.modelValue + 1);
      }
    };

    const links = computed(() => {
      const result = [];

      if (props.pageCount <= 5) {
        for (let i = 1; i <= props.pageCount; i++) {
          result.push(i);
        }
        return result;
      }

      if (props.modelValue < 5) {
        for (let i = 1; i < 6; i++) {
          result.push(i);
        }
        result.push('...');
        result.push(props.pageCount);
      } else if (props.modelValue >= props.pageCount - 2) {
        result.push(props.modelValue - 3);
        result.push(props.modelValue - 2);
        result.push(props.modelValue - 1);
        result.push(props.modelValue);

        if (props.modelValue + 1 <= props.pageCount) {
          result.push(props.modelValue + 1);
        }
        if (props.modelValue + 2 <= props.pageCount) {
          result.push(props.modelValue + 2);
        }
      } else if (props.modelValue >= 5) {
        result.push(props.modelValue - 3);
        result.push(props.modelValue - 2);
        result.push(props.modelValue - 1);
        result.push(props.modelValue);
        result.push(props.modelValue + 1);

        result.push('...');
        result.push(props.pageCount);
      }

      return result;
    });

    const onClick = (num) => {
      if (num !== '...') {
        props.clickHandler(num);
        emit('update:modelValue', num);
        emit('clickPage', num);
      }
    };

    const onClickSize = (n) => {
      props.clickSizeHandler(n);
    };

    return {
      links, onClick, onClickSize, goPrev, goNext, pageRanges, availableRanges
    };
  }

};
</script>
