import { reactive, onBeforeMount } from 'vue';

export function useStage (min, max, current) {
  const stage = reactive({
    min: 1,
    max: 1,
    current: 0,

    init: function (min, max, current) {
      this.min = min || 1;
      this.max = max || 1;
      this.current = current || 0;

      return this;
    },

    is: function (stageId) {
      return this.current === stageId;
    },

    next: function () {
      if (this.current + 1 <= this.max) { this.current++; }
      return this;
    },

    prev: function () {
      if (this.current - 1 >= this.min) { this.current--; }
      return this;
    },

    set: function (stageId) {
      if (stageId <= this.max && stageId >= this.min) {
        this.current = stageId;
      }
      return this;
    }
  });

  onBeforeMount(() => {
    stage.init(min, max, current);
  });

  return { stage };
}
