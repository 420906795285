import { ref, computed, watch, reactive } from 'vue';
import yup from '../utils/validation';
import { useField, useForm } from 'vee-validate';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useGTM } from '@/hooks/gtm';
import { TYPE_LOGIN, useTypeLoginPassword } from '@/hooks/useTypeLoginPassword';
import { useStage } from '@/hooks/stage';

export const ERROR_CODE = {
  FORBBIDEN: 403,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  SERVER: 500
};

export function useLoginForm () {
  const store = useStore();
  const router = useRouter();
  const gtm = useGTM();
  const isCheckLogin = ref(false);
  const isViewChipID = ref(false);
  const loginName = 'Лицевой счет';
  const { authSetType } = useTypeLoginPassword();
  const { stage } = useStage(1, 3, 1);
  const availableTypeLogin = [
    {
      id: 1,
      value: TYPE_LOGIN.password,
      displayName: 'Вход по паролю',
      placeholder: 'Введите пароль',
      typeInput: 'password',
      errorValue: 'Необходимо указать пароль'
    },
    {
      id: 2,
      value: TYPE_LOGIN.chipId,
      displayName: 'Вход по номеру приставки',
      placeholder: 'Введите Chip ID',
      typeInput: 'password',
      errorValue: 'Необходимо указать Chip ID'
    }
  ];
  const typeLogin = ref(availableTypeLogin[0]);
  const authTypes = [
    { type: 'personalAccount', name: loginName }
    // { type: 'inn', name: 'ИНН' }, //TO DO: Доработать ЛК, для авторизации по INN (возможность переключаться между несколькими ЛК, отображения для юр.лиц...)
  ];
  const selectedAuthType = ref(loginName);
  const errorForbbiden = reactive({
    title: '',
    description: '',
    temporaryBlocking: false
  });

  const schema = computed(() => {
    return yup.object({
      authType: yup.string().required(),
      login:
        selectedAuthType.value === loginName
          ? yup
            .string()
            .trim()
            .length(11, 'Номер должен содержать 11 символов')
            .matches('^[0-9]*$', 'Допустимые символы: 0-9')
            .required('Необходимо указать номер ЛС')
          : yup
            .string()
            .trim()
            .checkINNLegal()
            .length(10, 'Номер должен содержать 10 символов')
            .matches('^[0-9]*$', 'Допустимые символы: 0-9')
            .required('Необходимо указать ИНН'),
      password: typeLogin.value.id === 1
        ? yup.string()
          .required(typeLogin.value.errorValue)
        : yup.string()
          .required(typeLogin.value.errorValue)
          .matches('^[0-9]*$', 'Chip ID должен состоять из цифр')
          .test('len', 'Длинна от 6 символов', val => val && val.length >= 6)
    });
  });

  const {
    handleSubmit,
    isSubmitting,
    meta: formMeta,
    setFieldError
  } = useForm({
    validationSchema: schema,
    initialValues: {
      authType: loginName
    }
  });

  const { value: authType } = useField('authType');

  const {
    value: login,
    errorMessage: lError,
    handleBlur: lBlur,
    setErrors: setLError,
    meta: lMeta
  } = useField('login');

  const {
    value: password,
    errorMessage: pError,
    handleBlur: pBlur
  } = useField('password');

  const checkLogin = async () => {
    try {
      isCheckLogin.value = true;
      const response = await store.dispatch('auth/checkLogin', { personalAccount: login.value });
      isViewChipID.value = response.data.chipID;
      stage.next();
    } catch (e) {
      if (e.status >= ERROR_CODE.SERVER) {
        setLError('Сервис временно недоступен');
        return;
      }
      setLError(e.data.message);
    } finally {
      isCheckLogin.value = false;
    }
  };

  const onSubmit = handleSubmit(async (fields, actions) => {
    authTypes.forEach((objects) => {
      if (fields.authType === objects.name) {
        fields.authType = objects.type;
      }
    });

    try {
      await store.dispatch('auth/login', { type: fields.authType, login: fields.login, password: fields.password, typeLogin: typeLogin.value.value });
      gtm.push_events({ event: 'vntLogin', eventCategory: 'avtorizaciya', eventAction: 'confirmed', eventLabel: 'uspeshnaya_avtorizaciya', actionGroup: 'conversions' });
      authSetType(typeLogin.value.value, fields.password);
      router.push('/');
    } catch (e) {
      if (e.status === ERROR_CODE.UNAUTHORIZED && e.data.message === 'Unauthorized') {
        actions.setFieldError('password', typeLogin.value.value === TYPE_LOGIN.password ? 'Указан неверный пароль' : 'Войти по Chip Id невозможно.');
        return;
      }
      if (e.status === ERROR_CODE.FORBBIDEN) {
        setErrorForbbiden(
          e.data.message,
          e.data.details.description.join('<br><br>') ?? 'Доступ ограничен',
          e.data.details.temporaryBlocking[0] === 'true'
        );
        return;
      }
      if (e.status >= ERROR_CODE.SERVER) {
        actions.setFieldError('password', 'Сервис временно недоступен');
        return;
      }
      actions.setFieldError('password', e.data.message);
    }
  });

  const setErrorForbbiden = (title, description, temporaryBlocking) => {
    errorForbbiden.title = title;
    errorForbbiden.description = description;
    errorForbbiden.temporaryBlocking = temporaryBlocking;
    stage.set(3);
  };

  const onAuthTypeItemClick = (e) => {
    authType.value = e.target.innerText;
  };

  watch(authType, (value) => selectedAuthType.value = value);

  return {
    loginName,
    authTypes,
    selectedAuthType,
    authType,
    login,
    password,
    lError,
    lBlur,
    lMeta,
    pError,
    pBlur,
    isSubmitting,
    onSubmit,
    formMeta,
    setFieldError,
    onAuthTypeItemClick,
    typeLogin,
    availableTypeLogin,
    checkLogin,
    stage,
    isCheckLogin,
    isViewChipID,
    errorForbbiden
  };
}
