<template>
  <div
    class="banner"
    :class="[{'cursor-p': !attributes.tekst_knopki?.value && attributes.ssylka?.value}]"
    @click="windowSize <= 1280 || !attributes.tekst_knopki?.value ? clickToBannerLink() : ()=>{}"
  >
    <picture
      class="banner__img"
      :style="{'opacity': `0.${styleBanner.opacity}`}"
    >
      <source
        class="banner__img-body"
        :srcset="attributes.fonovoye_izobrazheniye_dlya_telefona_168_kh_100_rkh.value"
        media="(max-width: 1279px)"
      >
      <img
        class="banner__img-body"
        :src="attributes.fonovoye_izobrazheniye_dlya_dekstopa_316_kh_300_rkh.value"
        alt="banner"
        loading="lazy"
        decoding="async"
      >
    </picture>
    <div
      v-if="styleBanner.bgBlack"
      class="banner__bg"
    />
    <div class="banner__content">
      <div
        class="banner__text"
        :style="{'color': styleBanner.color}"
      >
        <div
          v-if="attributes.zagolovok?.value"
          class="banner__text-header"
        >
          {{ attributes.zagolovok.value }}
        </div>
        <div
          v-if="attributes.soderzhaniye?.value"
          class="banner__para"
        >
          {{ attributes.soderzhaniye.value }}
        </div>
      </div>
      <a
        v-if="attributes.ssylka?.value && attributes.tekst_knopki?.value"
        href
        class="banner__btn button button_secondary-bg-gray button_large"
        @click.prevent="clickToBannerLink"
      >
        {{ attributes.tekst_knopki.value }}
      </a>
    </div>
  </div>
</template>

<script setup>

import { useWindowSize } from '@/hooks/window-size';
import { onMounted, reactive, watch } from 'vue';
import { selectColor } from '@/utils/cmsHelpers';
import { useGTM } from '@/hooks/gtm';

const props = defineProps({
  banner: {
    type: Object
  }
});

const { windowSize } = useWindowSize();
const attributes = props.banner?.attributeCategories.tematicheskiye_bannery.attributes;
const gtm = useGTM();
const styleBanner = reactive({
  color: '',
  opacity: '',
  bgBlack: false
});

const clickToBannerLink = () => {
  if (!attributes.ssylka?.value) return;
  gtm.push_events({ event: 'vntMain', eventCategory: 'main', eventAction: 'button_click', eventLabel: 'podkluchit', eventContent: gtm.translit_ru_en(attributes.nazvaniye_produkta.value) });
  window.open(attributes.ssylka.value, '_blank');
};

const setStyle = (size) => {
  if (size <= 1280) {
    styleBanner.color = selectColor(attributes.tsvet_teksta_dlya_telefona?.value.value);
    styleBanner.opacity = attributes.prozrachnost_planshet_i_telefon?.value === '10' ? undefined : attributes.prozrachnost_planshet_i_telefon?.value;
    styleBanner.bgBlack = attributes.temnaya_podlozhka?.value === 'true';
    return;
  }

  styleBanner.color = selectColor(attributes.tsvet_teksta?.value.value);
  styleBanner.opacity = attributes.prozrachnost?.value === '10' ? undefined : attributes.prozrachnost?.value;
  styleBanner.bgBlack = attributes.temnaya_podlozhka_desktop?.value === 'true';
};

watch(windowSize, newValue => {
  setStyle(newValue);
});

onMounted(() => setStyle(windowSize.value));
</script>

<style scoped>

.banner__img-body {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

</style>
