<template>
  <div class="code__section">
    <div class="code__wrapper">
      <input
        ref="inputEl"
        v-maska
        class="code__input"
        type="text"
        autocomplete="one-time-code"
        name="token"
        inputmode="numeric"
        :value="modelValue"
        data-maska="####"
        :disabled="isDisabled"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="focusInput"
        @focusout="focusOutInput"
      >
      <div class="code__view-wrapper">
        <div
          v-for="i in 4"
          :key="i"
          class="code__view-item"
          :class="[
            {'code__view-item-focus': isFocus && i - 1 === elFocus},
            {'code__view-item-error': isError}
          ]"
        >
          {{ modelValue[i - 1] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { computed, ref } from 'vue';
import { vMaska } from 'maska';

const props = defineProps({
  modelValue: {
    type: String
  },
  isError: {
    type: Boolean
  },
  isDisabled: {
    type: Boolean
  }
});
const isFocus = ref(false);
const inputEl = ref(null);
const elFocus = computed(() => props.modelValue.length ? props.modelValue.length : 0);

const focusInput = () => {
  isFocus.value = true;
};

const focusOutInput = () => {
  isFocus.value = false;
};

const toFocusInput = () => {
  if (inputEl.value) {
    inputEl.value.focus();
    inputEl.value.click();
  }
};

defineExpose({
  toFocusInput
});
</script>

<style scoped>

.code__section {
  display: inline-block;
  max-width: 200px;
  position: relative;
  min-height: 2.25rem;
  margin: 0;
  width: 100%;
}
.code__wrapper {
  position: relative;
}

.code__input {
  opacity: 0;
  left: 0;
  top: 0;
  position: absolute;
  padding: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
}
.code__view-wrapper {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  border: none;
  position: relative;
  z-index: 1;
  top: 0;
  font-size: 20px;
}
.code__view-item {
  width: 2.25rem;
  height: 2.25rem;
  text-align: center;
  position: relative;
  border-bottom:2px solid var(--secondary-grey-light);
}
.code__view-item-focus {
  border-bottom-color: var(--link-light);
}
.code__view-item-focus::after {
  content: '';
  width: 1px;
  position: absolute;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  animation: cursorCode .7s infinite ease;
}
.code__view-item-error {
  border-bottom-color: var(--primary-light-negative);
}
.code__input:disabled + .code__view-wrapper .code__view-item {
  border-bottom:2px solid var(--secondary-grey-light);
}
@keyframes cursorCode {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
