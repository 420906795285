<template>
  <div class="popup__body">
    <form
      v-if="stage.is(1)"
      class="form"
      @submit.prevent="onSubmit1"
    >
      <div class="form__pre">
        Укажите почту, на которую будут приходить уведомления
      </div>
      <AppInput
        id="email"
        v-model="email"
        v-focus
        placeholder="example@domain.ru"
        type="email"
        maxlength="30"
        reset
        :Error="incorrectEmail ? incorrectEmailValue:''"
      >
        Почта
      </AppInput>

      <div class="form__bottom">
        <div class="form__btn-stack">
          <div class="form__btn-stack-item">
            <button
              class="form__submit button button_fluid button_small-m button_primary button_noscale"
              :disabled="!emailValid || isSubmitting"
            >
              Получить код
            </button>
          </div>
          <div class="form__btn-stack-item">
            <button
              class="form__submit button button_fluid button_small-m button_secondary button_noscale"
              @click.prevent="$emit('cancel')"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </form>
    <confirmation-code
      v-if="stage.is(2)"
      :attempts-left-prop="attemptsLeft"
      :prop-contact="email"
      :prop-type="'email'"
      @submit="$emit('success')"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useStage } from '../../../hooks/stage';
import ConfirmationCode from '../../shared/ConfirmationCode.vue';
import { checkEmail } from '../../../utils/validation.js';
import AppInput from '@/components/ui/AppInput';

export default {
  components: { AppInput, ConfirmationCode },
  props: {
    emailDefault: {
      type: String,
      required: false
    }
  },
  emits: ['cancel', 'success'],
  setup (props) {
    const store = useStore();
    const { stage } = useStage(1, 2, 1);

    const email = ref(props.emailDefault);

    const emailValid = computed(() => {
      return email.value && !incorrectEmail.value && checkEmail(email.value);
    });
    const incorrectEmail = ref(false);
    const attemptsLeft = ref(null);
    const incorrectEmailValue = ref('');

    watch(email, () => {
      if (incorrectEmail.value) {
        incorrectEmail.value = false;
      }
    });

    const isSubmitting = ref(false);

    const onSubmit1 = async () => {
      incorrectEmailValue.value = '';
      isSubmitting.value = true;
      try {
        const response = await store.dispatch('settings/sendConfirmCode', { type: 'email', value: email.value });
        attemptsLeft.value = response.attemptsLeft;
        stage.next(2);
      } catch (e) {
        incorrectEmailValue.value = e.data.message;
        incorrectEmail.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    return { stage, onSubmit1, emailValid, isSubmitting, email, incorrectEmail, attemptsLeft, incorrectEmailValue };
  }
};
</script>

<style>

</style>
