<template>
  <div class="popup__body">
    <template v-if="impossible">
      <div class="para popup__para">
        К сожалению, в вашем договоре отсутствуют контактные данные, на которые мы могли бы отправить код. Для восстановления доступа обратитесь в контактный центр 8 800 250 2320
      </div>
      <a
        class="mts-d-none-t button button_primary button_small"
        href="tel:88002502320"
      >Позвонить в контактный центр</a>
    </template>

    <form
      v-else-if="stage.is(1)"
      @submit.prevent="onSubmit1"
    >
      <div class="auth-form__pre">
        <p class="para">
          Вы получите код в SMS на телефон или в письме на электронную почту, которые указаны в вашем профиле
        </p>
      </div>
      <div class="auth-form__input-field">
        <div class="input-field">
          <label
            class="input-field__label"
            for="input-field-method"
          >Способ восстановления</label>
          <div
            class="input-field__dropdown-box"
            @click="toggle.switch"
          >
            <div class="input-field__dropdown-value-container">
              <input
                id="input-field-method"
                v-model="recoveryType"
                class="input-field__dropdown-value"
                type="text"
                readonly
              >
            </div>
            <div :class="['input-field__dropdown-list-container', {'input-field__dropdown-list-container_visible': toggle.is(true)}]">
              <div class="input-field__dropdown-list">
                <div
                  v-for="t in recoveryTypes"
                  :key="t.type"
                  :class="['input-field__dropdown-list-item', {'input-field__dropdown-list-item_selected': t.name === recoveryType}]"
                  @click="onRecoveryTypeItemClick"
                >
                  {{ t.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="auth-form__input-field">
        <div :class="['input-field', {'input-field_invalid': lError || isTimeNotEnd.flag}]">
          <label
            class="input-field__label"
            for="input-field-login"
          >Лицевой счет</label>
          <div class="input-field__control-box">
            <div
              v-if="login"
              class="input-field__control-reset-btn"
              @click="login=underfined"
            />
            <input
              id="input-field-login"
              v-model="login"
              v-focus
              class="input-field__control"
              type="text"
              placeholder="Лицевой счет"
              maxlength="11"
            >
          </div>
          <div
            v-if="lError || isTimeNotEnd.flag"
            class="input-field__description"
          >
            {{ lError || isTimeNotEnd.value }}
          </div>
        </div>
      </div>
      <div class="auth-form__bottom">
        <div class="auth-form__btn-stack">
          <div class="auth-form__btn-stack-item">
            <button
              class="auth-form__submit button button_small-m button_primary button_noscale"
              :disabled="!lMeta.valid || isSubmitting"
            >
              Получить код
            </button>
          </div>
          <div class="auth-form__btn-stack-item">
            <button
              class="auth-form__submit button button_small-m button_secondary button_noscale"
              @click.prevent="$emit('cancel'); gtm.push_events({'event': 'vntVoss', 'eventCategory': 'vosstanovlenie_dostupa', 'eventAction': 'button_click', 'eventLabel': 'otmena', 'buttonLocation': 'popup'})"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </form>

    <form
      v-else-if="stage.is(2)"
      @submit.prevent="onSubmit2"
    >
      <div class="auth-form__pre">
        <p
          v-if="recoveryType===smsName"
          class="para"
        >
          На ваш контактный телефон {{ emailOrMobile }} выслан код для восстановления пароля
        </p>
        <p
          v-else
          class="para"
        >
          На вашу контактную почту {{ emailOrMobile }} выслан код для восстановления доступа
        </p>
      </div>

      <div class="form__input-field">
        <div :class="['digital__input', {'digital-input_invalid':incorrectСode, 'input-field_invalid': incorrectСode}]">
          <AppConfirmationCode
            ref="codeCOM"
            v-model="code"
            :is-disabled="isDisabled"
            :is-error="touchError"
            @input="touchError = false"
          />
          <div
            v-if="incorrectСode"
            class="input-field__description"
          >
            <p>Неверный код</p>
            <p>{{ attemptsLeft !==0 ? `Осталось попыток: ${attemptsLeft}`: 'Отправьте код повторно' }}</p>
          </div>
          <div
            v-else-if="isInvalidCode"
            class="input-field__description"
          >
            <p>{{ INVALID_CODE }} Отправьте код повторно</p>
          </div>
          <div class="input-field__description-extra">
            Код действителен в течение 5 минут с момента получения
          </div>
        </div>
      </div>
      <div class="form__input-group-mid">
        <p
          v-if="inProcess"
          class="para para_gray"
        >
          Отправить код через {{ seconds }} сек.
        </p>
        <p
          v-else
          class="para para_gray"
        >
          <a
            :class="['link', {'link_not-clickable': isSubmitting}]"
            href="#"
            @click.prevent="resendCode"
          >Отправить код повторно</a>
        </p>
      </div>
      <div class="form__input-field checkbox__wrapper">
        <AppCheckbox
          id="agreement"
          v-model="agreement"
        />
        <p class="para">
          Я ознакомлен и согласен с <a
            class="link"
            href="/statements/terms_of_services_042014.pdf"
            target="_blank"
          >условиями пользования услугой</a>
        </p>
      </div>
      <div class="form__bottom">
        <div class="form__btn-stack">
          <div class="form__btn-stack-item">
            <AppButton
              fluid
              :disabled="!form2Valid"
              :loading="isSubmitLoader"
              @click="onSubmit2"
            >
              Войти
            </AppButton>
          </div>
          <div class="form__btn-stack-item">
            <AppButton
              fluid
              color="button_secondary"
              @click.prevent="$emit('cancel'); gtm.push_events({'event': 'vntVoss', 'eventCategory': 'vosstanovlenie_dostupa', 'eventAction': 'button_click', 'eventLabel': 'otmena', 'buttonLocation': 'popup'})"
            >
              отмена
            </AppButton>
          </div>
        </div>
      </div>
    </form>
    <div
      v-if="stage.is(3)"
      class="para popup__para"
    >
      Пароль успешно отправлен на контактный адрес
    </div>
  </div>
</template>

<script>
import { usePasswordRecovery } from '../../../hooks/password-recovery';
import { useToggle } from '../../../hooks/toggle';
import { useGTM } from '@/hooks/gtm';
import AppButton from '@/components/ui/AppButton';
import AppCheckbox from '@/components/ui/AppCheckbox';
import AppConfirmationCode from '@/components/ui/AppConfirmationCode.vue';

export default {
  components: { AppConfirmationCode, AppCheckbox, AppButton },
  props: ['propLogin'],
  emits: ['cancel'],
  setup (props) {
    const { toggle } = useToggle(false);
    const gtm = useGTM();

    return { ...usePasswordRecovery(props.propLogin), toggle, gtm };
  }
};
</script>

<style scoped>
.checkbox__wrapper {
  display: flex;
  gap: 8px;
}
.digital__input {
  font-size: 0;
}
</style>
