<template>
  <app-page v-if="$router.currentRoute.value.name === 'tariffs'">
    <div class="page__content page__content_regular page__content_noaside">
      <div class="page__nav">
        <div
          class="page__nav-btn-back"
          @click.prevent="$router.push({name: 'home'})"
        />
      </div>
      <div
        v-if="!loading"
        class="page__content-inner"
      >
        <div
          v-if="!tariffs || !tariffs.data.length"
          class="page__content-inner"
        >
          <div class="page__stub">
            <div class="page__stub-header">
              Нет доступных для смены тарифов
            </div>
            <div class="page__stub-body">
              <p class="page__stub-para">
                Для вашего тарифного плана не предусмотрен самостоятельный переход на другой тарифный план.
              </p>
              <p class="page__stub-para">
                Изменить тарифный план вы можете при обращении в Поддержку по номеру 88002502320 или Магазин МТС
              </p>
              <div class="form page__stub-form">
                <a
                  class="button button_secondary"
                  href="tel:88002502320"
                >Позвонить в поддержку</a>
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <h1 class="page__nav-title page__nav-title_margin">
            Выберите подходящий тариф
          </h1>
          <div class="card-set tariff-card-set">
            <tariff-card
              v-for="t in tariffs.data"
              :key="t"
              :tariff="t"
            />
          </div>
        </template>
      </div>
    </div>
  </app-page>
  <router-view />
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import AppPage from '@/components/ui/AppPage.vue';
import TariffCard from '@/components/tariffs/TariffCard.vue';

export default {
  components: { AppPage, TariffCard },
  inheritAttrs: false,
  props: {
    resId: {
      required: true
    }
  },
  setup (props) {
    const store = useStore();

    const tariffs = computed(() => store.getters['tariffs/availableTariffs']);
    const loading = ref(false);

    onMounted(async () => {
      try {
        loading.value = true;
        if (!tariffs.value || tariffs.value.resId !== props.resId) {
          const payload = { resId: props.resId };
          await store.dispatch('tariffs/loadAvailable', payload);
        }
      } catch (e) {
      } finally {
        loading.value = false;
      }
    });

    return { tariffs, loading };
  }
};
</script>
