<template>
  <div class="card-set__card card-set__card_resource">
    <div class="card-set__card-flex-top">
      <header class="card-set__card-header card-set__card-header_variant">
        {{ pack.tariff.name }}
      </header>
      <div class="card-set__card-status-box">
        <div
          v-if="pack.type === PACK"
          class="card-set__card-status card-set__card-status_positive"
        >
          {{ typesMap.pack }}
        </div>
        <div
          v-else-if="pack.type === TEST_DRIVE"
          class="card-set__card-status card-set__card-status_info"
        >
          {{ typesMap.testDrive }}
        </div>
      </div>
      <div class="card-set__card-description card-set__card-description_variant">
        {{ packInfoChannel?.opisaniye?.value ? descriptionDisplay(packInfoChannel?.opisaniye?.value) : descriptionDisplay(pack.tariff.description1) }}
      </div>
      <div class="logos__list">
        <div
          v-for="logo in logos"
          :key="logo.value"
        >
          <AppTooltipNew
            :max-width="155"
            tooltip-position="top"
          >
            <template #tooltip>
              <img
                class="logos__list-item"
                :src="logo.value?.split(DELIMITER)[1]"
                loading="lazy"
                :alt="logo.value?.split(DELIMITER)[0]"
              >
            </template>
            <template #default>
              {{ logo.value?.split(DELIMITER)[0] }}
            </template>
          </AppTooltipNew>
        </div>
      </div>
    </div>
    <div class="card-set__card-flex-bottom">
      <div class="card-set__card-cost card-set__card-cost_compact">
        <div
          v-if="pack.type === TEST_DRIVE"
          class="service-rate"
        >
          <div class="service-rate__value-box">
            <div class="service-rate__value">
              Бесплатно
            </div>
          </div>
          <div class="service-rate__description">
            Действует до {{ dateFormatter(pack.tariff.testDrive.dateTo, 5) }}
          </div>
        </div>
        <div
          v-else
          class="service-rate"
        >
          <div class="service-rate__value-box">
            <div class="service-rate__value">
              {{ pack.tariff.discount.price }} ₽/{{ tariffPeriodFormatter(pack.tariff.period, pack.tariff.periodUnit) }}
            </div>
            <div
              v-if="pack.tariff.discount.price < pack.tariff.price"
              class="service-rate__value-old"
            >
              {{ pack.tariff.price }} ₽
            </div>
          </div>
          <div class="service-rate__description">
            {{ pack.tariff.description2 }}
          </div>
        </div>
      </div>
      <div class="form card-set__card-form-button-box card-set__card-form-button-box_variant">
        <div class="form__stack">
          <div class="form__stack-item">
            <div
              v-if="testDriveCanBeConnected"
              class="button button_secondary"
              @click.prevent="addTestDrive"
            >
              Тест-драйв
            </div>
          </div>
          <div
            v-if="needIconShow(pack.tariff.description1)"
            class="form__stack-item"
          >
            <div class="form__el-grid-2-fluid-min-content">
              <button
                v-if="pack.type === AVAILABLE_PACK"
                class="form__submit button button_primary"
                :disabled="dispatching"
                @click.prevent="addPackShow"
              >
                Подключить
              </button>
              <button
                v-else-if="pack.type === PACK"
                class="button button_secondary button_negative"
                :disabled="dispatching"
                @click.prevent="deletePackShow"
              >
                Отключить
              </button>
              <button
                v-else-if="pack.type === TEST_DRIVE"
                class="button button_secondary button_negative"
                :disabled="dispatching"
                @click.prevent="deleteTestDriveShow"
              >
                Завершить тест-драйв
              </button>
              <button
                class="button button_icon-info button_icon"
                @click.prevent="iconShow"
              />
            </div>
          </div>
          <div
            v-else
            class="form__stack-item"
          >
            <button
              v-if="pack.type === AVAILABLE_PACK"
              class="form__submit button_fluid button button_primary"
              :disabled="dispatching"
              @click.prevent="addPackShow"
            >
              Подключить
            </button>
            <button
              v-else-if="pack.type === PACK"
              class="button button_secondary button_fluid button_negative"
              :disabled="dispatching"
              @click.prevent="deletePackShow"
            >
              Отключить
            </button>
            <button
              v-else-if="pack.type === TEST_DRIVE"
              class="button button_secondary button_fluid button_negative"
              :disabled="dispatching"
              @click.prevent="deleteTestDriveShow"
            >
              Завершить тест-драйв
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pack-card-modal
    v-if="needShowModal"
    :pack="pack"
    :type="pack.type"
    :action-type="actionType"
    :toast="toast"
    :card="card"
    :pack-changed-callback="changedCallback"
    @hidden="needShowModal = false"
    @started="dispatching = true"
    @error="dispatching = false"
  />
  <app-toast
    v-if="showToast"
    :text="toastText"
    :type="toastType"
  />
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import { tariffPeriodFormatter, dateFormatter, shortTextWithEllipsis } from '@/utils/helpers';
import PackCardModal from './PackCardModal.vue';
import { useToast } from '@/hooks/toast';
import AppToast from '@/components/ui/AppToast.vue';
import { useGTM } from '@/hooks/gtm';

import { useStore } from 'vuex';
const AppTooltipNew = defineAsyncComponent(() => import('@/components/ui/AppTooltipNew.vue'));

export default {
  components: { AppTooltipNew, PackCardModal, AppToast },
  props: {
    pack: {
      type: Object,
      require: true
    },
    card: {
      type: Object,
      required: true
    },
    packChangedCallback: {
      type: Function
    }
  },
  setup (props) {
    const gtm = useGTM();
    const changedCallback = async () => {
      await props.packChangedCallback();
      dispatching.value = false;
    };

    const PACK = 'pack';
    const DELIMITER = ' | ';
    const AVAILABLE_PACK = 'availablePack';
    const TEST_DRIVE = 'testDrive';

    const MAX_CHARACTERS = 150;
    const store = useStore();
    const packInfoChannel = store.getters['cms/getLogosChannels'](props.pack.tariff.id);
    const logos = computed(() => packInfoChannel?.kanaly?.value.filter(item => ~item.value?.indexOf(DELIMITER)));

    const typesMap = {
      testDrive: 'Тест-драйв',
      pack: 'Подключено',
      availablePack: ''
    };

    const toast = useToast();

    const descriptionDisplay = (text) => {
      return shortTextWithEllipsis(text, MAX_CHARACTERS);
    };

    const needIconShow = (text) => text.length > MAX_CHARACTERS;
    const actionType = ref();

    const iconShow = () => {
      actionType.value = 'iconShow';
      needShowModal.value = true;
    };

    const packType = new Map([
      [16710, 'amedia_premium_hd'],
      [16711, 'match_premier'],
      [19018, 'match_futbol'],
      [19017, 'nastroi_kino'],
      [16712, 'posle_polunochi'],
      [22926, 'ocean_otkrytii']
    ]);
    const addPackShow = () => {
      gtm.push_events({ event: 'vntStv', eventCategory: 'uslugi', eventAction: 'button_click', eventLabel: 'podkluchit', buttonLocation: 'screen', eventContent: packType.get(props.pack.tariff.id), eventContext: packType.get(props.pack.tariff.id) });
      actionType.value = 'addPackShow';
      needShowModal.value = true;
    };

    const deletePackShow = () => {
      actionType.value = 'deletePackShow';
      needShowModal.value = true;
    };

    const addTestDrive = () => {
      actionType.value = 'addTestDrive';
      needShowModal.value = true;
    };

    const deleteTestDriveShow = () => {
      actionType.value = 'deleteTestDriveShow';
      needShowModal.value = true;
    };

    const testDriveCanBeConnected = computed(() => props.pack.type === AVAILABLE_PACK && props.pack.tariff.testDrive.actionType === 'Подключить');

    const needShowModal = ref(false);
    const dispatching = ref(false);

    return {
      typesMap,
      PACK,
      AVAILABLE_PACK,
      TEST_DRIVE,
      testDriveCanBeConnected,
      tariffPeriodFormatter,
      dateFormatter,
      descriptionDisplay,
      needIconShow,
      iconShow,
      addPackShow,
      deletePackShow,
      addTestDrive,
      deleteTestDriveShow,
      needShowModal,
      actionType,
      ...toast,
      toast,
      dispatching,
      changedCallback,
      packInfoChannel,
      logos,
      DELIMITER
    };
  }

};
</script>

<style>

.logos__list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.logos__list-item {
  width: 44px;
  height: 44px;
  padding: 4px;
  border-radius: 12px;
  background-color: var(--secondary-grey-ultra-light);
}

</style>
