<template>
  <app-page>
    <template #menu>
      <div class="page__aside">
        <div class="page__aside-nav page__aside-nav-scroll scrollbar__mts">
          <router-link
            v-for="item in supportsNavList"
            :key="item.id"
            :to="`/support/${item.code}`"
            class="page__aside-nav-link text-ellipsis"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </template>

    <div
      v-if="$router.currentRoute.value.name === 'support'"
      class="page__content page__content_regular"
    >
      <div class="page__nav" />
      <div class="blocks page__content-blocks">
        <div class="blocks__item">
          <h1 class="page__nav-title page__nav-title_margin">
            Поддержка
          </h1>
          <div class="nav-page-menu">
            <router-link
              v-for="item in supportsNavList"
              :key="item.id"
              :to="`/support/${item.code}`"
              class="nav-page-menu__link nav-page-menu_link-not-img"
            >
              <span class="text-ellipsis">
                {{ item.name }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <router-view />
  </app-page>
</template>

<script setup>
import { computed, onMounted, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import AppPage from '../../components/ui/AppPage.vue';
import { useWindowSize } from '@/hooks/window-size';
import { useStore } from 'vuex';

const store = useStore();
const { windowSize } = useWindowSize();
const router = useRouter();

const supportsNavList = computed(() => store.getters['cms/getSupportChapter']);
const needRedirectToFirstChapter = () => {
  return router.currentRoute.value.name === 'support' && windowSize.value >= 1280;
};

const goToFirstChapter = () => {
  router.push(`/support/${supportsNavList.value[0].code}`);
};

onMounted(async () => {
  try {
    if (!supportsNavList.value.length) await router.push({ name: 'home' });

    if (needRedirectToFirstChapter()) {
      goToFirstChapter();
    }
  } catch (e) {

  }
});

onUpdated(() => {
  if (needRedirectToFirstChapter()) {
    goToFirstChapter();
  }
});

</script>

<style>

</style>
