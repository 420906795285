<template>
  <label class="checkbox__label-wrapper">
    <input
      v-model="model"
      type="checkbox"
      class="input"
      :value="value"
      :disabled="disabled"
    >
    <div class="checkbox__icon-wrapper">
      <svg
        class="checkbox__icon"
        width="24"
        height="24"
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :class="{'checkbox__icon-fill':model, 'checkbox-disabled':disabled}"
          d="M3.08 9.92c.2-2.61.3-3.92 1.6-5.23C6 3.37 7.32 3.28 9.93 3.09a28.1 28.1 0 0 1 4.16 0c2.61.19 3.92.28 5.23 1.6 1.32 1.31 1.41 2.62 1.6 5.23a28.14 28.14 0 0 1 0 4.16c-.19 2.61-.28 3.92-1.6 5.24-1.3 1.3-2.62 1.4-5.23 1.6a28.14 28.14 0 0 1-4.16 0c-2.61-.2-3.92-.3-5.23-1.6-1.32-1.32-1.41-2.63-1.6-5.24a28.11 28.11 0 0 1 0-4.16Z"
        />
        <path
          :class="{'checkbox__icon-border':model}"
          stroke="rgba(188, 195, 208, 0.5)"
          stroke-width="1.5"
          d="M3.83 9.98c.2-2.67.3-3.68 1.39-4.76 1.08-1.09 2.1-1.2 4.76-1.39a27.36 27.36 0 0 1 4.04 0c2.67.2 3.68.3 4.76 1.39 1.09 1.08 1.2 2.1 1.39 4.76a27.32 27.32 0 0 1 0 4.04c-.2 2.67-.3 3.68-1.39 4.77-1.08 1.08-2.1 1.18-4.76 1.38a27.32 27.32 0 0 1-4.04 0c-2.67-.2-3.68-.3-4.76-1.38-1.09-1.09-1.2-2.1-1.4-4.77a27.35 27.35 0 0 1 0-4.04Z"
        />
        <path
          :class="{'checkbox__icon-check': model && !disabled}"
          fill="transparent"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.2 10.2a1 1 0 0 0-1.4-1.4L11 12.58l-1.3-1.3a1 1 0 0 0-1.4 1.42l2 2a1 1 0 0 0 1.4 0l4.5-4.5Z"
        />
        <path
          v-if="disabled"
          fill="#fff"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 11a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H9Z"
        />
      </svg>
    </div>
    <span
      v-if="label"
      :class="[labelSize==='s'?'checkbox__label-s':'checkbox__label-l']"
    >
      {{ label }}
    </span>
  </label>
</template>

<script setup>

import { computed } from 'vue';

const props = defineProps({
  modelValue: { type: [Array, Boolean] },
  value: { type: [Boolean, Object] },
  label: { type: String },
  disabled: { type: Boolean, default: false },
  labelSize: { type: String, default: 's' }
});
const emit = defineEmits(['update:modelValue']);
const model = computed({
  get () {
    return props.modelValue;
  },
  set (value) {
    emit('update:modelValue', value);
  }
});

</script>

<style scoped>

.checkbox__label-wrapper {
  position: relative;
  z-index: 0;
  display: inline-flex;

  --size:1.5rem;
}

.checkbox__label-wrapper:hover .checkbox__icon-wrapper{
  transform: scale(1.1);
}

.checkbox__icon {
  transition: fill .2s ease;
}

.checkbox__label-wrapper:hover .checkbox__icon {
  fill: rgba(188, 195, 208, 0.25);
}

.checkbox__label-wrapper:active .checkbox__icon-wrapper{
  transform: scale(1);
}

.checkbox:focus ~ .checkbox__icon-wrapper{
  outline: var(--primary-light,#ff0032);
}

.input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
}

.checkbox__icon-wrapper {
  width: var(--size);
  height: var(--size);
  transform: scale(1);
  transition: transform .2s ease;
}

.checkbox__icon {
  display: block;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.checkbox__icon-fill {
  fill: var(--primary-light,#ff0032);;
}
.checkbox__icon-border {
  display: none;
}
.checkbox__icon-check {
  display: block;
  fill: #fff;
}

.checkbox__label-s {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var( --primary-black,#1D2023);
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  margin-left: 8px;
}

.checkbox__label-l {
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
  font-weight: 500;
  margin-left: 12px;
}

.checkbox-disabled {
  fill: var(--secondary-grey-light, #969FA8);
}

</style>
