<template>
  <div class="card-set__card">
    <p
      class="card-set__card-header"
      :style="{'margin-bottom':`${props.titleBottom}px`}"
    >
      {{ props.title }}
    </p>
    <slot />
  </div>
</template>

<script setup>

const props = defineProps({
  title: String,
  titleBottom: {
    type: Number,
    default: 16,
    required: false
  }
});

</script>

<style scoped>

</style>
