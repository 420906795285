import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useWindowSize } from '@/hooks/window-size';

export const useThemedBannersSwiper = (items, timerProps) => {
  const currentIndex = ref(0);
  const { windowSize } = useWindowSize();
  const timerSeconds = computed(() => +items[currentIndex.value]?.attributeCategories.tematicheskiye_bannery.attributes.taymer?.value.value || timerProps);
  let timer;
  let length = items.length;

  const nextSlide = () => {
    currentIndex.value = (currentIndex.value + 1) % length;
    resetTimer();
  };

  const startTimer = () => {
    timer = setInterval(() => {
      nextSlide();
    }, timerSeconds.value * 1000);
  };

  const resetTimer = () => {
    clearInterval(timer);
    timer = null;
    if (windowSize.value >= 1280) {
      startTimer();
    }
  };

  const setLengths = (size) => {
    length = size;
  };

  watch(windowSize, newSize => {
    if (length <= 1) return;
    if (newSize < 1280) {
      clearInterval(timer);
      timer = null;
    } else if (newSize >= 1280 && !timer) {
      startTimer();
    }
  });

  onMounted(() => {
    if (windowSize.value >= 1280 && length > 1) {
      startTimer();
    }
  });
  onUnmounted(() => {
    clearInterval(timer);
  });

  return {
    currentIndex,
    nextSlide,
    timerSeconds,
    setLengths
  };
};
