<template>
  <div class="select__month">
    <button
      v-for="item in listMonths"
      :key="item"
      :class="['month',
               {'active':isActive(item)},
               {'month__range': isRange(item)},
               {'start__select':selectDate[0] && selectDate[1] && selectDate[0].getMonth()===getMonthSmallNum(item) && selectDate[0].getFullYear()===year}]"
      :disabled="isDisabled(item)"
      @click.prevent="$emit('setMonth', item)"
    >
      <span>
        {{ item }}
      </span>
    </button>
  </div>
</template>

<script setup>
import { isRangeDates } from '@/components/ui/AppDatePickerMobile/helpers/isRangeDates';
import { getMonthSmall, getMonthSmallNum } from '@/utils/getMounth';

const props = defineProps({
  selectDate: [Array, Date],
  year: Number,
  min: Date,
  max: Date
});

const listMonths = [
  'Янв',
  'Фев',
  'Март',
  'Апр',
  'Май',
  'Июнь',
  'Июль',
  'Авг',
  'Сен',
  'Окт',
  'Нояб',
  'Дек'
];

const isActive = (month) => {
  if (!props.selectDate.length) return false;
  if (props.selectDate.length === 1) return getMonthSmall(props.selectDate[0].getMonth()) === month && props.selectDate[0].getFullYear() === props.year;
  if (!props.selectDate[1]) return (getMonthSmall(props.selectDate[0].getMonth()) === month && props.selectDate[0].getFullYear() === props.year);
  return (getMonthSmall(props.selectDate[0].getMonth()) === month && props.selectDate[0].getFullYear() === props.year) ||
      (getMonthSmall(props.selectDate[1].getMonth()) === month && props.selectDate[1].getFullYear() === props.year);
};

const isRange = (month) => {
  if (props.selectDate.length < 2) return false;
  return isRangeDates(props.selectDate[0], props.selectDate[1], new Date(props.year, getMonthSmallNum(month), 0));
};

const isDisabled = (month) => {
  let min, max;
  const now = new Date(props.year, getMonthSmallNum(month), 0);
  if (!props.min && !props.max) return ((new Date()).getMonth() < getMonthSmallNum(month) && (new Date()).getFullYear() === props.year);

  if (props.min && !props.max) {
    min = new Date(props.min.getFullYear(), props.min.getMonth(), 0);
    return ((new Date()).getMonth() < getMonthSmallNum(month) && (new Date()).getFullYear() === props.year) || (min > now);
  }
  if (!props.min && props.max) {
    max = new Date(props.max.getFullYear(), props.max.getMonth(), 0);
    return ((new Date()).getMonth() < getMonthSmallNum(month) && (new Date()).getFullYear() === props.year) || (max < now);
  }

  min = new Date(props.min.getFullYear(), props.min.getMonth(), 0);
  max = new Date(props.max.getFullYear(), props.max.getMonth(), 0);
  return ((new Date()).getMonth() < getMonthSmallNum(month) && (new Date()).getFullYear() === props.year) || (min > now) || (max < now);
};

</script>

<style scoped>

.select__month {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.month {
  width: 33%;
  height: 32px;
  flex: 1 1 auto;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  position: relative;
  transition: background-color .2s ease;
}

.month:hover {
  background-color: rgba(188, 195, 208, 0.25);
  border-radius: 6px;
}

.month:disabled {
  cursor: not-allowed;
}

.month:hover:disabled {
  cursor: not-allowed;
  background-color: transparent;
}

.month:not(:nth-last-child(-n+3)){
  margin-bottom: 8px;
}

.active span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #1D2023;
  border-radius: 6px;
  width: 72px;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.active:hover {
  background-color: transparent;
}

.month__range {
  background-color: rgba(188, 195, 208, 0.25);
}

.month__range:hover {
  border-radius: 0;
}

.active.month__range{
  background-color: transparent;
  position: relative;
}
.active.month__range:after {
  content:'';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background-color: rgba(188, 195, 208, 0.25);
  z-index: -1;
}

.start__select:after {
  content:'';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  background-color: rgba(188, 195, 208, 0.25);
  z-index: -1;
}
</style>
