<template>
  <div :class="['banners', {'banners_inline': inline}]">
    <template v-if="!inline">
      <SwiperThemedBaner
        v-for="(item, index) in banners"
        :key="item.id"
        :current-index="currentIndex"
        :index="index"
        :timer-seconds="timerSeconds"
        :size="banners.length"
      >
        <ThemedBanner :banner="item" />
      </SwiperThemedBaner>
    </template>
    <template v-else>
      <ThemedBanner
        v-for="item in banners"
        :key="item.id"
        :banner="item"
      />
    </template>
    <div
      class="banner"
      @click.prevent="bannerClicked(()=> $emit('replace', false))"
    >
      <picture class="banner__img">
        <source
          srcset="../assets/content/banner3-mob.jpg"
          media="(max-width: 1279px)"
        >
        <img
          src="../assets/content/banner3.jpg"
          alt="Оборудование"
        >
      </picture>
      <div class="banner__content">
        <div class="banner__text">
          <div class="banner__text-header">
            Дополнительное оборудование
          </div>
          <div class="banner__para banner__para-mobile">
            Если требуется замена или подключение «Мультирум»
          </div>
        </div>
        <button
          class="banner__btn button button_secondary-bg-gray button_large"
          @click="gtm.push_events({ event: 'vntStv', eventCategory: 'glavnaya', eventAction: 'banner_click', eventLabel: 'zakazat', buttonLocation: 'screen' }); $emit('replace', false)"
        >
          Отправить заявку
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useGTM } from '@/hooks/gtm';
import ThemedBanner from '@/components/baner/ThemedBanners/ThemedBanner.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import SwiperThemedBaner from '@/components/baner/ThemedBanners/SwiperThemedBaner.vue';
import { useThemedBannersSwiper } from '@/components/baner/ThemedBanners/useThemedBannersSwiper';
import { CMS_CATEGORIES, isTargetGroup } from '@/utils/cmsHelpers';

export default {
  components: { SwiperThemedBaner, ThemedBanner },
  props: {
    inline: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const gtm = useGTM();

    const store = useStore();
    const banners = computed(() => store.getters['cms/getThemedBanners'].filter(item => isTargetGroup(item, CMS_CATEGORIES.tematicheskiye_bannery)));

    const swiper = useThemedBannersSwiper(banners.value, 10);
    const bannerClicked = (cb) => {
      if (props.inline) {
        gtm.push_events({ event: 'vntStv', eventCategory: 'glavnaya', eventAction: 'banner_click', eventLabel: 'zakazat', buttonLocation: 'screen' });
        cb();
      }
    };

    return {
      bannerClicked,
      gtm,
      banners,
      ...swiper
    };
  }
};
</script>

<style scoped>
@media (max-width: 1279px) {
  .banner__para-mobile {
    display: none;
  }
}
</style>
