<template>
  <app-page>
    <template #menu>
      <div class="page__aside">
        <div class="page__aside-nav">
          <a
            :class="['page__aside-nav-link_fin-all', 'page__aside-nav-link', { 'page__aside-nav-link_active': activePage.type === ALL_TYPE.type }]"
            href="#"
            @click.prevent="activePage=ALL_TYPE; gtm.push_events({'event': 'vntFin', 'eventCategory': 'finansy', 'eventAction': 'element_click', 'eventLabel': 'vse_operacii'})"
          >Все операции</a>
          <a
            :class="['page__aside-nav-link_fin-minus', 'page__aside-nav-link', { 'page__aside-nav-link_active': activePage.type === COSTS_TYPE.type }]"
            href="#"
            @click.prevent="activePage=COSTS_TYPE; gtm.push_events({'event': 'vntFin', 'eventCategory': 'finansy', 'eventAction': 'element_click', 'eventLabel': 'spisaniya'})"
          >Списания</a>
          <a
            :class="['page__aside-nav-link_fin-plus', 'page__aside-nav-link', { 'page__aside-nav-link_active': activePage.type === PAYMENTS_TYPE.type }]"
            href="#"
            @click.prevent="activePage=PAYMENTS_TYPE; gtm.push_events({'event': 'vntFin', 'eventCategory': 'finansy', 'eventAction': 'element_click', 'eventLabel': 'popolneniya'})"
          >Пополнения</a>
        </div>
      </div>
    </template>
    <div class="page__content_regular page__finances">
      <div class="blocks page__content-blocks">
        <div class="blocks__item">
          <h1 class="page__nav-title page__nav-title_margin page__finances-title">
            Финансы
          </h1>
          <div class="page__finances-tabs">
            <div
              ref="typeButtons"
              class="tabs tabs_button tabs_hidden-ds"
            >
              <button
                :class="['tabs__item', 'button', 'button_tab', 'button_secondary', 'button_small', { 'button_secondary-active': activePage.type === ALL_TYPE.type }]"
                @click.prevent="allClicked(); gtm.push_events({'event': 'vntFin', 'eventCategory': 'finansy', 'eventAction': 'element_click', 'eventLabel': 'vse_operacii'})"
              >
                Все операции
              </button>
              <button
                :class="['tabs__item', 'button', 'button_tab', 'button_secondary', 'button_small', { 'button_secondary-active': activePage.type === COSTS_TYPE.type}]"
                @click.prevent="activePage=COSTS_TYPE; gtm.push_events({'event': 'vntFin', 'eventCategory': 'finansy', 'eventAction': 'element_click', 'eventLabel': 'spisaniya'})"
              >
                Списания
              </button>
              <button
                :class="['tabs__item', 'button', 'button_tab', 'button_secondary', 'button_small', { 'button_secondary-active': activePage.type === PAYMENTS_TYPE.type }]"
                @click.prevent="replenishmentClicked(); gtm.push_events({'event': 'vntFin', 'eventCategory': 'finansy', 'eventAction': 'element_click', 'eventLabel': 'popolneniya'})"
              >
                Пополнения
              </button>
            </div>
            <AppCheckbox
              v-if="activePage.type !== PAYMENTS_TYPE.type"
              v-model="isFreeOperations"
              class="finance__mobile-check"
              label="Показать бесплатные"
            />
          </div>
          <div class="page__finances-log fin-log">
            <form
              class="form finances-log__form log__form"
              @submit.prevent="onSubmit"
            >
              <div class="input-field">
                <label
                  class="input-field__label"
                  for="finance__range"
                >Укажите период</label>
                <div
                  v-if="!isCalendarMobile"
                  class="date__wrapper-finance"
                >
                  <span class="calendar__finance-text desk">Все операции за&nbsp;</span>
                  <span class="calendar__finance-text mob">За&nbsp;</span>
                  <VueDatePicker
                    id="finance__range"
                    ref="datepicker"
                    v-model="date"
                    locale="ru-RU"
                    range
                    multi-calendars
                    auto-apply
                    :close-on-auto-apply="false"
                    :enable-time-picker="false"
                    placeholder="Укажите период"
                    :max-date="new Date()"
                    prevent-min-max-navigation
                    hide-offset-dates
                    :clearable="false"
                    :hide-navigation="['month', 'year']"
                    @update:model-value="handleDate"
                  >
                    <template #trigger>
                      <span class="view__date-time calendar__finance-text">{{ FormatInputDate(date) }}
                        <svg
                          width="16"
                          height="8"
                          viewBox="0 0 16 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.01246 0C0.112259 4.76837e-07 -0.338563 1.07659 0.297975 1.70623C0.297975 1.70623 0.297975 1.70623 0.297975 1.70623L3.4273 4.80165C5.58289 6.93388 6.66069 8 8.00001 8C9.33932 8 10.4171 6.93388 12.5727 4.80164L15.702 1.70623C16.3386 1.07659 15.8877 0 14.9875 0C14.7196 0 14.4625 0.105304 14.2731 0.292744L11.1437 3.38815C10.0255 4.49423 9.32762 5.17859 8.75443 5.61118C8.23709 6.00162 8.05648 6.00117 8.00276 6.00103L8.00001 6.00103L7.99725 6.00103C7.94353 6.00117 7.76292 6.00162 7.24558 5.61118C6.67239 5.1786 5.97446 4.49423 4.85627 3.38816L1.72695 0.292743C1.72695 0.292743 1.72695 0.292743 1.72695 0.292743C1.53745 0.105303 1.28044 4.76837e-07 1.01246 0Z"
                            fill="#007CFF"
                          />
                        </svg>
                      </span>
                    </template>
                    <template #month="{ value }">
                      {{ getMonth(value) }}
                    </template>
                    <template #month-overlay-value="{ value }">
                      {{ getMonthSmall(value) }}
                    </template>
                    <template #arrow-left>
                      <svg
                        class="slot-icon"
                        width="8px"
                        height="16px"
                        viewBox="0 0 8 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        fit=""
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                      >
                        <path
                          d="M8 1.01246C8 0.11226 6.92341 -0.338563 6.29377 0.297975L3.19835 3.4273C1.06612 5.58289 -9.10439e-07 6.66069 0 8C-4.27446e-08 9.33932 1.06612 10.4171 3.19836 12.5727L6.29377 15.702C6.92341 16.3386 8 15.8877 8 14.9875C8 14.7196 7.8947 14.4625 7.70726 14.2731L4.61185 11.1437C3.50577 10.0255 2.82141 9.32762 2.38882 8.75443C1.99838 8.23709 1.99883 8.05648 1.99897 8.00276L1.99897 8L1.99897 7.99725C1.99883 7.94353 1.99838 7.76292 2.38882 7.24558C2.8214 6.67239 3.50577 5.97446 4.61184 4.85627L7.70726 1.72695C7.8947 1.53745 8 1.28044 8 1.01246Z"
                          fill="black"
                        />
                      </svg>
                    </template>
                    <template #arrow-right>
                      <svg
                        style="transform: rotateZ(180deg)"
                        class="slot-icon"
                        width="8px"
                        height="16px"
                        viewBox="0 0 8 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        fit=""
                        preserveAspectRatio="xMidYMid meet"
                        focusable="false"
                      >
                        <path
                          d="M8 1.01246C8 0.11226 6.92341 -0.338563 6.29377 0.297975L3.19835 3.4273C1.06612 5.58289 -9.10439e-07 6.66069 0 8C-4.27446e-08 9.33932 1.06612 10.4171 3.19836 12.5727L6.29377 15.702C6.92341 16.3386 8 15.8877 8 14.9875C8 14.7196 7.8947 14.4625 7.70726 14.2731L4.61185 11.1437C3.50577 10.0255 2.82141 9.32762 2.38882 8.75443C1.99838 8.23709 1.99883 8.05648 1.99897 8.00276L1.99897 8L1.99897 7.99725C1.99883 7.94353 1.99838 7.76292 2.38882 7.24558C2.8214 6.67239 3.50577 5.97446 4.61184 4.85627L7.70726 1.72695C7.8947 1.53745 8 1.28044 8 1.01246Z"
                          fill="black"
                        />
                      </svg>
                    </template>
                  </VueDatePicker>
                </div>
                <AppDatePicker
                  v-else
                  v-model="date"
                  range
                >
                  <div class="finance__mobile-text">
                    <span class="calendar__finance-text mob">За&nbsp;</span>
                    <span class="view__date-time calendar__finance-text">{{ FormatInputDate(date) }}
                      <svg
                        width="16"
                        height="8"
                        viewBox="0 0 16 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.01246 0C0.112259 4.76837e-07 -0.338563 1.07659 0.297975 1.70623C0.297975 1.70623 0.297975 1.70623 0.297975 1.70623L3.4273 4.80165C5.58289 6.93388 6.66069 8 8.00001 8C9.33932 8 10.4171 6.93388 12.5727 4.80164L15.702 1.70623C16.3386 1.07659 15.8877 0 14.9875 0C14.7196 0 14.4625 0.105304 14.2731 0.292744L11.1437 3.38815C10.0255 4.49423 9.32762 5.17859 8.75443 5.61118C8.23709 6.00162 8.05648 6.00117 8.00276 6.00103L8.00001 6.00103L7.99725 6.00103C7.94353 6.00117 7.76292 6.00162 7.24558 5.61118C6.67239 5.1786 5.97446 4.49423 4.85627 3.38816L1.72695 0.292743C1.72695 0.292743 1.72695 0.292743 1.72695 0.292743C1.53745 0.105303 1.28044 4.76837e-07 1.01246 0Z"
                          fill="#007CFF"
                        />
                      </svg>
                    </span>
                  </div>
                </AppDatePicker>
              </div>
              <AppCheckbox
                v-if="activePage.type !== PAYMENTS_TYPE.type"
                v-model="isFreeOperations"
                class="finance__desktop-check"
                label="Показать бесплатные"
              />
            </form>
            <table
              v-if="!loading && operations?.length"
              class="fin-log__items log__items"
            >
              <thead class="fin-log__items-header log__items-header">
                <tr class="log__header-row">
                  <td class="log__header-col">
                    Дата
                  </td>
                  <td class="log__header-col">
                    Услуги
                  </td>
                  <td class="log__header-col">
                    Категория
                  </td>
                  <td class="log__header-col">
                    Сумма
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="operation in items"
                  :key="operation"
                  :class="['fin-log__item', 'log__item', {'fin-log__item_same-date': operation.sameDate}]"
                >
                  <td :class="['log__item-col', 'fin-log__date', {'fin-log__date_same-date': operation.sameDate}]">
                    <div class="fin-log__date-d">
                      {{ dateFormatter(operation.date, 1) }}
                    </div>
                    <div class="fin-log__date-m">
                      {{ dateFormatter(operation.date, 5) }}
                    </div>
                  </td>
                  <td class="log__item-col fin-log__service fin-log__service_package">
                    {{ operation.name }}
                  </td>
                  <td class="log__item-col fin-log__category">
                    {{ operation.category }}
                  </td>
                  <td :class="['log__item-col', 'fin-log__sum', `${ getDisplayColorClass(operation) }`]">
                    {{ getDisplayAmount(operation) }} ₽
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-show="!loading && operations?.length"
              class="fin-log__pagination log__pagination"
            >
              <app-paginate
                v-model="page"
                :total-rows="totalRows"
                :page-count="pageCount"
                :page-size="pageSize"
                :click-handler="pageChangeHandler"
                :click-size-handler="pageSizeChangeHandler"
              />
            </div>
          </div>

          <div
            v-show="!loading &&!operations?.length"
            class="fin-log__no-items"
          >
            <div class="fin-log__no-items-status">
              Нет финансовых операций за выбранный период
            </div>
            <div class="fin-log__no-items-desc">
              Попробуйте изменить параметры поиска
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-page>
</template>

<script>
import { ref, watch, onMounted, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import AppPage from '@/components/ui/AppPage.vue';
import AppPaginate from '@/components/ui/AppPaginate.vue';
import { getNewDate, dateFormatter } from '@/utils/helpers';
import { usePaginate } from '@/hooks/paginate.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import { FormatInputDate } from '@/utils/FormatInputDate';
import { getMonth, getMonthSmall } from '@/utils/getMounth';
import AppDatePicker from '@/components/ui/AppDatePickerMobile/AppDatePicker';
import AppCheckbox from '@/components/ui/AppCheckbox';
import { useGTM } from '@/hooks/gtm';

export default {
  components: { AppCheckbox, AppDatePicker, AppPage, AppPaginate, VueDatePicker },
  setup () {
    const isFreeOperations = ref(false);
    const store = useStore();
    const paginate = usePaginate(paginateRefreshCallback);
    const gtm = useGTM();
    const loading = ref(true);

    const isCalendarMobile = ref(window.innerWidth <= 600);
    const operationData = ref();

    const getDate = async () => {
      const payload = { start: dateFormatter(date.value[0], 4), end: dateFormatter(date.value[1], 4), type: activePage.value.type };
      operationData.value = await store.dispatch('finance/loadFinance', payload);
    };

    const onSubmit = async () => {
      try {
        loading.value = true;
        await getDate();
        paginate.reset(operations.value);
      } catch (e) {
      } finally {
        loading.value = false;
      }
    };

    // Отсортированные строки от большего к меньшему без учета времени
    const operations = computed(() => {
      return operationData.value?.rows
        .filter(item => {
          if (isFreeOperations.value && activePage.value.type !== PAYMENTS_TYPE.type) return true;
          return item.amount !== 0;
        })
        .sort((a, b) => {
          if (a.date < b.date) {
            return 1;
          }
          if (a.date > b.date) {
            return -1;
          }
          return 0;
        })
        .map(item => {
          item.date = dateFormatter(item.date, 4);
          return item;
        });
    });

    watch(operations, newValue => {
      paginate.reset(newValue);
    });

    const resize = () => {
      isCalendarMobile.value = window.innerWidth <= 600;
    };

    onMounted(() => {
      window.addEventListener('resize', resize);
      onSubmit();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', resize);
    });

    const ALL_TYPE = { name: 'all', type: 3 };
    const PAYMENTS_TYPE = { name: 'payments', type: 2 };
    const COSTS_TYPE = { name: 'costs', type: 1 };

    const activePage = ref(ALL_TYPE);

    watch(activePage, _ => {
      onSubmit();
    });

    const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const endDate = new Date();
    const date = ref([startDate, endDate]);
    const datepicker = ref(null);

    const handleDate = () => { // изменения при десктопной версии
      datepicker.value.closeMenu();
      onSubmit();
    };

    watch(date, _ => { // следим за изменениями при мобильной версии
      if (isCalendarMobile.value) onSubmit();
    });

    const getDisplayAmount = (operation) => {
      let result = operation.amount;
      if (operation.amount === 0) {
        return result;
      }
      if (operation.type === 2) {
        result = operation.amount > 0 ? '+' + operation.amount : operation.amount;
      } else if (operation.type === 1) {
        result = operation.amount > 0 ? '-' + operation.amount : '+' + -operation.amount;
      }
      return result;
    };

    const getDisplayColorClass = (operation) => {
      let resultClassName = '';
      const amount = getDisplayAmount(operation);
      if (amount > 0) { resultClassName = 'fin-log__sum_positive'; } else if (amount < 0) {
        resultClassName = 'fin-log__sum_negative';
      }
      return resultClassName;
    };

    const formValid = computed(() => {
      return !!date.value;
    });

    function paginateRefreshCallback (items) {
      items.map((item, index, array) => {
        item.sameDate = index > 0 && item.date === array[index - 1].date;
        return item;
      });
    }

    const typeButtons = ref();

    const replenishmentClicked = () => {
      activePage.value = PAYMENTS_TYPE;
      const elem = typeButtons.value;
      const elemWidth = elem.getBoundingClientRect().width;
      const maxScrollWidth = elem.scrollWidth - elemWidth;
      elem.scrollLeft = maxScrollWidth;
    };

    const allClicked = () => {
      activePage.value = ALL_TYPE;
      const elem = typeButtons.value;
      elem.scrollLeft = 0;
    };
    return { isFreeOperations, date, datepicker, handleDate, ALL_TYPE, isCalendarMobile, COSTS_TYPE, PAYMENTS_TYPE, activePage, operationData, formValid, loading, onSubmit, dateFormatter, getNewDate, getDisplayAmount, getDisplayColorClass, operations, ...paginate, replenishmentClicked, allClicked, typeButtons, gtm };
  },
  methods: {
    FormatInputDate,
    getMonth,
    getMonthSmall
  }
};
</script>

<style scoped>
.page__finances-title {
  display: block;
}
.finance__desktop-check{
  margin-top: 14px;
}
@media (max-width: 1279px) {
  .finance__desktop-check{
    display: none;
  }
}
</style>
