<template>
  <div class="settings__document">
    <div class="settings__document-wrapper-title">
      <div class="document__name">
        <p class="document__name-text">
          {{ document.docName }}
        </p>
        <AppTooltipNew
          :position="windowSize>=768?'left':'right'"
          :top="windowSize>=768?'bottom':'top'"
          :max-width="260"
        >
          <template #tooltip>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.68597 1.68597C0.373095 2.99884 0.275748 4.30684 0.0810531 6.92282C0.0305833 7.60095 0 8.30048 0 9C0 9.69952 0.0305834 10.399 0.0810532 11.0772C0.275748 13.6932 0.373095 15.0012 1.68597 16.314C2.99884 17.6269 4.30684 17.7243 6.92282 17.9189C7.60095 17.9694 8.30048 18 9 18C9.69952 18 10.399 17.9694 11.0772 17.9189C13.6932 17.7243 15.0012 17.6269 16.314 16.314C17.6269 15.0012 17.7243 13.6932 17.9189 11.0772C17.9694 10.399 18 9.69952 18 9C18 8.30048 17.9694 7.60095 17.9189 6.92282C17.7243 4.30684 17.6269 2.99884 16.314 1.68597C15.0012 0.373095 13.6932 0.275748 11.0772 0.0810531C10.399 0.0305833 9.69952 0 9 0C8.30048 0 7.60095 0.0305834 6.92282 0.0810532C4.30684 0.275748 2.99884 0.373095 1.68597 1.68597ZM10.0001 4.99887C10.0001 5.68859 9.44092 6.24773 8.75119 6.24773C8.06146 6.24773 7.50233 5.68859 7.50233 4.99887C7.50233 4.30914 8.06146 3.75 8.75119 3.75C9.44092 3.75 10.0001 4.30914 10.0001 4.99887ZM9.70711 8.29289C9.89464 8.48043 10 8.73479 10 9L9.99999 13C9.99999 13.5523 9.55227 14 8.99998 14C8.4477 14 7.99999 13.5523 7.99999 13L8 10H7.5C6.94772 10 6.5 9.55228 6.5 9C6.5 8.44772 6.94772 8 7.5 8H9C9.26522 8 9.51957 8.10536 9.70711 8.29289Z"
                fill="#FAC031"
              />
            </svg>
          </template>
          <template #default>
            {{ document.docName }} не подписан
          </template>
        </AppTooltipNew>
      </div>
      <div class="view__block">
        <a
          v-if="!isLoading"
          href=""
          class="link"
          @click.prevent="viewDocument"
        >Ознакомиться с документом</a>
        <AppLoader
          v-else
          color="var(--link-light,#007CFF)"
        />
      </div>
    </div>
    <AppButton
      class="settings__document-button"
      @click="submit"
    >
      Подписать
    </AppButton>
  </div>
</template>

<script setup>

import AppButton from '@/components/ui/AppButton';
import AppTooltipNew from '@/components/ui/AppTooltipNew';
import { useWindowSize } from '@/hooks/window-size';
import { ref } from 'vue';
import { useStore } from 'vuex';
import AppLoader from '@/components/ui/AppLoader';

const props = defineProps({
  document: Object
});
const emit = defineEmits(['setDocument']);
const { windowSize } = useWindowSize();
const store = useStore();
const isLoading = ref(false);

const submit = () => {
  emit('setDocument', props.document);
};

const viewDocument = async () => {
  try {
    isLoading.value = true;
    const data = await store.dispatch('settings/viewDocument', props.document);
    window.open(encodeURI(URL.createObjectURL(data)));
  } catch (e) {

  } finally {
    isLoading.value = false;
  }
};

</script>

<style scoped>

.document__name {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.settings__document:not(:last-child){
  margin-bottom: 32px;
}

.document__name-text {
  margin-right: 9px;
}

.view__block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 767px) {
  .settings__document {
    flex-direction: column;
    align-items: flex-start;
  }
  .settings__document-button {
    width: 100%;
    margin-top: 16px;
  }
  .settings__document-wrapper-title {
    width: 100%;
  }
  .document__name {
    justify-content: space-between;
  }
}

</style>
