import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    setPackagesData (state, data) {
      state.packagesData = data;
    },
    setSpecialOffers (state, data) {
      state.specialOffers = data;
    },
    setDataForRequest (state, data) {
      state.dataForRequest = data;
    },
    updateSetSpecialOffers (state, specialOfferId) {
      const offer = state.specialOffers.find(offer => offer.id === specialOfferId);
      offer.posted = true;
    },
    clear (state) {
      state.packagesData = null;
      state.specialOffers = null;
      state.dataForRequest = null;
    }
  },
  actions: {
    async load ({ commit }) {
      try {
        const { data } = await axios.get('/tv/packages');
        commit('setPackagesData', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async packDelete (_, payload) {
      try {
        await axios.delete('/tv/package', { params: { resId: payload.resId } });
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async packAdd ({ _ }, payload) {
      try {
        await axios.post('/tv/package', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async testDriveAdd ({ _ }, payload) {
      try {
        await axios.post('/tv/package/test_drive', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async testDriveDelete ({ _ }, payload) {
      try {
        await axios.delete('/tv/package/test_drive', { params: { id: payload.testDriveId } });
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async specialOffers ({ commit }) {
      try {
        const { data } = await axios.get('/specialoffer');
        commit('setSpecialOffers', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async dataForRequest ({ commit }) {
      try {
        const { data } = await axios.get('/specialoffer/request');
        commit('setDataForRequest', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async specialOfferSend ({ commit }, payload) {
      try {
        await axios.post('/specialoffer/request', payload);
        commit('updateSetSpecialOffers', payload.specialOfferId);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    packagesData (state) {
      return state.packagesData;
    },
    specialOffers (state) {
      return state.specialOffers;
    },
    dataForRequest (state) {
      return state.dataForRequest;
    }
  }
};
