import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    setResourcesInfo (state, resources) {
      state.resources = resources;
    },
    setActivatedStatus (state, resId) {
      for (let i = 0; i < state.resources.length; i++) {
        const stb = state.resources[i].children;
        for (let j = 0; j < stb.length; j++) {
          if (stb[j].resId === resId) {
            stb[j].isActiveTimer = true;
            return;
          }
        }
      }
    },
    clear (state) {
      state.resources = null;
    }
  },
  actions: {
    async load ({ commit }) {
      try {
        const { data } = await axios.get('/account/stv_resources');
        commit('setResourcesInfo', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async activated ({ commit }, body) {
      try {
        await axios.post('/account/stv_res_reactivate', body);
        commit('setActivatedStatus', body.resId);
        window.localStorage.setItem(body.resId, JSON.stringify(new Date()));
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async changeEquipment (_, eq) {
      try {
        await axios.post('/account/stv_change_equipment', eq);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async orderEquipment (_, eq) {
      try {
        await axios.post('/account/stv_order_equipment', eq);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    resources (state) {
      return state.resources;
    }
  }
};
