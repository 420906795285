export const scroll = (timing) => {
  const start = document.querySelector('.select__day_start');
  const end = document.querySelector('.select__day-end');
  if (!start && !end) {
    document.querySelector('.calendar__nav')?.scrollIntoView({
      behavior: 'auto',
      block: 'center'
    });
  }
  if (!start) {
    end?.scrollIntoView({
      behavior: timing,
      block: 'center'
    });
  } else {
    start?.scrollIntoView({
      behavior: timing,
      block: 'center'
    });
  }
};
