<template>
  <div
    id="change__password"
    class="blocks__item overflow scrollbar__mts"
  >
    <h1 class="page__nav-title page__nav-title_margin">
      {{ title }}
    </h1>

    <form
      v-if="userInfo"
      class="form"
      @submit.prevent="onSubmit"
    >
      <div class="tertiary-banner">
        {{ description }}
      </div>
      <div class="form__pre">
        <p class="para">
          Номер лицевого счета
        </p>
        <p class="para">
          <em class="form__em">{{ userInfo.personalAccount }}</em>
        </p>
      </div>
      <div :class="['form__input-field', 'form__input-field_fixed']">
        <AppInput
          id="input-field-password-old"
          v-model="mainPassword"
          v-focus
          placeholder="Текущий пароль"
          type="password"
          maxlength="30"
        />
      </div>

      <div :class="['form__input-field', 'form__input-field_fixed']">
        <AppInput
          id="input-field-password-new1"
          v-model="passwordNew"
          placeholder="Новый пароль"
          type="password"
          maxlength="30"
        />
      </div>
      <div :class="['form__input-field', 'form__input-field_fixed']">
        <AppInput
          id="input-field-password-new2"
          v-model="passwordNewCopy"
          placeholder="Повторите новый пароль"
          type="password"
          maxlength="30"
        />
      </div>
      <ul class="validate__rules">
        <li
          v-for="rule in validateList"
          :key="rule.rule"
          :class="['validate__rule-default', {'validate__rule-success': rule.value}]"
        >
          {{ rule.rule }}
          <AppTooltipNew
            v-if="rule.tooltip"
            tooltip-position="top"
            :event-stop="{
              element: '#change__password',
              event: 'scroll'
            }"
          >
            {{ rule.tooltip }}
          </AppTooltipNew>
        </li>
      </ul>
      <div :class="['form__bottom', close ? 'button__wrapper':'form__bottom_fixed']">
        <AppButton
          v-if="close"
          fluid
          color="button_secondary"
          @click.prevent="$emit('close')"
        >
          Закрыть
        </AppButton>
        <AppButton
          fluid
          :disabled="!isAllValid"
          :loading="isLoading"
        >
          Изменить пароль
        </AppButton>
      </div>
    </form>

    <app-toast
      v-if="toast.showToast.value"
      :text="toast.toastText.value"
      :type="toast.toastType.value"
    />
  </div>
</template>

<script setup>
import AppTooltipNew from '@/components/ui/AppTooltipNew.vue';
import AppInput from '@/components/ui/AppInput.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { useStore } from 'vuex';
import { computed, defineEmits } from 'vue';
import { useReplacePassword } from '@/hooks/useReplacePassword';
import AppToast from '@/components/ui/AppToast.vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Смена пароля для входа в Личный кабинет'
  },
  description: {
    type: String
  },
  close: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const userInfo = computed(() => store.getters['user/userInfo']);
const emit = defineEmits(['success']);

const { mainPassword, passwordNew, onSubmit, validateList, isAllValid, toast, isLoading, passwordNewCopy } = useReplacePassword(emit);
</script>

<style scoped>

.overflow {
  overflow: hidden;
  overflow-y: auto;
}

.validate__rules {
  margin: 0;
  padding: 0;
}

.validate__rule-default {
  padding-left: 20px;
  background-image: url("../../../assets/svg/line.svg");
  background-repeat: no-repeat;
  background-position: left center;

  display: flex;
  align-items: center;
  gap: 5px;
}

.validate__rule-default:not(:last-child) {
  margin-bottom: 5px;
}

.button__wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.validate__rule-success {
  background-image: url("../../../assets/svg/success.svg");
}

@media (max-width: 576px) {
  .button__wrapper {
    flex-direction: column-reverse;
  }
}

</style>
