import { ref } from 'vue';
import { useGTM } from '@/hooks/gtm';
import { useToast } from '@/hooks/toast';
import { useStore } from 'vuex';
import { useCheckRulesPassword } from '@/hooks/useCheckRulesPassword';
import { useTypeLoginPassword } from '@/hooks/useTypeLoginPassword';
export const useReplacePassword = (emit) => {
  const gtm = useGTM();
  const toast = useToast();
  const store = useStore();
  const mainPassword = ref('');

  const { removeTypeLoginPassword } = useTypeLoginPassword();

  const {
    isAllValid,
    passwordNew,
    passwordNewCopy,
    isLoading,
    validateList
  } = useCheckRulesPassword();

  const onSubmit = async () => {
    gtm.push_events({ event: 'vntSme', eventCategory: 'smena_parolya', eventAction: 'button_click', eventLabel: 'zimenit_parol' });
    try {
      isLoading.value = true;
      await store.dispatch('settings/changePassword', { oldPassword: mainPassword.value, newPassword: passwordNew.value });
      mainPassword.value = passwordNew.value = passwordNewCopy.value = '';
      toast.triggerToast('Пароль изменен', 'ok');
      removeTypeLoginPassword();
      emit('success');
    } catch (e) {
      toast.showError(e);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    mainPassword,
    passwordNew,
    passwordNewCopy,
    onSubmit,
    validateList,
    isAllValid,
    toast,
    isLoading
  };
};
