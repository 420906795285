<template>
  <div class="popup__body">
    <form
      v-if="stage.is(1)"
      class="form"
      @submit.prevent="onSubmit1"
    >
      <div class="form__pre">
        Укажите номер, на который будут приходить уведомления
      </div>
      <div :class="['form__input-field', {'input-field_invalid': incorrectMobile}]">
        <app-input-mobile
          v-model="mobile"
          :need-focus="true"
        />
        <div
          v-if="incorrectMobile"
          class="input-field__description"
        >
          {{ incorrectMobileValue }}
        </div>
      </div>
      <div class="form__bottom">
        <div class="form__btn-stack">
          <div class="form__btn-stack-item">
            <button
              class="form__submit button button_fluid button_small-m button_primary button_noscale"
              :disabled="!mobileValid || isSubmitting"
            >
              Получить код
            </button>
          </div>
          <div class="form__btn-stack-item">
            <button
              class="form__submit button button_fluid button_small-m button_secondary button_noscale"
              @click.prevent="$emit('cancel')"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </form>
    <confirmation-code
      v-if="stage.is(2)"
      :attempts-left-prop="attemptsLeft"
      :prop-contact="mobile"
      :prop-type="'mobile'"
      @submit="$emit('success')"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useStage } from '../../../hooks/stage';
import ConfirmationCode from '../../shared/ConfirmationCode.vue';
import AppInputMobile from '../../ui/AppInputMobile.vue';

export default {
  components: { ConfirmationCode, AppInputMobile },
  props: {
    mobileDefault: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['cancel', 'success'],
  setup (props) {
    const store = useStore();
    const { stage } = useStage(1, 2, 1);

    const mobile = ref(props.mobileDefault);

    const mobileValid = computed(() => {
      return mobile.value && mobile.value.length === 11 && !incorrectMobile.value;
    });
    const incorrectMobile = ref(false);
    const attemptsLeft = ref(null);
    const incorrectMobileValue = ref('');

    watch(mobile, () => {
      if (incorrectMobile.value) {
        incorrectMobile.value = false;
      }
    });

    const isSubmitting = ref(false);

    const onSubmit1 = async () => {
      incorrectMobileValue.value = '';
      isSubmitting.value = true;
      try {
        const response = await store.dispatch('settings/sendConfirmCode', { type: 'mobile', value: mobile.value });
        attemptsLeft.value = response.attemptsLeft;
        stage.next(2);
      } catch (e) {
        incorrectMobileValue.value = e.data.message;
        incorrectMobile.value = true;
      } finally {
        isSubmitting.value = false;
      }
    };

    return { stage, onSubmit1, mobileValid, isSubmitting, mobile, incorrectMobile, attemptsLeft, incorrectMobileValue };
  }
};
</script>

<style>

</style>
