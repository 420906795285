<template>
  <div
    ref="target"
    class="tooltip__wrapper"
    @mouseover="onMouseover"
    @mouseleave="onMouseleave"
    @touchend="onTouchend"
  >
    <slot name="tooltip">
      <span class="hint" />
    </slot>
  </div>
  <teleport to="body">
    <span
      ref="tooltip"
      :class="['tooltip', {'tooltip_visible': toggle.is(true)}, tooltipPosition ?`tooltip_tail-${tooltipPosition}`:`tooltip_tail-${position}-${top}`]"
    >
      <slot>{{ text }}</slot>
    </span>
  </teleport>
</template>

<script>
import { ref, watch } from 'vue';
import { useToggle } from '@/hooks/toggle';
import { useWindowSize } from '@/hooks/window-size';

export default {
  props: {
    text: {
      type: String,
      required: false
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 200
    },
    indentRight: {
      type: Number,
      required: false,
      default: 20
    },
    position: {
      type: String,
      required: false,
      default: 'left' // center, left
    },
    top: {
      type: String,
      require: false,
      default: 'bottom'
    },
    tooltipPosition: {
      type: String,
      require: false,
      default: ''
    },
    scrollLeft: {
      require: false,
      default: 0,
      type: Number
    },
    eventStop: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const { toggle } = useToggle(false);

    const tooltip = ref(null);
    const target = ref();

    const { windowSize } = useWindowSize();

    const onMouseover = (e) => {
      resetPosition(e);
      toggle.on();
      if (props.eventStop) document.querySelector(props.eventStop.element).addEventListener(props.eventStop.event, onMouseleave);
    };

    const onMouseleave = () => {
      toggle.off();
      tooltip.value.style = '';
      if (props.eventStop) document.querySelector(props.eventStop.element).removeEventListener(props.eventStop.event, onMouseleave);
    };

    const onTouchend = (e) => {
      const height = tooltip.value.getBoundingClientRect().height;
      resetPosition(e);
      if (height !== tooltip.value.getBoundingClientRect().height) resetPosition(e);
      toggle.switch();

      if (!props.eventStop) return;

      if (toggle.is(true)) document.querySelector(props.eventStop.element).addEventListener(props.eventStop.event, onMouseleave);
      else document.querySelector(props.eventStop.element).removeEventListener(props.eventStop.event, onMouseleave);
    };

    watch(() => props.scrollLeft, _ => {
      toggle.off();
    });

    watch(windowSize, _ => {
      if (toggle.is(false)) return;
      onMouseleave();
    });

    function resetPosition () {
      const scrollTop = window.pageYOffset;
      tooltip.value.style.maxWidth = props.maxWidth + 'px';
      const targetWidth = target.value.getBoundingClientRect().width;
      const targetHeight = target.value.getBoundingClientRect().height;
      const toolTipWidth = tooltip.value.getBoundingClientRect().width;
      const toolTipHeight = tooltip.value.getBoundingClientRect().height;
      let directionX = 0;
      let directionY = 0;
      switch (props.position) {
        case 'left': directionX = targetWidth / 2 - 18;
          break;
        case 'right': directionX = -toolTipWidth + targetWidth / 2 + 18;
          break;
        default: directionX = 0;
          break;
      }
      switch (props.top) {
        case 'top': directionY = targetHeight + 10;
          break;
        case 'bottom': directionY = -toolTipHeight - 10;
          break;
      }

      if (props.tooltipPosition) {
        switch (props.tooltipPosition) {
          case 'right':
            tooltip.value.style.left = target.value.getBoundingClientRect().left + targetWidth * 3 / 2 + 'px';
            tooltip.value.style.top = target.value.getBoundingClientRect().top + scrollTop + targetWidth / 2 - toolTipHeight / 2 + 'px';
            break;
          case 'top':
            tooltip.value.style.left = target.value.getBoundingClientRect().left - toolTipWidth / 2 + targetWidth / 2 + 'px';
            tooltip.value.style.top = target.value.getBoundingClientRect().top + scrollTop - toolTipHeight - 10 + 'px';
            break;
        }
        return;
      }
      tooltip.value.style.left = target.value.getBoundingClientRect().left + directionX + 'px';
      tooltip.value.style.top = target.value.getBoundingClientRect().top + scrollTop + directionY + 'px';
    }

    return { tooltip, toggle, onMouseover, onMouseleave, onTouchend, target };
  }
};
</script>
<style scoped>
.tooltip__wrapper {
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  cursor: pointer;
}
</style>
