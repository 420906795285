<template>
  <section class="banner-home">
    <div class="banner__wrapper">
      <AppSwiper
        :is-navigate="banners.length > 1"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
        @prevSlide="prevSlide"
        @nextSlide="nextSlide"
      >
        <AppSwiperItem
          v-for="(item, index) in banners"
          :key="item.id"
          :current-index="currentIndex"
          :right="right"
          :left="left"
          :index="index"
          :timer-seconds="timerSeconds"
          :size="banners.length"
        >
          <BannerHome :banner="item" />
        </AppSwiperItem>
      </AppSwiper>
    </div>
    <div
      v-for="(item, index) in banners"
      :key="item.id"
    >
      <Transition>
        <picture
          v-show="index === currentIndex"
          class="banner__bg-picture"
        >
          <source
            :srcset="item?.attributeCategories.osnovnoy_banner.attributes.fonovoye_izobrazheniye_telefon?.value"
            media="(max-width: 767px)"
          >
          <source
            :srcset="item?.attributeCategories.osnovnoy_banner.attributes.fonovoye_izobrazheniye_planshet?.value"
            media="(max-width: 1359px)"
          >
          <img
            :src="item?.attributeCategories.osnovnoy_banner.attributes.fonovoye_izobrazheniye?.value"
            alt="логотип банера"
          >
        </picture>
      </Transition>
    </div>
  </section>
</template>

<script setup>

import { computed } from 'vue';
import BannerHome from '@/components/baner/BannerHome.vue';
import { useSwiper } from '@/hooks/useSwiper';
import AppSwiper from '@/components/ui/AppSwiper/AppSwiper';
import AppSwiperItem from '@/components/ui/AppSwiper/AppSwiperItem';
import { useStore } from 'vuex';
import { CMS_CATEGORIES, isTargetGroup } from '@/utils/cmsHelpers';

const store = useStore();
const banners = computed(() => store.getters['cms/getBanners'].filter(item => isTargetGroup(item, CMS_CATEGORIES.osnovnoy_banner)));

const { currentIndex, left, right, nextSlide, prevSlide, handleTouchEnd, handleTouchStart, timerSeconds } = useSwiper(banners.value, 10);
</script>

<style scoped>

.banner-home {
  color: #FFFFFF;
  text-align: center;
  background-color: var(--secondary-grey-ultra-light);
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner__wrapper {
  max-width: 1360px;
  padding: 0 40px;
  position: relative;
  z-index: 2;
  min-height: 100%;
  width: 100%;
}

.banner__bg-picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.banner__bg-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--white);
}

@media (max-width: 768px) {
  .banner__wrapper {
    padding: 0 20px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
