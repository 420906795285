export const FormatInputDate = (date) => {
  if (!date) return '';
  if (!Array.isArray(date)) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
  const dayFrom = date[0].getDate() < 10 ? `0${date[0].getDate()}` : date[0].getDate();
  const monthFrom = date[0].getMonth() + 1 < 10 ? `0${date[0].getMonth() + 1}` : date[0].getMonth() + 1;
  const yearFrom = date[0].getFullYear();

  const dayTo = date[1].getDate() < 10 ? `0${date[1].getDate()}` : date[1].getDate();
  const monthTo = date[1].getMonth() + 1 < 10 ? `0${date[1].getMonth() + 1}` : date[1].getMonth() + 1;
  const yearTo = date[1].getFullYear();

  if (dayFrom === dayTo && monthFrom === monthTo && yearFrom === yearTo) return `${dayFrom}.${monthFrom}.${yearFrom}`;

  return `${dayFrom}.${monthFrom}.${yearFrom} — ${dayTo}.${monthTo}.${yearTo}`;
};
