<template>
  <div class="card-set__card tariff-card-set__card">
    <header class="card-set__card-header card-set__card-header_tariff">
      {{ tariff.name }}
    </header>
    <div class="card-set__card-description card-set__card-description_tariff">
      <span>{{ descriptionDisplay() }}</span>
    </div>
    <div class="service-rate">
      <div class="service-rate__value-box">
        <div class="service-rate__value tariff-card-set__card-value">
          {{ tariff.discount.price }} ₽/{{ tariffPeriodFormatter(tariff.period, tariff.periodUnit) }}
        </div>
        <div
          v-if="tariff.discount.price < tariff.price"
          class="service-rate__value-old tariff-card-set__card-value-old"
        >
          {{ tariff.price }} ₽
        </div>
      </div>
      <div class="service-rate__description tariff-card-set__card-value-desc">
        {{ tariff.description2 }}
      </div>
    </div>
    <div class="form tariff-card-set__card-form-button-box">
      <div class="form__el-grid-2-fluid-min-content">
        <button
          class="form__submit button button_primary"
          @click.prevent="connectClicked(null)"
        >
          Подключить
        </button>
        <button
          v-if="needIconShow"
          class="button button_icon-info button_icon"
          @click.prevent="iconClicked"
        />
      </div>
    </div>
  </div>
  <confirm-dialog-one-button
    v-if="showConfirmModal"
    :title="tariff.name"
    :text="tariff.description1"
    ok-button-text="Подключить"
    :ok-action="connectClicked"
    @ok="onDialogSuccess"
    @cancel="onDialogCancel"
    @error="onDialogError"
  />
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useConfirmDialog } from '@/hooks/confirm-dialog';
import ConfirmDialogOneButton from '@/components/shared/modals/ConfirmDialogOneButton.vue';
import { tariffPeriodFormatter, shortTextWithEllipsis } from '@/utils/helpers';
import { useGTM } from '@/hooks/gtm';

export default {
  components: { ConfirmDialogOneButton },
  props: {
    tariff: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const router = useRouter();
    const dialog = useConfirmDialog();
    const gtm = useGTM();

    const iconClicked = () => {
      gtm.push_events({ event: 'vntTarif', eventCategory: 'tarif', eventAction: 'element_click', eventLabel: 'info', eventContent: gtm.translit_ru_en(props.tariff.name) });
      dialog.showConfirmDialog();
    };

    const connectClicked = (noPopup) => {
      gtm.push_events({ event: 'vntTarif', eventCategory: 'tarif', eventAction: 'button_click', eventLabel: 'podkluchit', eventContent: gtm.translit_ru_en(props.tariff.name), buttonLocation: noPopup === null ? null : 'popup' });
      const params = { tpId: props.tariff.id };
      router.push({ name: 'tariffs_change', params: params });
    };

    const MAX_CHARACTERS = 160;

    const needIconShow = computed(() => props.tariff.description1.length > MAX_CHARACTERS);

    const descriptionDisplay = () => {
      return shortTextWithEllipsis(props.tariff.description1, MAX_CHARACTERS);
    };

    return { connectClicked, iconClicked, ...dialog, tariffPeriodFormatter, needIconShow, descriptionDisplay, gtm };
  }
};
</script>

<style>

</style>
