import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    setInstallment (state, installmentInfo) {
      state.installmentInfo = installmentInfo;
    },
    deleteInstallment (state) {
      state.installmentInfo = '';
    },
    clear (state) {
      state.installmentInfo = null;
    }
  },
  actions: {
    async load ({ commit }) {
      try {
        const { data } = await axios.get('/account/planpay');
        commit('setInstallment', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async payOffEarly () {
      try {
        await axios.delete('/account/planpay');
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    installmentInfo (state) {
      return state.installmentInfo;
    }
  }
};
