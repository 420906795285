<template>
  <div
    v-if="stage.is(1) && !error"
    class="wrapper"
  >
    <h3 class="wrapper-title">
      Для активации оборудования подключите соединительные кабели и включите оборудование
    </h3>
    <div class="buttons__wrapper">
      <AppButton
        color="button_secondary-bg-gray"
        fluid
        @click="$emit('close')"
      >
        Отмена
      </AppButton>
      <AppButton
        fluid
        :loading="loading"
        @click="activatedEquipment"
      >
        Активировать
      </AppButton>
    </div>
  </div>

  <div
    v-else-if="stage.is(2) && !error"
    class="wrapper"
  >
    <div class="icon-b icon-b-success success-icon" />
    <h3 class="wrapper-title">
      Заявка на активацию отправлена. Ожидайте показ
    </h3>
    <p class="success__wrapper-description">
      Уточнить статус заявки вы можете в разделе Настройки-История уведомлений
    </p>
    <AppButton
      fluid
      @click="$emit('close')"
    >
      Отлично
    </AppButton>
  </div>

  <div
    v-if="error"
    class="wrapper"
  >
    <div class="icon-b icon-b-error" />
    <h3 class="wrapper-title error__title">
      Активация не возможна, попробуйте позже
    </h3>
    <AppButton
      fluid
      @click="$emit('close')"
    >
      Закрыть
    </AppButton>
  </div>
</template>
<script setup>

import AppButton from '@/components/ui/AppButton.vue';
import { useStage } from '@/hooks/stage';
import { ref } from 'vue';
import { useStore } from 'vuex';
const props = defineProps({
  equipment: {
    type: Object,
    required: true
  }
});

const store = useStore();
const { stage } = useStage(1, 2, 1);
const error = ref(false);
const loading = ref(false);

const activatedEquipment = async () => {
  try {
    loading.value = true;
    await store.dispatch('resource/activated', {
      resId: props.equipment.resId
    });
    stage.next();
  } catch (e) {
    error.value = true;
  } finally {
    loading.value = false;
  }
};

</script>

<style scoped>

</style>
