import * as yup from 'yup';

// export const required = (value) => !!value;
// export const minLength = (num) => (value) => value.length >= num;
// export const length = (num) => (value) => value.length == num;
// export const digits = (value) => /^[0-9]*$/.test(value);

export const checkEmail = (value) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
};

export const checkAmount = (value) => {
  if (value === 0) {
    return false;
  }
  const pattern = /^\d+(\.\d{0,2})?$/;
  return pattern.test(value);
};

export const isINNLegal = (value) => {
  const valueToString = '' + value;
  const values = valueToString.split('');

  if (values.length === 10) {
    return (
      Number(values[9]) ===
      ((2 * values[0] +
        4 * values[1] +
        10 * values[2] +
        3 * values[3] +
        5 * values[4] +
        9 * values[5] +
        4 * values[6] +
        6 * values[7] +
        8 * values[8]) %
        11) %
        10
    );
  }
  return false;
};

// ----- Валидаторы для yup:
function checkINNLegal (msg) {
  return this.test({
    name: 'checkINNLegal',
    exclusive: false,
    message: msg || 'Некорректный ИНН',
    test: function (value) {
      return isINNLegal(value);
    }
  });
}

yup.addMethod(yup.string, 'checkINNLegal', checkINNLegal);
// ------------------------

export default yup;
