<template>
  <footer class="auth__wrapper-footer">
    <p class="auth__wrapper-footer-description">
      © {{ new Date().getFullYear() }} ПАО «МТС». Все права защищены.<br>
      <!--      Поменять потом ссылку-->
      При входе на ресурс, вы принимаете <a
        class="link footer__link"
        target="_blank"
        href="https://static.ssl.mts.ru/mts_rf/images/usloviya-edinogo-dostupa-k-servisam-MTS.html"
      >условия доступа</a>
    </p>
  </footer>
</template>

<script setup>

</script>

<style scoped>

.auth__wrapper-footer {
  margin-top: auto;
  margin-bottom: 20px;
}

.auth__wrapper-footer-description {
  font-family: 'MTS Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-grey);
  text-align: center;
}

.footer__link {
  font-family: 'MTS Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .auth__wrapper-footer{
    display: none;
  }
}

</style>
