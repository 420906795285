<template>
  <div :class="['input-field',{'input-field_invalid': Error}]">
    <label
      class="input-field__label"
      for="phone"
    >Телефон</label>
    <div class="input-field__control-box">
      <div
        v-if="mobile"
        class="input-field__control-reset-btn"
        @click="mobile = mobileStartValue"
      />
      <input
        id="phone"
        v-model="mobile"
        v-focus="needFocus"
        class="input-field__control"
        placeholder="+7 999 999-99-99"
        maxlength="16"
        @input="onInput"
        @paste="onPaste"
      >
    </div>
    <div
      v-if="Error?.length"
      class="input-field__description"
    >
      {{ Error }}
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
  props: {
    modelValue: { type: String },
    needFocus: {
      type: Boolean,
      required: false,
      default: false
    },
    Error: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const mobileStartValue = '+7 ';

    const mobile = ref(props.modelValue);

    onMounted(() => {
      makeBeautiful(getInputNumbers(mobile.value));
    });

    watch(mobile, value => {
      emit('update:modelValue', getInputNumbers(value));
    });

    const getInputNumbers = (value) => {
      return value.replace(/\D/g, '');
    };

    const onInput = (e) => {
      const input = e.target;

      const inputNumbersValue = getInputNumbers(mobile.value);
      const selectionStart = input.selectionStart;

      if (!inputNumbersValue) {
        mobile.value = '+7 ';
        return;
      }

      if (input.value.length !== selectionStart) {
        return;
      }

      makeBeautiful(inputNumbersValue);
    };

    const makeBeautiful = (inputNumbersValue) => {
      let formattedInputValue = '';

      if (inputNumbersValue[0] !== '7') {
        inputNumbersValue = '7' + inputNumbersValue;
      }

      if (inputNumbersValue.length > 0) {
        formattedInputValue = '+' + inputNumbersValue.substring(0, 1) + ' ' + inputNumbersValue.substring(1, 4);
      }

      if (inputNumbersValue.length > 4) {
        formattedInputValue += ' ' + inputNumbersValue.substring(4, 7);
      }

      if (inputNumbersValue.length > 7) {
        formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
      }

      if (inputNumbersValue.length > 9) {
        formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
      }

      mobile.value = formattedInputValue;
    };

    const onPaste = (e) => {
      const pasted = e.clipboardData ?? window.clipboardData;
      let inputNumbersValue;

      if (pasted) {
        const pastedText = pasted.getData('Text');
        inputNumbersValue = getInputNumbers(pastedText);
        mobile.value = inputNumbersValue;
      }
    };

    return { mobileStartValue, mobile, onInput, onPaste };
  }
};
</script>

<style>
</style>
