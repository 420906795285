<template>
  <div class="settings__document">
    <div class="document__name">
      <p class="document__name-text">
        {{ document.docName }}
      </p>
      <span
        v-if="document.signDate || document.contrDate"
        class="document__name-date"
      >
        Подписан {{ document.signDate ? document.signDate.split(' ')[0] : document.contrDate.split(' ')[0] }}
      </span>
    </div>
    <AppButton
      :loading="isLoading"
      loading-color="#000"
      :color="windowSize>=768?'button_secondary':'button_transparent'"
      @click="downloadFile"
    >
      <div
        v-if="windowSize>=768"
        class="button__download"
      >
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 17C0.447715 17 0 17.4477 0 18C0 18.5523 0.447715 19 1 19H17C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17H1Z"
            fill="black"
          />
          <path
            d="M7.99976 12.0332L7.99978 1C7.99978 0.447713 8.4475 0 8.99978 0C9.55207 0 9.99978 0.447717 9.99978 1L9.99976 12.0347C10.1718 11.8213 10.3712 11.569 10.6091 11.2673C11.1136 10.6275 11.5148 10.1176 11.8324 9.69457C12.1343 9.29246 12.462 9 13.0007 9C13.2288 9 13.4715 9.10763 13.6461 9.29196C13.9259 9.58749 14.0306 10.0802 13.6191 10.6426C13.2392 11.1619 12.759 11.7709 12.1796 12.5057C11.0724 13.9098 10.5188 14.6119 9.84265 14.8538C9.29804 15.0487 8.70271 15.0487 8.1581 14.8538C7.48193 14.6119 6.92831 13.9098 5.8211 12.5056C5.2417 11.7708 4.76155 11.1619 4.3816 10.6426C3.97016 10.0802 4.07485 9.58749 4.35468 9.29196C4.52923 9.10763 4.77192 9 5 9C5.53878 9 5.86641 9.29246 6.16833 9.69457C6.48594 10.1176 6.88711 10.6275 7.39161 11.2673C7.62896 11.5683 7.82796 11.8201 7.99976 12.0332Z"
            fill="black"
          />
        </svg>
        <span>
          Скачать
        </span>
      </div>
      <svg
        v-else
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 17C0.447715 17 0 17.4477 0 18C0 18.5523 0.447715 19 1 19H17C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17H1Z"
          fill="var(--secondary-grey-light,#969FA8)"
        />
        <path
          d="M7.99976 12.0332L7.99978 1C7.99978 0.447713 8.4475 0 8.99978 0C9.55207 0 9.99978 0.447717 9.99978 1L9.99976 12.0347C10.1718 11.8213 10.3712 11.569 10.6091 11.2673C11.1136 10.6275 11.5148 10.1176 11.8324 9.69457C12.1343 9.29246 12.462 9 13.0007 9C13.2288 9 13.4715 9.10763 13.6461 9.29196C13.9259 9.58749 14.0306 10.0802 13.6191 10.6426C13.2392 11.1619 12.759 11.7709 12.1796 12.5057C11.0724 13.9098 10.5188 14.6119 9.84265 14.8538C9.29804 15.0487 8.70271 15.0487 8.1581 14.8538C7.48193 14.6119 6.92831 13.9098 5.8211 12.5056C5.2417 11.7708 4.76155 11.1619 4.3816 10.6426C3.97016 10.0802 4.07485 9.58749 4.35468 9.29196C4.52923 9.10763 4.77192 9 5 9C5.53878 9 5.86641 9.29246 6.16833 9.69457C6.48594 10.1176 6.88711 10.6275 7.39161 11.2673C7.62896 11.5683 7.82796 11.8201 7.99976 12.0332Z"
          fill="var(--secondary-grey-light,#969FA8)"
        />
      </svg>
    </AppButton>
  </div>

  <app-toast
    v-if="toast.showToast.value"
    :text="toast.toastText.value"
    :type="toast.toastType.value"
  />
</template>

<script setup>
import AppButton from '@/components/ui/AppButton';
import { useWindowSize } from '@/hooks/window-size';
import { useStore } from 'vuex';
import { ref } from 'vue';
import AppToast from '@/components/ui/AppToast.vue';
import { useToast } from '@/hooks/toast';

const props = defineProps({
  document: Object
});
const { windowSize } = useWindowSize();
const store = useStore();
const isLoading = ref(false);
const toast = useToast();

const downloadFile = async () => {
  try {
    isLoading.value = true;
    const data = await store.dispatch('settings/downloadDocument', props.document);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = `${props.document.docName}.pdf`;
    link.click();
  } catch (e) {
    toast.showError(e);
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>

.settings__document:not(:last-child){
  margin-bottom: 24px;
}
.document__name {
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  display: flex;
  flex-direction: column;
}
.document__name-text {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 4px;
}
.document__name-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-grey-light,#969FA8);
}
.button__download {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
}
.button__download svg{
  margin-right: 11px;
}

@media (max-width: 767px) {
  .settings__document {
    align-items: flex-start;
  }
}

</style>
