<template>
  <teleport to="body">
    <app-modal
      v-if="!started"
      :title="title"
      @close="$emit('cancel')"
    >
      <div class="popup__body">
        <div class="form">
          <div class="form__pre">
            {{ text }}
          </div>
          <div class="form__btn-stack">
            <div class="form__btn-stack-item">
              <button
                :class="['form__submit', 'button', 'button_fluid', 'button_small-m', `button_${okButtonNegative ? 'negative': 'primary'}`, 'button_noscale']"
                @click.prevent="onButtonClick"
              >
                {{ okButtonText }}
              </button>
            </div>
            <div class="form__btn-stack-item">
              <button
                class="form__submit button button_fluid button_small-m button_secondary button_noscale"
                @click.prevent="$emit('cancel')"
              >
                {{ cancelButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-modal>
    <!-- <div v-if="started && !done">Выполняется операция...</div> -->
  </teleport>
</template>

<script>
import { ref } from 'vue';
import AppModal from '../../ui/AppModal';

export default {
  components: { AppModal },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    okButtonText: {
      type: String,
      default: 'Да'
    },
    okButtonNegative: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: 'Отмена'
    },
    okAction: {
      type: Function,
      required: true
    }
  },
  emits: ['cancel', 'ok', 'error'],
  setup (props, { emit }) {
    const started = ref(false);
    const done = ref(ref);

    const onButtonClick = async () => {
      try {
        started.value = true;
        await props.okAction();
        emit('ok');
      } catch (ex) {
        emit('error', ex);
      } finally {
        done.value = true;
      }
    };

    return { onButtonClick, started, done };
  }
};

</script>

<style>

</style>
