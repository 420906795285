import { ref, onUnmounted } from 'vue';

export function useTimer () {
  const count = ref(0);
  const inProcess = ref(false);
  let timerId = 0;

  const startTimer = (minutes) => {
    if (Number.isInteger(minutes) && minutes > 0 && !inProcess.value) {
      count.value = minutes;
      inProcess.value = true;

      timerId = setInterval(() => {
        if (count.value === 1) {
          count.value = count.value - 1;
          clearInterval(timerId);
          inProcess.value = false;
        } else {
          count.value = count.value - 1;
        }
      }, 1000);
    }
  };

  onUnmounted(() => {
    clearInterval(timerId);
  });

  return { count, inProcess, startTimer };
}
