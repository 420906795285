<template>
  <div class="signed__document-modal">
    <div v-if="!isSigned">
      <p class="popup__title">
        Подписание документа
      </p>
      <p :class="['signed__document-modal-description',{'isView':isViewDocument}]">
        Я ознакомлен и согласен с
        <a
          v-if="!isViewDocument"
          href=""
          class="link"
          @click.prevent="viewDocument"
        >условиями {{ document.docName }}</a>
        <AppLoader
          v-else
          style="margin-left: 10px"
          size="s"
          color="var(--link-light,#007CFF)"
        />
      </p>
      <p class="signed__error">
        {{ error }}
      </p>
    </div>
    <div
      v-else
      class="success__block"
    >
      <svg
        class="success-icon"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_7311_89358)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.194233 16.9251C0.670174 10.5302 0.908144 7.33275 4.11752 4.12337C7.3269 0.913998 10.5243 0.676029 16.9192 0.20009C18.577 0.0767148 20.287 0.00195312 21.997 0.00195312C23.707 0.00195312 25.417 0.0767161 27.0747 0.200092C33.4696 0.676033 36.6671 0.914004 39.8765 4.12338C43.0858 7.33276 43.3238 10.5302 43.7997 16.9251C43.9231 18.5828 43.9979 20.2928 43.9979 22.0029C43.9979 23.7129 43.9231 25.4229 43.7997 27.0806C43.3238 33.4755 43.0858 36.6729 39.8765 39.8823C36.6671 43.0917 33.4696 43.3297 27.0747 43.8056C25.417 43.929 23.707 44.0037 21.997 44.0037C20.287 44.0037 18.577 43.929 16.9192 43.8056C10.5243 43.3297 7.32689 43.0917 4.11751 39.8823C0.908139 36.6729 0.670169 33.4755 0.194231 27.0806C0.0708554 25.4229 -0.00390625 23.7129 -0.00390625 22.0028C-0.00390625 20.2928 0.0708567 18.5828 0.194233 16.9251Z"
            fill="#26CD58"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.2837 17.6173C33.2384 16.6626 33.2384 15.1149 32.2837 14.1603C31.3291 13.2057 29.7814 13.2057 28.8268 14.1603L19.5553 23.4318L16.3949 20.2714C15.4402 19.3168 13.8925 19.3168 12.9379 20.2714C11.9833 21.226 11.9833 22.7738 12.9379 23.7284L17.8268 28.6173C18.7814 29.5719 20.3291 29.5719 21.2837 28.6173L32.2837 17.6173Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_7311_89358">
            <rect
              width="44"
              height="44"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
      <p class="title__success">
        Документ подписан
      </p>
    </div>
    <AppButton
      fluid
      :loading="isLoading"
      @click="signed"
    >
      {{ isSigned?'Отлично':'Подписать' }}
    </AppButton>
  </div>
</template>

<script setup>
import AppButton from '@/components/ui/AppButton';
import { ref } from 'vue';
import AppLoader from '@/components/ui/AppLoader';
import { useStore } from 'vuex';

const props = defineProps({
  document: Object
});
const store = useStore();

const isSigned = ref(false);
const emit = defineEmits(['close', 'signed']);
const isLoading = ref(false);
const isViewDocument = ref(false);
const error = ref('');

const signed = async () => {
  if (isSigned.value) {
    emit('close');
    return;
  }

  try {
    isLoading.value = true;
    await store.dispatch('settings/signDocument', props.document);
    isSigned.value = true;
    emit('signed');
  } catch (e) {
    error.value = e.data.message;
  } finally {
    isLoading.value = false;
  }
};

const viewDocument = async () => {
  try {
    isViewDocument.value = true;
    const data = await store.dispatch('settings/viewDocument', props.document);
    window.open(encodeURI(URL.createObjectURL(data)));
  } catch (e) {

  } finally {
    isViewDocument.value = false;
  }
};

</script>

<style scoped>

.signed__document-modal {
  display: flex;
  flex-direction: column;
}

.signed__document-modal-description {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  margin-bottom: 32px;
}

.isView {
  display: flex;
  align-items: center;
}

.title__success {
  font-size: 20px;
  line-height: 24px;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
  font-weight: 500;
}

.success__block {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.success__block svg {
  margin-bottom: 16px;
}

.success-icon {
  animation: success .5s 1 ease;
}

.signed__error {
  color: var(--primary-light-negative,#EB4A13);
  margin-bottom: 10px;
}

@keyframes success {
  0%, 100%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.2);
  }
}

</style>
