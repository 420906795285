<template>
  <div :class="['input-field', {'input-field_invalid': props.Error}]">
    <label
      class="input-field__label"
      :for="id"
    >
      <slot />
    </label>
    <div :class="['input-field__control-box',{'input-field__control-box_password':type==='password'}]">
      <div
        v-if="reset && modelValue"
        class="input-field__control-reset-btn"
        @click="resetValue"
      />
      <div
        v-if="modelValue && type==='password'"
        :class="['input-field__control-show-pwd-btn',{'input-field__control-show-pwd-btn-active':pwdShowToggle.is(false)}]"
        @click="pwdShowToggle.switch"
      />
      <div
        v-if="success && !Error?.length"
        class="input-field__control-success"
      />
      <input
        :id="id"
        :value="modelValue"
        :type="isPassword ? (pwdShowToggle.is(true) ? 'password':'text') : props.type"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        class="input-field__control"
        @input="updateValue"
        @blur="props.blur"
      >
    </div>
    <div
      v-if="props.Error?.length"
      class="input-field__description"
    >
      {{ props.Error }}
    </div>
  </div>
</template>

<script setup>

import { useToggle } from '@/hooks/toggle';
import { computed } from 'vue';

const props = defineProps({

  modelValue: String,
  Error: String,
  type: String,
  placeholder: String,
  id: String,
  maxlength: String,
  autocomplete: String,
  reset: Boolean,
  blur: Function,
  success: {
    type: Boolean,
    default: false
  }
});

const isPassword = computed(() => props.type === 'password');
const { toggle: pwdShowToggle } = useToggle(isPassword.value);

const emit = defineEmits(['update:modelValue']);

const updateValue = (event) => {
  emit('update:modelValue', event.target.value);
};

const resetValue = () => {
  emit('update:modelValue', '');
};

</script>

<style scoped>

</style>
