<template>
  <app-page>
    <div class="page__content page__content_regular page__content_noaside">
      <div class="page__content-inner">
        <div class="blocks page__content-blocks">
          <div class="blocks__item blocks__item_alt">
            <h1 class="page__nav-title page__nav-title_margin page__nav-title_margin-top16">
              Вы используете подписку Весь МТС Экстра
            </h1>
            <div class="page__max-width">
              <p class="para para_margin">
                Основную информация о расходах вы можете найти в <a
                  class="link"
                  href="https://lk.mts.ru/"
                  target="_blank"
                >личном кабинете</a> мобильной связи
              </p>
              <p class="para para_bold para_margin">
                Что входит в подписку
              </p>
              <ul class="list">
                <li class="list__item">
                  высокоскоростной мобильный интернет
                </li>
                <li class="list__item">
                  онлайн-кинотеатр <a
                    class="link"
                    href="https://hello.kion.ru/?utm_source=mts_ru&utm_medium=ref&utm_campaign=inhouse_kion&utm_term=site&utm_content=w_link&_ga=2.220574360.1056885206.1669023487-994673150.1666605077"
                    target="_blank"
                  >KION</a>
                </li>
                <li class="list__item">
                  спутниковое ТВ МТС
                </li>
              </ul>
              <p class="para para_bold para_margin">
                Полезные ссылки
              </p>
              <ul class="list">
                <li class="list__item">
                  список <a
                    class="link"
                    href="https://moskva.mts.ru/personal/dlya-doma/tarifi/tv-paket/sputnik-rashirennyi-paket/moskva"
                    target="_blank"
                  >подключенных каналов</a>
                </li>
                <li class="list__item">
                  <a
                    class="link"
                    href="https://kion.ru/epg"
                    target="_blank"
                  >телепрограмма</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-page>
</template>

<script>
import AppPage from '@/components/ui/AppPage.vue';

export default {
  components: { AppPage },
  setup () {
    return { };
  }

};
</script>

<style>

</style>
