import { ref } from 'vue';

export function useToast () {
  const showToast = ref(false);

  const toastText = ref('Успешно');

  const toastType = ref('ok');

  // type: ok, error или warning
  const triggerToast = (text, type) => {
    toastText.value = text;
    toastType.value = type;
    showToast.value = true;
    setTimeout(() => showToast.value = false, 5000);
  };

  const showError = (error) => {
    if (error.status >= 400 && error.status <= 499 && error.status !== 401) {
      triggerToast(error.data.message, 'error');
      return;
    }
    triggerToast('Произошла ошибка', 'error');
  };

  return { showToast, triggerToast, toastText, toastType, showError };
}
