import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import Auth from '../views/Auth.vue';
import Home from '../views/Home.vue';
import AuthMobileError from '../views/AuthMobileError';
import Settings from '../views/Settings.vue';
import SettingsPersonal from '../components/settings/SettingsPersonal.vue';
import SettingsDocuments from '../components/settings/SettingsDocuments';
import SettingsPassword from '../components/settings/SettingsPassword.vue';
import SettingsInforming from '../components/settings/SettingsInforming.vue';
import SettingsNotifications from '../components/settings/SettingsNotifications.vue';
import Services from '../views/Services.vue';
import Finance from '../views/Finance.vue';
import Convergent from '../views/Convergent.vue';
import Support from '../views/Support/Support.vue';
import Chapter from '@/views/Support/Chapter.vue';

import Tariffs from '../views/Tariffs.vue';
import TariffsChange from '@/components/tariffs/TariffsChange.vue';

import { useGTM } from '@/hooks/gtm';
import { useTypeLoginPassword, VARIABLES_LOCAL_LOGIN } from '@/hooks/useTypeLoginPassword';

const gtm = useGTM();

const routes = [
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/auth401',
    name: 'authMobile',
    component: AuthMobileError,
    meta: {
      layout: 'auth-mobile',
      auth: false
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'main',
      auth: true,
      canComeTokenInParams: true,
      needAccountInNavbar: true
    },
    children: [
      {
        path: '/payments',
        name: 'payments',
        component: () => import('../views/Payments.vue'),
        meta: {
          // layout: 'main',
          // auth: true,
          needAccountInNavbar: false,
          breadcrumb: [
            { name: 'Главная', routeName: 'home' },
            { name: 'Пополнение баланса', active: true }
          ]
        },
        children: [
          {
            path: 'card',
            name: 'card',
            component: () => import('../components/payments/PaymentsCard.vue')
          },
          {
            path: 'prepayment',
            name: 'prepayment',
            component: () => import('../components/payments/PaymentsPrepayment.vue')
            // meta: {
            //   notAvailableForConvergent: true,
            // }
          },
          {
            path: 'prepayment_history',
            name: 'prepayment_history',
            component: () => import('../components/payments/PaymentsPrepaymentHistory.vue')
            // meta: {
            //   notAvailableForConvergent: true,
            // }
          }
        ]
      },
      {
        path: '/tariffs/:resId/',
        name: 'tariffs',
        component: Tariffs,
        props: true,
        meta: {
          needAccountInNavbar: false,
          asideType: 'noaside',
          breadcrumb: [
            { name: 'Главная', routeName: 'home' },
            { name: 'Выбор тарифа', active: true }
          ]
        },
        children: [
          {
            path: 'change/:tpId',
            name: 'tariffs_change',
            component: TariffsChange,
            props: true,
            meta: {
              breadcrumb: [
                { name: 'Главная', routeName: 'home' },
                { name: 'Выбор тарифа', routeName: 'tariffs' },
                { name: 'Смена тарифного плана', active: true }
              ]
            }
          }
        ]
      },
      {
        path: '/convergent',
        name: 'convergent',
        component: Convergent,
        meta: {
          layout: 'main',
          auth: true,
          asideType: 'noaside',
          needAccountInNavbar: true
        }
      }
    ]
  },
  {
    path: '/settings',
    // redirect: '/settings/personal',
    name: 'settings',
    component: Settings,
    meta: {
      layout: 'main',
      auth: true
    },
    children: [
      {
        path: 'personal',
        name: 'personal',
        component: SettingsPersonal
      },
      {
        path: 'documents',
        name: 'documents',
        component: SettingsDocuments
      },
      {
        path: 'password',
        component: SettingsPassword
      },
      {
        path: 'informing',
        component: SettingsInforming
      },
      {
        path: 'notifications',
        component: SettingsNotifications
      }
    ]
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      layout: 'main',
      auth: true,
      asideType: 'noaside'
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: Finance,
    meta: {
      layout: 'main',
      auth: true,
      asideType: 'aside'
    }
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    meta: {
      layout: 'main',
      auth: true
    },
    children: [
      {
        path: ':category',
        name: 'supCategory',
        component: Chapter
      }
    ]
  },
  {
    path: '/stock',
    name: 'stock',
    component: () => import('@/views/Stock.vue'),
    meta: {
      layout: 'main',
      auth: true,
      asideType: 'noaside'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'home'
    }
  }

  // {
  //   path: '/login',
  //   name: 'login',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth;
  const isAuthLayout = to.meta.layout === 'auth';

  const canComeTokenInParams = to.meta.canComeTokenInParams;

  const { setTypeLogin, removeItem } = useTypeLoginPassword();

  if (canComeTokenInParams && to.query.token) {
    store.commit('auth/setToken', to.query.token);
    setTypeLogin('phone');
    removeItem(VARIABLES_LOCAL_LOGIN.validPassword);
  }
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  // const notAvailableForConvergent = to.meta.notAvailableForConvergent;
  // const needShowConvergent = store.getters['user/needShowConvergent']; //режим отображения для конвергентного договора

  if (isAuthLayout && isAuthenticated) {
    next('/');
  } else if (requireAuth && !isAuthenticated) {
    const toMTSId = encodeURIComponent(`https://login.mts.ru/amserver/oauth2/authorize?scope=${process.env.VUE_APP_SCOPE}&response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&state=${process.env.VUE_APP_STATE}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}`);
    window.location.replace(`https://login.mts.ru/amserver/UI/Logout?goto=${toMTSId}`);
  } else {
    const userInfo = store.getters['user/userInfo'];
    gtm.push_pages({ screenName: to.path, userAuth: isAuthenticated, personalAccount: userInfo?.personalAccount });
    next();
  }
});

router.afterEach(() => {
  window.scroll({
    top: 0
  });
});

export default router;
