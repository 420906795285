export default {
  name: 'focus',
  mounted (el, binding) {
    if (binding.value === false) {
      return;
    }

    el.focus();
  }
};
