<template>
  <div class="mobile__datePicker">
    <div @click.stop="isActive=!isActive">
      <slot>
        <div :class="['view__date',{'active': isActive}]">
          <span v-if="modelValue">{{ FormatInputDate(modelValue) }}</span>
          <span
            v-else
            class="calendar__empty"
          >дд.мм.гггг</span>
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.317 3.68597C15.612 2.98098 14.9084 2.62649 14.0029 2.41921V1C14.0029 0.447715 13.5552 0 13.0029 0C12.4506 0 12.0029 0.447715 12.0029 1V2.15211C11.7141 2.12824 11.4073 2.10541 11.0801 2.08105C10.402 2.03058 9.70245 2 9.00293 2C8.30341 2 7.60379 2.03059 6.92566 2.08106C6.59848 2.10541 6.29176 2.12824 6.00293 2.15211V1C6.00293 0.447715 5.55521 0 5.00293 0C4.45064 0 4.00293 0.447715 4.00293 1V2.41921C3.09747 2.62649 2.39389 2.98098 1.6889 3.68597C0.376025 4.99884 0.278678 6.30684 0.0839828 8.92282C0.033513 9.60095 0.00292969 10.3005 0.00292969 11C0.00292969 11.6995 0.033513 12.399 0.0839829 13.0772C0.278678 15.6932 0.376025 17.0012 1.6889 18.314C3.00177 19.6269 4.30977 19.7243 6.92575 19.9189C7.60388 19.9694 8.30341 20 9.00293 20C9.70245 20 10.402 19.9694 11.0801 19.9189C13.6961 19.7243 15.0041 19.6269 16.317 18.314C17.6298 17.0012 17.7272 15.6932 17.9219 13.0772C17.9723 12.399 18.0029 11.6995 18.0029 11C18.0029 10.3005 17.9723 9.60095 17.9219 8.92282C17.7272 6.30684 17.6298 4.99884 16.317 3.68597ZM2.99998 8C2.4477 8 1.99998 8.44771 1.99998 9C1.99998 9.55228 2.4477 10 2.99998 10H15C15.5523 10 16 9.55228 16 9C16 8.44771 15.5523 8 15 8H2.99998ZM12 15.4C12.7732 15.4 13.4 14.7732 13.4 14C13.4 13.2268 12.7732 12.6 12 12.6C11.2268 12.6 10.6 13.2268 10.6 14C10.6 14.7732 11.2268 15.4 12 15.4Z"
              fill="#969FA8"
            />
          </svg>
        </div>
      </slot>
    </div>

    <CalendarMobile
      v-if="isActive && isCalendarMobile"
      :date="modelValue"
      :range="range"
      :min="min"
      :max="max"
      @dateApply="dateApply"
      @close="isActive=false"
    />
    <CalendarDesktop
      v-if="isActive && !isCalendarMobile"
      :date="modelValue"
      :range="range"
      :min="min"
      :max="max"
      @dateApply="dateApply"
      @close="isActive=false"
    />
  </div>
</template>

<script setup>
import { FormatInputDate } from '@/utils/FormatInputDate';
import { onMounted, onUnmounted, ref } from 'vue';
import CalendarMobile from '@/components/ui/AppDatePickerMobile/CalendarMobile';
import CalendarDesktop from '@/components/ui/AppDatePickerMobile/CalendarDesktop';

const props = defineProps({
  modelValue: [Array, Date],
  range: {
    type: Boolean,
    default: false
  },
  min: {
    type: Date,
    required: false
  },
  max: {
    type: Date,
    required: false
  }
});

const emit = defineEmits(['update:modelValue']);
const isActive = ref(false);
const isCalendarMobile = ref(window.innerWidth <= 600);
const resize = () => {
  isCalendarMobile.value = window.innerWidth <= 600;
};

const dateApply = (dateArg) => {
  emit('update:modelValue', dateArg);
  if (!isCalendarMobile.value) return;
  isActive.value = false;
};

onMounted(() => {
  window.addEventListener('resize', resize);
});
onUnmounted(() => {
  window.removeEventListener('resize', resize);
});

</script>

<style scoped>

.mobile__datePicker {
  position: relative;
}

.view__date {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px 15px 10px 12px;
  border: 1px solid rgba(188, 195, 208, 0.5);
  border-radius: 8px;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
  box-sizing: border-box;
  min-width: 272px;
  background-color: #F2F3F7;
  transition: border-color .2s ease;
}

.view__date:hover {
  border-color: var(--link-light);
}

.active {
  border-color: var(--link-light);
}

.calendar__empty {
  font-size: 17px;
  line-height: 24px;
  color: var(--secondary-grey);
  font-family: "MTSCompactRegular", "MTS Sans", serif;
}

</style>
