export const getMonth = (num) => {
  switch (num) {
    case 0: return 'Январь';
    case 1: return 'Февраль';
    case 2: return 'Март';
    case 3: return 'Апрель';
    case 4: return 'Май';
    case 5: return 'Июнь';
    case 6: return 'Июль';
    case 7: return 'Август';
    case 8: return 'Сентябрь';
    case 9: return 'Октябрь';
    case 10: return 'Ноябрь';
    case 11: return 'Декабрь';
  }
};

export const getMonthSmall = (num) => {
  switch (num) {
    case 0: return 'Янв';
    case 1: return 'Фев';
    case 2: return 'Март';
    case 3: return 'Апр';
    case 4: return 'Май';
    case 5: return 'Июнь';
    case 6: return 'Июль';
    case 7: return 'Авг';
    case 8: return 'Сен';
    case 9: return 'Окт';
    case 10: return 'Нояб';
    case 11: return 'Дек';
  }
};

export const getMonthSmallNum = (num) => {
  switch (num) {
    case 'Янв': return 0;
    case 'Фев': return 1;
    case 'Март': return 2;
    case 'Апр': return 3;
    case 'Май': return 4;
    case 'Июнь': return 5;
    case 'Июль': return 6;
    case 'Авг': return 7;
    case 'Сен': return 8;
    case 'Окт': return 9;
    case 'Нояб': return 10;
    case 'Дек': return 11;
  }
};
