<template>
  <confirm-dialog
    v-if="showConfirmModal"
    :title="modalTitle"
    :text="modalText"
    :ok-button-text="okButtonText"
    :ok-button-negative="okButtonNegative"
    :cancel-button-text="cancelButtonText"
    :ok-action="okButtonClick"
    @ok="onDialogSuccess"
    @cancel="onDialogCancel"
    @error="onDialogError"
  />

  <teleport to="body">
    <app-modal
      v-if="showNotAddPackIconModal"
      :title="pack.tariff.name"
      @close="showNotAddPackIconModal = false"
    >
      {{ pack.tariff.description1 }}
    </app-modal>
  </teleport>

  <confirm-dialog-two-buttons
    v-if="showIconModal"
    :title="pack.tariff.name"
    :text="pack.tariff.description1"
    ok-button1-text="Подключить"
    ok-button2-text="Тест-драйв"
    @ok1="iconModalOk1"
    @ok2="iconModalOk2"
    @cancel="showIconModal = false"
    @error="showIconModal = false"
  />

  <confirm-dialog-one-button
    v-if="showPackIconModal"
    :title="pack.tariff.name"
    :text="pack.tariff.description1"
    ok-button-text="Подключить"
    @ok="iconModalOk"
    @cancel="showPackIconModal = false"
    @error="showPackIconModal = false"
  />
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useConfirmDialog } from '@/hooks/confirm-dialog';
import ConfirmDialog from '@/components/shared/modals/ConfirmDialog.vue';
import AppModal from '@/components/ui/AppModal.vue';
import ConfirmDialogTwoButtons from '@/components/shared/modals/ConfirmDialogTwoButtons.vue';
import ConfirmDialogOneButton from '@/components/shared/modals/ConfirmDialogOneButton.vue';

export default {
  components: { ConfirmDialog, AppModal, ConfirmDialogTwoButtons, ConfirmDialogOneButton },
  props: {
    type: {
      type: String,
      required: true,
      validator (value) {
        return ['testDrive', 'pack', 'availablePack'].includes(value);
      }
    },
    pack: {
      type: Object,
      require: true
    },
    actionType: {
      type: String,
      validator (value) {
        return ['addPackShow', 'deletePackShow', 'addTestDrive', 'deleteTestDriveShow', 'iconShow'].includes(value);
      }
    },
    toast: {
      type: Object,
      required: false
    },
    card: {
      type: Object,
      required: true
    },
    packChangedCallback: {
      type: Function
    }
  },
  emits: ['hidden', 'started', 'error'],
  setup (props, { emit }) {
    const store = useStore();

    const AVAILABLE_PACK = 'availablePack';

    const show = () => {
      if (props.actionType === 'addPackShow') {
        addPackShow();
      } else if (props.actionType === 'deletePackShow') {
        deletePackShow();
      } else if (props.actionType === 'addTestDrive') {
        addTestDrive();
      } else if (props.actionType === 'deleteTestDriveShow') {
        deleteTestDriveShow();
      } else if (props.actionType === 'iconShow') {
        iconShow();
      }
    };

    onMounted(() => show());

    const dialog = useConfirmDialog({ toast: props.toast, sucessCallback: sucessCallback, errorCallback: errorCallback });

    const showIconModal = ref(false);
    const showNotAddPackIconModal = ref(false);
    const showPackIconModal = ref(false);

    watch([dialog.showConfirmModal, showPackIconModal, showNotAddPackIconModal, showIconModal], ([new1, new2, new3, new4]) => {
      if (!new1 && !new2 && !new3 && !new4) {
        emit('hidden');
      }
    });

    const TEST_DRIVE_MODAL_TITLE = 'Тест-драйв';

    const TEST_DRIVE_MODAL_TEXT = 'Подключите тестовый просмотр без абонентской платы на трое суток (72 часа), чтобы быть уверенными в своем решении о подписке. Воспользоваться сервисом тестового доступа вы можете один раз для каждого дополнительного пакета каналов, далее подключение возможно согласно установленным тарифам.';
    const TEST_DRIVE_DELETE_MODAL_TEXT = 'Хотите завершить пробный период доступа к подписке досрочно?';
    const PACK_ADD_MODAL_TEXT = 'Подключить выбранный пакет каналов?';
    const PACK_DELETE_MODAL_TEXT = 'Отменить подписку на выбранный пакет каналов?';

    const PACK_ADD_OK_TEXT = 'Подключить';
    const PACK_ADD_CANCEL_TEXT = 'Отмена';

    const PACK_DELETE_OK_TEXT = 'Отменить подписку';
    const PACK_DELETE_CANCEL_TEXT = 'Закрыть';

    const TEST_DRIVE_OK_TEXT = 'Активировать';
    const TEST_DRIVE_CANCEL_TEXT = 'Отмена';

    const TEST_DRIVE_DELETE_OK_TEXT = 'Да, завершить';
    const TEST_DRIVE_DELETE_CANCEL_TEXT = 'Отмена';

    const modalText = ref('');
    const modalTitle = ref('');
    const okButtonText = ref('');
    const cancelButtonText = ref('');
    const okButtonNegative = ref(false);

    const okActionsMap = [
      'packAdd',
      'packDelete',
      'testDriveAdd',
      'testDriveDelete'
    ];
    const okActionType = ref();

    const iconShow = () => {
      if (testDriveCanBeConnected.value) {
        okActionType.value = okActionsMap[2];
        showIconModal.value = true;
      } else if (props.type === AVAILABLE_PACK) {
        okActionType.value = okActionsMap[0];
        showPackIconModal.value = true;
      } else {
        showNotAddPackIconModal.value = true;
      }
    };

    const addPackShow = () => {
      dialogParamsSet('addPackShow');
      okActionType.value = okActionsMap[0];
      dialog.showConfirmDialog();
    };

    const deletePackShow = () => {
      dialogParamsSet('deletePackShow');
      okActionType.value = okActionsMap[1];
      dialog.showConfirmDialog();
    };

    const addTestDrive = () => {
      dialogParamsSet('addTestDrive');
      okActionType.value = okActionsMap[2];
      dialog.showConfirmDialog();
    };

    const deleteTestDriveShow = () => {
      dialogParamsSet('deleteTestDriveShow');
      okActionType.value = okActionsMap[3];
      dialog.showConfirmDialog();
    };

    const iconModalOk1 = () => {
      showIconModal.value = false;
      addPackShow();
    };

    const iconModalOk2 = () => {
      showIconModal.value = false;
      addTestDrive();
    };

    const iconModalOk = () => {
      showPackIconModal.value = false;
      addPackShow();
    };

    async function sucessCallback () {
      await props.packChangedCallback();
    }

    function errorCallback () {
      emit('error');
    }

    const okButtonClick = async () => {
      emit('started');
      // Подключение пакета
      if (okActionType.value === okActionsMap[0]) {
        await store.dispatch('services/packAdd', { tariffId: props.pack.tariff.id, resId: props.card.id });
        await store.dispatch('user/updateBalance');
      } else if (okActionType.value === okActionsMap[1]) { // Отключение пакета
        await store.dispatch('services/packDelete', { resId: props.pack.resId, card: props.card });
        await store.dispatch('user/updateBalance');
      } else if (okActionType.value === okActionsMap[2]) { // Подключение тест-драйва
        await store.dispatch('services/testDriveAdd', { tariffId: props.pack.tariff.id, resId: props.card.id });
      } else if (okActionType.value === okActionsMap[3]) { // Отключение тест-драйва
        await store.dispatch('services/testDriveDelete', { testDriveId: props.pack.tariff.testDrive.id });
      }
    };

    function dialogParamsSet (type) {
      if (type === 'addPackShow') {
        modalTitle.value = props.pack.tariff.name;
        modalText.value = PACK_ADD_MODAL_TEXT;
        okButtonText.value = PACK_ADD_OK_TEXT;
        cancelButtonText.value = PACK_ADD_CANCEL_TEXT;
      } else if (type === 'deletePackShow') {
        modalTitle.value = props.pack.tariff.name;
        modalText.value = PACK_DELETE_MODAL_TEXT;
        okButtonText.value = PACK_DELETE_OK_TEXT;
        cancelButtonText.value = PACK_DELETE_CANCEL_TEXT;
        okButtonNegative.value = true;
      } else if (type === 'addTestDrive') {
        modalTitle.value = TEST_DRIVE_MODAL_TITLE;
        modalText.value = TEST_DRIVE_MODAL_TEXT;
        okButtonText.value = TEST_DRIVE_OK_TEXT;
        cancelButtonText.value = TEST_DRIVE_CANCEL_TEXT;
      } else if (type === 'deleteTestDriveShow') {
        modalTitle.value = props.pack.tariff.name;
        modalText.value = TEST_DRIVE_DELETE_MODAL_TEXT;
        okButtonText.value = TEST_DRIVE_DELETE_OK_TEXT;
        cancelButtonText.value = TEST_DRIVE_DELETE_CANCEL_TEXT;
        okButtonNegative.value = true;
      }
    }

    const testDriveCanBeConnected = computed(() => props.type === AVAILABLE_PACK && props.pack.tariff.testDrive.actionType === 'Подключить');

    return {
      ...dialog,
      modalTitle,
      modalText,
      okButtonText,
      cancelButtonText,
      okButtonNegative,
      showIconModal,
      showNotAddPackIconModal,
      showPackIconModal,
      iconModalOk1,
      iconModalOk2,
      iconModalOk,
      okButtonClick
    };
  }
};
</script>
