<template>
  <div
    ref="refWrapper"
    class="calendar__wrapper"
  >
    <div class="calendar__header">
      <button
        class="button__reset button__arrow"
        @click.prevent="clickPrev"
      >
        <svg
          width="8px"
          height="16px"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          fit=""
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
        >-->
          <path
            d="M8 1.01246C8 0.11226 6.92341 -0.338563 6.29377 0.297975L3.19835 3.4273C1.06612 5.58289 -9.10439e-07 6.66069 0 8C-4.27446e-08 9.33932 1.06612 10.4171 3.19836 12.5727L6.29377 15.702C6.92341 16.3386 8 15.8877 8 14.9875C8 14.7196 7.8947 14.4625 7.70726 14.2731L4.61185 11.1437C3.50577 10.0255 2.82141 9.32762 2.38882 8.75443C1.99838 8.23709 1.99883 8.05648 1.99897 8.00276L1.99897 8L1.99897 7.99725C1.99883 7.94353 1.99838 7.76292 2.38882 7.24558C2.8214 6.67239 3.50577 5.97446 4.61184 4.85627L7.70726 1.72695C7.8947 1.53745 8 1.28044 8 1.01246Z"
            fill="black"
          />
        </svg>
      </button>
      <div>
        <button
          v-show="!isSelectMonth && !isSelectYear"
          class="button__reset button__nav"
          @click.prevent="isSelectMonth=!isSelectMonth"
        >
          {{ getMonth(month) }}
        </button>
        <button
          class="button__reset button__nav"
          @click.prevent="isSelectYear=!isSelectYear; isSelectMonth=false"
        >
          {{ !isSelectYear ? selectYear : `${yearsRange[0]} – ${yearsRange[1]}` }}
        </button>
      </div>
      <button
        :class="['button__reset button__arrow',
                 {'hidden':Hidden()}]"
        @click.prevent="clickNext"
      >
        <svg
          style="transform: rotateZ(180deg)"
          width="8px"
          height="16px"
          viewBox="0 0 8 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          fit=""
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
        >-->
          <path
            d="M8 1.01246C8 0.11226 6.92341 -0.338563 6.29377 0.297975L3.19835 3.4273C1.06612 5.58289 -9.10439e-07 6.66069 0 8C-4.27446e-08 9.33932 1.06612 10.4171 3.19836 12.5727L6.29377 15.702C6.92341 16.3386 8 15.8877 8 14.9875C8 14.7196 7.8947 14.4625 7.70726 14.2731L4.61185 11.1437C3.50577 10.0255 2.82141 9.32762 2.38882 8.75443C1.99838 8.23709 1.99883 8.05648 1.99897 8.00276L1.99897 8L1.99897 7.99725C1.99883 7.94353 1.99838 7.76292 2.38882 7.24558C2.8214 6.67239 3.50577 5.97446 4.61184 4.85627L7.70726 1.72695C7.8947 1.53745 8 1.28044 8 1.01246Z"
            fill="black"
          />
        </svg>
      </button>
    </div>

    <div v-if="!isSelectMonth && !isSelectYear">
      <DayWeek />
      <Month
        :first-active="firstActive"
        :select-date="selectDate"
        :year="selectYear"
        :month="month"
        :range="props.range"
        :min="min"
        :max="max"
        @selectDay="selectDay"
      />
    </div>
    <SelectMonth
      v-show="isSelectMonth"
      :select-date="selectDate"
      :year="selectYear"
      :min="min"
      :max="max"
      @setMonth="setMonth"
    />
    <SelectYearNew
      v-show="isSelectYear"
      :select-date="selectDate"
      :year="selectYear"
      :years-range="yearsRange"
      :min="min"
      :max="max"
      @setYear="setYear"
    />
  </div>
</template>

<script setup>
import { getMonth, getMonthSmallNum } from '@/utils/getMounth';
import DayWeek from '@/components/ui/AppDatePickerMobile/DayWeek';
import Month from '@/components/ui/AppDatePickerMobile/Month';
import { onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import SelectMonth from '@/components/ui/AppDatePickerMobile/SelectMonth';

import SelectYearNew from '@/components/ui/AppDatePickerMobile/SelectYearNew';

const emit = defineEmits(['dateApply', 'close']);

const props = defineProps({
  date: [Array, Date],
  range: Boolean,
  min: {
    type: Date,
    required: false
  },
  max: {
    type: Date,
    required: false
  }
});

const isDate = () => {
  if (props.range && props.date) {
    return [props.date[0], props.date[1]];
  }
  if (props.range && !props.date) {
    return [];
  }
  if (!props.range && props.date) {
    return [props.date];
  }
  if (!props.range && !props.date) {
    return [];
  }
};
const refWrapper = ref(null);

const selectYear = ref(null);
const isFirstActive = ref(null);
const isSecondActive = ref(null);
const selectDate = ref(null);
const firstActive = ref(null);
const month = ref(null);
const isSelectMonth = ref(false);
const isSelectYear = ref(false);
const yearsRange = ref([]);

const init = () => {
  isFirstActive.value = (props.date ? props.range ? !!props.date[0] : !!props.date : false);
  isSecondActive.value = (props.date ? props.range ? !!props.date[1] : !!props.date : false);
  selectDate.value = (isDate());
  firstActive.value = true;
  yearsRange.value = [selectYear.value - 11, selectYear.value];
};

watch(props, () => {
  init();
});

const selectDay = (date) => {
  if (!props.range) {
    selectDate.value[0] = date;
    emit('dateApply', selectDate.value[0]);
    return;
  }
  if (isFirstActive.value && isSecondActive.value) {
    isFirstActive.value = false;
    isSecondActive.value = false;
    selectDate.value[1] = null;
    selectDate.value[0] = date;
  }
  if (!isFirstActive.value) {
    selectDate.value[0] = date;
    isFirstActive.value = true;
  } else {
    selectDate.value[1] = date;
    isSecondActive.value = true;

    selectDate.value.sort((a, b) => new Date(a) - new Date(b));
    emit('dateApply', !props.range ? selectDate.value[0] : selectDate.value);
  }
};

const Hidden = () => {
  if (isSelectMonth.value) {
    return (new Date()).getFullYear() === selectYear.value;
  }
  if (isSelectYear.value) {
    return (new Date()).getFullYear() <= yearsRange.value[1];
  }
  return month.value === (new Date()).getMonth() && (new Date()).getFullYear() === selectYear.value;
};

const clickNext = () => {
  if (isSelectMonth.value) {
    selectYear.value++;
    return;
  }
  if (isSelectYear.value) {
    yearsRange.value[0] += 11;
    yearsRange.value[1] += 11;
    return;
  }
  if (month.value === 11) {
    month.value = 0;
    selectYear.value++;
  } else {
    month.value++;
  }
};
const clickPrev = () => {
  if (isSelectMonth.value) {
    selectYear.value--;
    return;
  }
  if (isSelectYear.value) {
    yearsRange.value[0] -= 11;
    yearsRange.value[1] -= 11;
    return;
  }
  if (month.value === 0) {
    month.value = 11;
    selectYear.value--;
  } else {
    month.value--;
  }
};

const outSideClick = (event) => {
  if (refWrapper.value !== event.target && !refWrapper.value.contains(event.target)) {
    emit('close');
  }
};

onMounted(() => {
  window.addEventListener('click', outSideClick, false);
});

onUnmounted(() => {
  window.removeEventListener('click', outSideClick, false);
});

onBeforeMount(() => {
  selectYear.value = (props.date ? props.range ? props.date[0].getFullYear() : props.date.getFullYear() : (new Date()).getFullYear());
  month.value = (props.date ? props.range ? props.date[0].getMonth() : props.date.getMonth() : (new Date()).getMonth());
  init();
});

const setMonth = (newMonth) => {
  month.value = getMonthSmallNum(newMonth);
  isSelectMonth.value = false;
};

const setYear = (newYear) => {
  selectYear.value = newYear;
  isSelectMonth.value = true;
  isSelectYear.value = false;
};

</script>

<style scoped>

.calendar__wrapper {
  display: flex;
  flex-direction: column;
  width: 272px;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12), 0 12px 20px rgba(0, 0, 0, 0.14);
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  padding: 26px 12px 12px 12px;
  box-sizing: border-box;
  z-index: 100;
}

.calendar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.button__reset {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button__arrow {
  padding: 5px;
  border-radius: 6px;
  transition: background-color .1s ease;
}

.button__arrow:hover {
  background-color: rgba(188, 195, 208, 0.25);
}

.button__nav {
  font-family: "MTSCompactMedium", "MTS Sans", serif;
  font-size: 17px;
  border-radius: 6px;
  padding: 0 4px;
}

.button__nav:first-child{
  margin-right: 8px;
}

.hidden {
  visibility: hidden;
}

.button__nav:hover {
  background-color: rgba(188, 195, 208, 0.25);
}
</style>
