<template>
  <div
    ref="loader"
    class="loader"
  />
</template>

<script setup>

import { onMounted, ref } from 'vue';

const props = defineProps({
  color: {
    type: String,
    default: '#ff0032'
  },
  size: {
    type: String,
    default: 'm'
  }
});

const loader = ref(null);

const setSize = () => {
  switch (props.size) {
    case 's': return '1rem';
    case 'm': return '1.25rem';
    case 'i': return '2.75rem';
    default: return '1.5rem';
  }
};

onMounted(() => {
  loader.value.style.setProperty('--color', props.color);
  loader.value.style.setProperty('--size', setSize());
});

</script>

<style scoped>

.loader {
  --color: #ff0032;
  --size: 1.5rem;
  --spiner--background: conic-gradient(from 90deg, rgba(0, 0, 0, 0) 0 35deg, var(--color) 320deg, rgba(0, 0, 0, 0) 320deg 360deg) ;
  --spiner-width: var(--size);
  --spiner-height: var(--size);
  --spiner-padding:0.125rem;
  --spiner-mask:url("data:image/svg+xml,%3Csvg viewBox=%270 0 20 20%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Ccircle cx=%2710%27 cy=%2710%27 r=%278.5%27 fill=%27none%27 stroke=%27black%27 stroke-width=%273%27 stroke-dasharray=%2745%27 stroke-dashoffset=%270%27 stroke-linecap=%27round%27 /%3E%3C/svg%3E") center center no-repeat;
  width: var(--spiner-width);
  height: var(--spiner-height);
  padding: var(--spiner-padding);
  overflow: hidden;
  display: inline-block;
  border-radius: 50%;
}

.loader:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: var(--spiner--background);
  -webkit-mask: var(--spiner-mask);
  mask: var(--spiner-mask);
  animation: loaderRound 1s linear infinite;
  will-change: transform;
}

@keyframes loaderRound {
  0%{
    transform: rotateZ(0deg);
  }
  50%{
    transform: rotateZ(180deg);
  }
  100%{
    transform: rotateZ(360deg);
  }
}

</style>
