<template>
  <div
    class="banner__container"
    :class="[{'cursor-p': !attributes.tekst_knopki?.value && attributes.podrobneye?.value}]"
    :style="{'color': colorFonts.fontColor}"
    @click="!attributes.tekst_knopki?.value && attributes.podrobneye?.value ? clickBanner() : ()=>{}"
  >
    <h3
      v-if="attributes.zagolovok?.value"
      :class="['banner__title',{'banner__title-mt-auto': !!attributes.podrobneye?.value}]"
    >
      {{ attributes.zagolovok?.value }}
    </h3>
    <p
      v-if="attributes.soderzhaniye?.value"
      :class="['banner__description',{'banner__text-padding': !!!attributes.podrobneye?.value}]"
    >
      {{ attributes.soderzhaniye?.value }}
    </p>
    <a
      v-if="attributes.podrobneye?.value"
      class="banner__text-link"
      target="_blank"
      :style="{'color': colorFonts.linkColor}"
      :href="attributes.podrobneye?.value"
      @click="gtm.push_events({'event': 'vntStv', 'eventCategory': 'glavnaya', 'eventAction': 'link_click', 'eventLabel': 'podrobnee', 'buttonLocation': 'screen'})"
    >
      <div
        v-if="attributes.tekst_knopki?.value"
        class="banner__text-link-wrapper"
      >
        <p>
          {{ attributes.tekst_knopki?.value }}
        </p>
        <svg
          width="11"
          height="17"
          viewBox="0 0 11 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="banner__text-link-arrow"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.60486 8.5L1.37085 4.98848C0.616363 4.16925 0.676427 2.89152 1.50444 2.14668L2.42289 1.32048C3.23664 0.588471 4.48765 0.646565 5.23006 1.45084L9.86633 6.47347C10.9228 7.61796 10.9228 9.38204 9.86633 10.5265L5.23006 15.5492C4.48765 16.3534 3.23664 16.4115 2.42289 15.6795L1.50444 14.8533C0.676427 14.1085 0.616363 12.8307 1.37085 12.0115L4.60486 8.5Z"
            :fill="colorFonts.linkColor"
          />
        </svg>
      </div>
    </a>
  </div>
</template>

<script setup>

import { computed } from 'vue';
import { useWindowSize } from '@/hooks/window-size';
import { useGTM } from '@/hooks/gtm';
import { selectColor } from '@/utils/cmsHelpers';
const gtm = useGTM();

const props = defineProps({
  banner: {
    type: Object
  }
});

const attributes = props.banner?.attributeCategories.osnovnoy_banner.attributes;

const { windowSize } = useWindowSize();

const colorFonts = computed(() => {
  if (windowSize.value <= 767) {
    return {
      fontColor: selectColor(attributes.tsvet_teksta_telefon?.value.value),
      linkColor: selectColor(attributes.tsvet_knopki_telefon?.value.value)
    };
  }

  if (windowSize.value <= 1359) {
    return {
      fontColor: selectColor(attributes.tsvet_teksta_planshet?.value.value),
      linkColor: selectColor(attributes.tsvet_knopki_planshet?.value.value)
    };
  }

  return {
    fontColor: selectColor(attributes.tsvet_teksta?.value.value),
    linkColor: selectColor(attributes.tsvet_knopki?.value.value)
  };
});

const clickBanner = () => {
  window.open(attributes.podrobneye.value, '_blank');
};

</script>

<style scoped>
.banner__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  height: 180px;
  justify-content: center;
}
.banner__title {
  font-size: 28px;
  font-weight: 700;
  font-family: "MTS Sans", serif;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner__title-mt-auto,
.banner__text-link {
  margin-top: auto;
}
.banner__description {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  font-family: "MTS Text", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner__text-padding {
  padding: 0 125px;
}
.banner__text-link {
  font-size: 28px;
  font-weight: 700;
  font-family: "MTS Sans", serif;
  display: flex;
  align-items: center;
  width: fit-content;
}
.banner__text-link p {
  margin-right: 5px;
}
.banner__text-link svg {
  transition: transform .5s ease;
}
.banner__text-link:hover svg {
  transform: translateX(5px);
}

.banner__text-link-wrapper {
  display: flex;
  align-items: center;
}

.banner__text-link-arrow {
  padding-top: 3px;
}

@media (max-width: 1359px) {
  .banner__description {
    -webkit-line-clamp: 3;
  }
  .banner__container {
    height: 220px;
  }
}

@media (max-width: 768px) {
  .banner__title {
    -webkit-line-clamp: 2;
    font-size: 22px;
  }
  .banner__text-link {
    font-size: 20px;
  }
  .banner__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: "MTS Compact", sans-serif;
    -webkit-line-clamp: 4;
  }
  .banner__text-padding {
    padding: 0;
  }
  .banner__text-link-arrow {
    transform: scale(0.8);
    padding-top: 2px;
  }
}
</style>
