<template>
  <div class="page__content page__content_regular">
    <div class="page__nav">
      <div
        class="page__nav-btn-back"
        @click.prevent="$router.push({name: 'settings'})"
      />
    </div>
    <div class="blocks page__content-blocks">
      <div class="blocks__item">
        <h1 class="page__nav-title page__nav-title_margin">
          Документы
        </h1>
        <div class="documents__wrapper">
          <div
            v-if="userInfo?.balance.value <= 0"
            class="tertiary-banner"
          >
            При необходимости вы можете осуществить платеж <a
              class="link tertiary-banner-link"
              href=""
              @click.prevent="openPayWidget(userInfo)"
            >на странице оплаты </a>
          </div>
          <DocumentsListForSigned />
          <DocumentsListSigned />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DocumentsListForSigned from '@/components/settings/entities/DocumentsListForSigned';
import DocumentsListSigned from '@/components/settings/entities/DocumentsListSigned';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { openPayWidget } from '@/utils/openPayWidget';

const store = useStore();
const userInfo = computed(() => store.getters['user/userInfo']);
</script>

<style scoped>

.documents__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

</style>
