<template>
  <div :class="['popup', 'popup-desktop',{'popup-mobile': mobileFullView}]">
    <div
      :style="[{'width': `${widthValue.width}px`}, {'max-width': `${widthValue.maxWidth}px`}, {'width': widthValue.widthFull && '100%'}]"
      :class="['popup__inner', {'popup__inner-mobile': mobileFullView},{'not-padding': notPadding}]"
    >
      <div class="popup__header">
        <div
          v-if="viewClose"
          :class="['popup__close-btn',{'popup__close-btn-sq': mobileFullView},{'pos-16': notPadding}]"
          @click="$emit('close')"
        />
        <div
          v-if="title"
          class="popup__title"
        >
          {{ title }}
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue';

const props = defineProps({
  title: {
    type: String
  },
  viewClose: {
    type: Boolean,
    default: true
  },
  mobileFullView: {
    type: Boolean,
    default: false
  },
  notPadding: {
    type: Boolean,
    default: false
  },
  width: {
    type: Number
  },
  maxWidth: {
    type: Number
  },
  widthFull: {
    type: Boolean
  }
});

let widthValue = reactive({
  width: props.width,
  maxWidth: props.maxWidth,
  widthFull: props.widthFull
});

watch(() => [props.width, props.maxWidth, props.widthFull], newValue => {
  widthValue = {
    width: newValue.width,
    maxWidth: newValue.maxWidth,
    widthFull: newValue.widthFull
  };
});
</script>

<style scoped>

.not-padding {
  padding: 0;
}

.pos-16 {
  right: -48px;
  top: 0;
}

.popup__close-btn-sq {
  border-radius: 6px;
}

.popup-desktop {
  padding: 20px 60px;
}

@media (max-width: 767px) {
  .popup-desktop {
    padding: 20px 20px;
  }
  .popup-mobile {
    padding: 20px 0 0 0;
  }
  .pos-16 {
    top: 16px;
    right: 16px;
  }
  .popup__inner-mobile {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    animation: test .3s 1 ease;
  }

  @keyframes test {
    0%{
      transform: translateY(100%);
    }
    100%{
      transform: none;
    }
  }
}

</style>
