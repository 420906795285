import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {

  },
  mutations: {
    // setInvoices(state, invoices) {
    //   state.invoices = invoices;
    // },
    clear () {
      // state.invoices = null;
    }
  },
  actions: {
    async loadInvoices (_, payload) {
      try {
        const { data } = await axios.get('/invoices/prepayment', { params: payload });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async getInvoiceFile (_, invoice) {
      try {
        const { data } = await axios.get(invoice.url, { responseType: 'blob' });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async createInvoice (_, payload) {
      try {
        const { data } = await axios.post('/invoices/prepayment', { amount: payload.amount });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async logRedirectToPayment (_, url) {
      try {
        const { data } = await axios.post('/payments/payment', { url: url });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    invoices (state) {
      return state.invoices;
    }
  }
};
