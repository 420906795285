export const openPayWidget = (userInfo) => {
  document.getElementById('MTSPayWidget')?.remove();
  document.getElementById('mtsPayScript')?.remove();

  const mtsPayWidget = document.createElement('div');
  mtsPayWidget.id = 'MTSPayWidget';

  const script = document.createElement('script');
  script.id = 'mtsPayScript';
  script.src = 'https://pay.mts.ru/assets/js/web-sdk/v1/sdk.js';

  document.body.append(mtsPayWidget);
  document.head.append(script);

  script.onload = () => {
    const params = {
      scenarioType: 'refill',
      serviceToken: process.env.VUE_APP_SERVICE_TOKEN,
      phone: userInfo.isConvergent ? userInfo.mobileConvergent : '',
      bill: userInfo.personalAccount,
      environment: process.env.NODE_ENV === 'production' ? 'PROD' : ''
    };
    const widget = new MTSPay(params);
    widget.render('MTSPayWidget');
  };
};
