<template>
  <div
    v-if="loading"
    class="loader__wrapper"
  >
    <AppLoader
      class=""
      size="i"
    />
  </div>
  <app-page v-else>
    <div class="page__content page__content_regular page__content_noaside">
      <div class="page__content-inner">
        <div class="blocks page__content-blocks">
          <div
            v-if="!loading"
            class="blocks__item blocks__item_alt"
          >
            <h1 class="page__nav-title page__nav-title_margin">
              Дополнительные пакеты каналов
            </h1>
            <div
              v-if="needShowDropdowns"
              class="form blocks__item-inner-mb36"
            >
              <div class="form__input-group form__input-group_double400">
                <div class="form__input-field form__input-field_margin24">
                  <app-dropdown
                    v-if="selectedRoot"
                    v-model="selectedRoot"
                    label-name="Ресурс"
                    :available-items="availableRoots"
                  />
                </div>
                <div class="form__input-field form__input-field_margin24">
                  <app-dropdown
                    v-if="selectedCard"
                    v-model="selectedCard"
                    label-name="Оборудование"
                    :available-items="availableCards"
                  />
                </div>
              </div>
            </div>
            <div v-if="!loading && !packages.length">
              <p>Уважаемый абонент!</p>
              <p>На данном договоре нет подключенных услуг.</p>
              <p>По возникшим вопросам Вы можете обратиться в службу поддежки по номеру 8 800 250 2320.</p>
            </div>
            <div
              v-if="packages.length"
              class="card-set card-set_resource"
            >
              <pack-card
                v-for="pack in packages"
                :key="pack.tariff.id"
                :pack="pack"
                :card="selectedCard"
                :pack-changed-callback="packChangedCallback"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-page>
</template>

<script>
import { computed, onMounted, onBeforeUpdate, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import AppPage from '@/components/ui/AppPage.vue';
import AppDropdown from '@/components/ui/AppDropdown.vue';
import PackCard from '@/components/services/PackCard.vue';
import { useWindowSize } from '@/hooks/window-size';
import AppLoader from '@/components/ui/AppLoader.vue';

export default {
  components: { AppLoader, AppPage, AppDropdown, PackCard },
  setup () {
    const store = useStore();
    const route = useRoute();

    const packagesData = computed(() => store.getters['services/packagesData']);

    const availableRoots = ref([]);

    const selectedRoot = ref();
    const selectedCard = ref();

    const loading = ref(true);

    onMounted(async () => {
      try {
        loading.value = true;

        if (!packagesData.value) {
          await Promise.allSettled([
            store.dispatch('services/load'),
            store.dispatch('cms/getCMSLogosChannels')
          ]);
        }

        availableRootsRefresh();
        selectedRoot.value = availableRoots.value.find(r => r.id === Number(route.query.stv)) ?? availableRoots.value[0];
      } catch (e) {
      } finally {
        loading.value = false;
      }
    });

    const availableRootsRefresh = () => {
      availableRoots.value = packagesData.value.map(item => {
        return {
          id: item.resId,
          displayName: getDisplayName(item.name, item.resourceName),
          isMultiroom: item.isMultiroom,
          units: item.units.map(unit => {
            return {
              id: unit.resId,
              displayName: getDisplayName(unit.name, unit.cardNumber),
              ...unit
            };
          })
        };
      });
    };

    watch(selectedRoot, () => {
      if (!selectedRoot.value) {
        return;
      }
      resetSelectedCard();
    });

    const packChangedCallback = async () => {
      const currentRootId = selectedRoot.value.id;
      const currentCardId = selectedCard.value.id;

      await store.dispatch('services/load');
      availableRootsRefresh();

      selectedRoot.value.units = availableRoots.value.find(r => r.id === currentRootId).units;
      selectedCard.value = selectedRoot.value.units.find(unit => unit.id === currentCardId);
    };

    const availableCards = computed(() => {
      const mainCards = selectedRoot.value.units.filter(unit => unit.mainMultiroom);
      return mainCards.length ? mainCards : selectedRoot.value.units.sort((a, b) => b.id - a.id);
    });

    const packages = computed(() => {
      const result = [];
      // Тест-драйвы
      const testDrives = selectedCard.value?.availible.filter(pack => pack.tariff.testDrive.id !== 0);
      if (testDrives) {
        const testDrivesResult = testDrives.map(item => { item.type = 'testDrive'; return { ...item }; });
        result.push(...testDrivesResult);
      }
      // Подключенные пакеты
      const packs = selectedCard.value?.packages.filter(p => !p.isBase);
      if (packs) {
        const packsResult = packs.map(item => { item.type = 'pack'; return { ...item }; });
        result.push(...packsResult);
      }
      // Неподключенные(доступные) пакеты
      const availablePacks = selectedCard.value?.availible.filter(pack => pack.tariff.testDrive.id === 0);
      if (availablePacks) {
        const availablePacksResult = availablePacks.map(item => { item.type = 'availablePack'; return { ...item }; });
        result.push(...availablePacksResult);
      }
      return result;
    });

    function resetSelectedCard () {
      const mainCard = selectedRoot.value.units.find(unit => unit.mainMultiroom);
      const defaultCard = selectedRoot.value.units.find(u => u.id === Number(route.query.card)) ?? selectedRoot.value.units[0];
      selectedCard.value = selectedRoot.value.isMultiroom && mainCard ? mainCard : defaultCard;
    }
    const getDisplayName = (first, second) => {
      return first + ' - ' + second;
    };

    // Показываем тогда, когда:
    //  больше 1го корня
    //  или 1 корень и несколько приставок без мультирум
    const needShowDropdowns = computed(() => {
      if (availableRoots.value?.length > 1) {
        return true;
      }
      if (availableRoots.value?.length === 1 && availableRoots.value[0]?.units?.length > 1 && !availableRoots.value[0]?.isMultiroom) {
        return true;
      }
      return false;
    });

    const canNextClick = computed(() => {
      return offerCards.value.length > 1 && currentOfferIdx.value < offerCards.value.length - 1;
    });

    const canPrevClick = computed(() => {
      return offerCards.value.length > 1 && currentOfferIdx.value > 0;
    });

    const nextOffer = () => {
      if (canNextClick.value) {
        const marginLeft = +window.getComputedStyle(offerCards.value[1]?.$el.nextElementSibling).marginLeft.replace(/\D/g, '');
        offersRef.value.scrollLeft += offerCards.value[0]?.$el.nextElementSibling.getBoundingClientRect().width + marginLeft;
        currentOfferIdx.value += 1;
      }
    };

    const prevOffer = () => {
      if (canPrevClick.value) {
        const marginLeft = +window.getComputedStyle(offerCards.value[1]?.$el.nextElementSibling).marginLeft.replace(/\D/g, '');
        offersRef.value.scrollLeft -= offerCards.value[0]?.$el.nextElementSibling.getBoundingClientRect().width + marginLeft;
        currentOfferIdx.value -= 1;
      }
    };

    const offersRef = ref();
    const offerCards = ref([]);
    const currentOfferIdx = ref(0);

    onBeforeUpdate(() => {
      offerCards.value = [];
    });

    const { windowSize } = useWindowSize();

    watch(windowSize, () => {
      if (offersRef.value) {
        offersRef.value.scrollLeft = 0;
        currentOfferIdx.value = 0;
      }
    });

    return {
      packagesData,
      availableRoots,
      selectedRoot,
      availableCards,
      selectedCard,
      packages,
      needShowDropdowns,
      loading,
      packChangedCallback,
      prevOffer,
      nextOffer,
      canPrevClick,
      canNextClick,
      offersRef,
      offerCards
    };
  }
};
</script>

<style scoped>
.loader__wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
