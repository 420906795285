<template>
  <div class="toasts">
    <div class="toasts__item">
      <div :class="['toasts__item-message', `toasts__item-message_${type}`]">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Успешно'
    },
    type: {
      type: String,
      default: 'ok',
      validator (value) {
        return ['ok', 'error', 'warning'].includes(value);
      }
    }
  },
  setup () {
    return {

    };
  }

};
</script>
