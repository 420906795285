import { ref } from 'vue';

export function useConfirmDialog (params) {
  const showConfirmModal = ref(false);

  const onDialogSuccess = async () => {
    showConfirmModal.value = false;
    await params?.sucessCallback?.();
    params?.toast?.triggerToast('Успешно', 'ok');
  };

  const onDialogError = () => {
    showConfirmModal.value = false;
    params?.errorCallback?.();
    params?.toast?.triggerToast('Произошла ошибка', 'error');
  };

  const onDialogCancel = () => {
    showConfirmModal.value = false;
    params?.cancelCallback?.();
  };

  const showConfirmDialog = () => {
    showConfirmModal.value = true;
  };

  const cancelConfirmDialog = () => {
    showConfirmModal.value = false;
  };

  return { showConfirmModal, onDialogSuccess, onDialogError, onDialogCancel, showConfirmDialog, cancelConfirmDialog };
}
