<template>
  <teleport to="body">
    <div class="calendar__wrapper">
      <div class="calendar__header">
        <button
          class="button__reset calendar__header-title"
          @click="isSelectYear=!isSelectYear"
        >
          Выбор даты
        </button>
        <button
          class="button__reset calendar__header-close"
          @click="$emit('close')"
        >
          Закрыть
        </button>
      </div>

      <div class="calendar__container">
        <div v-if="!isSelectYear">
          <div
            v-for="month in months"
            :key="month"
            class="calendar"
          >
            <div class="calendar__nav">
              <button class="button__reset calendar__mont-year">
                {{ month }}
              </button>
              <button class="button__reset calendar__mont-year">
                {{ selectYear }}
              </button>
            </div>
            <DayWeek />
            <Month
              :select-date="selectDate"
              :year="selectYear"
              :month="month"
              :range="props.range"
              :min="min"
              :max="max"
              @selectDay="selectDay"
              @setTop="setTop"
            />
          </div>
        </div>
        <SelectYear
          v-else
          :year="(new Date()).getFullYear()"
          :select-date="selectDate"
          @setYear="setYear"
        />
      </div>

      <div class="calendar__footer">
        <div class="calendar__footer-btns">
          <button
            class="button__reset button__footer button__reset-date"
            @click="$emit('close')"
          >
            Сбросить
          </button>
          <button
            class="button__reset button__footer button__apply"
            :disabled="!isApply()"
            @click="dateApply"
          >
            Применить
          </button>
        </div>
        <p
          v-if="getNumbersOfDays()"
          class="calendar__footer-range-day"
        >
          {{ getNumbersOfDays() }}
        </p>
      </div>
    </div>
  </teleport>
</template>

<script setup>
import DayWeek from '@/components/ui/AppDatePickerMobile/DayWeek';
import Month from '@/components/ui/AppDatePickerMobile/Month';
import { onMounted, onUnmounted, ref } from 'vue';
import SelectYear from '@/components/ui/AppDatePickerMobile/SelectYear';
import { getDayTitle } from '@/utils/getDayTitle';
import { diffInDays } from '@/utils/helpers';

const emit = defineEmits(['dateApply', 'close']);

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

const props = defineProps({
  date: [Array, Date],
  range: Boolean,
  min: {
    type: Date,
    required: false
  },
  max: {
    type: Date,
    required: false
  }
});

const isSelectYear = ref(false);
const selectYear = ref(props.date ? props.range ? props.date[0].getFullYear() : props.date.getFullYear() : (new Date()).getFullYear());

const isFirstActive = ref(props.date ? props.range ? !!props.date[0] : !!props.date : false);
const isSecondActive = ref(props.date ? props.range ? !!props.date[1] : !!props.date : false);

const isDate = () => {
  if (props.range && props.date) {
    return [props.date[0], props.date[1]];
  }
  if (props.range && !props.date) {
    return [];
  }
  if (!props.range && props.date) {
    return [props.date];
  }
  if (!props.range && !props.date) {
    return [];
  }
};

const selectDate = ref(isDate());

const top = ref(0);

const selectDay = (date) => {
  if (!props.range) {
    selectDate.value[0] = date;
    return;
  }
  if (isFirstActive.value && isSecondActive.value) {
    isFirstActive.value = false;
    isSecondActive.value = false;
    selectDate.value[1] = null;
    selectDate.value[0] = date;
  }
  if (!isFirstActive.value) {
    selectDate.value[0] = date;
    isFirstActive.value = true;
  } else {
    selectDate.value[1] = date;
    isSecondActive.value = true;

    selectDate.value.sort((a, b) => new Date(a) - new Date(b));
  }
};

const dateApply = () => {
  emit('dateApply', !props.range ? selectDate.value[0] : selectDate.value);
};

const setYear = (newYear) => {
  selectYear.value = newYear;
  isSelectYear.value = false;
};

const getNumbersOfDays = () => {
  if (!selectDate.value[0] || !selectDate.value[1]) return '';
  return getDayTitle(diffInDays(selectDate.value[0], selectDate.value[1]) + 1);
};

const setTop = (newTop) => {
  top.value = newTop;
  document.querySelector('.calendar__container').scrollTo(0, top.value - 300);
};

const isApply = () => {
  if (props.range && (!selectDate.value[0] || !selectDate.value[1])) {
    return false;
  }
  if (!props.range && !selectDate.value[0]) {
    return false;
  }
  return true;
};

onMounted(() => {
  document.querySelector('.page__footer').style.display = 'none';
  document.querySelector('.page__footer-bottom').style.display = 'none';
  document.querySelector('.calendar__container').scrollTo(0, top.value - 300);
});

onUnmounted(() => {
  document.querySelector('.page__footer').style.display = '';
  document.querySelector('.page__footer-bottom').style.display = '';
});

</script>

<style scoped>

.button__reset {
  border: none;
  padding: 0;
  background-color: transparent;
}

.calendar__container {
  padding: 78px 20px;
  overflow-y: scroll;
}

.calendar__wrapper{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-width: 360px;
  background-color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: start .5s 1 ease;
}

@keyframes start {
  from {
    transform:translateY(100%);
  }
  to {
    transform: none;
  }
}

.calendar__header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.08);
  z-index: 10;
}

.calendar__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-shadow: 0 -2px 24px rgba(0, 0, 0, 0.08), 0 -8px 24px rgba(0, 0, 0, 0.1);
  z-index: 10;
  flex-direction: column;
}

.calendar__footer-btns {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.calendar__footer-range-day {
  font-size: 14px;
  line-height: 20px;
  color: #626C77;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
}

.button__footer {
  border-radius: 6px;
  padding: 6px;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
}

.button__reset-date {
  color: #1D2023;
  flex: 1 1 auto;
  background-color: #F2F3F7;
}

.button__apply {
  background-color: #FF0032;
  color: #fff;
  flex: 1 1 auto;
  transition-property: background-color, color;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.button__apply:disabled {
  background-color: #ebecf1;
  color: #969fa8;
}

.calendar__header-title {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
}

.calendar__header-close {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #007CFF;
  position: absolute;
  right: 20px;
}

.calendar__nav {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}

.calendar__mont-year {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #1D2023;
  font-family: "MTSCompactMedium", "MTS Sans", serif;
}

.calendar {
  margin-bottom: 26px;
}

</style>
