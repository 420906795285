<template>
  <div class="tariffchange-page">
    <div class="page__container tarifchange__container-up">
      <AppBreadcrumbs />
      <div class="page__nav">
        <div
          class="page__nav-btn-back"
          @click.prevent="$router.go(-1)"
        />
      </div>
      <form
        v-if="tariff"
        class="form form__card"
        @submit.prevent="showConfirmDialog"
      >
        <p class="para form__el-secondary-14">
          Новый тарифный план
        </p>
        <div class="page__nav-title page__nav-title_margin form__el-mt8">
          {{ tariff.name }}
        </div>
        <div
          v-if="showResName"
          class="form__el-mt24"
        >
          <p class="para form__el-header-17">
            Ресурс
          </p>
          <p class="para form__el-secondary-14 form__el-mt4">
            {{ resName }} – Спутниковое ТВ
          </p>
        </div>
        <div class="form__el-mt24 form__el-grid-2-fluid-min-content">
          <p class="para form__el-header-17">
            Абонентская плата
          </p>
          <div class="service-rate">
            <div class="service-rate__value-box">
              <div class="service-rate__value">
                {{ tariff.discount.price }} ₽/{{ tariffPeriodFormatter(tariff.period, tariff.periodUnit) }}
              </div>
              <div
                v-if="tariff.discount.price < tariff.price"
                class="service-rate__value-old"
              >
                {{ tariff.price }} ₽
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="isSaleOnTariff(tariff).isSale"
          class="para form__el-secondary-14 next__price"
        >
          {{ isSaleOnTariff(tariff).saleValue }}
        </p>
        <div class="form__el-mt24 form__el-grid-2-fluid-min-content">
          <p class="para form__el-header-17">
            Переход на тариф
          </p>
          <div class="service-rate">
            <div class="service-rate__value-box">
              <div class="service-rate__value">
                {{ changeCost?`${changeCost} ₽`:'Бесплатно' }}
              </div>
            </div>
          </div>
        </div>
        <div class="form__el-mt24 form__el-grid-2-fluid-min-content tariffchange-desktop-elements">
          <p class="para form__el-header-17">
            Итого к оплате
          </p>
          <div class="service-rate">
            <div class="service-rate__value-box">
              <div class="service-rate__value">
                {{ tariff.discount.price + changeCost }} ₽
              </div>
            </div>
            <div class="service-rate__description" />
          </div>
        </div>
        <div class="form__el-mt24 tariffchange-desktop-elements">
          <button class="form__submit button button_primary button_fluid">
            Сменить тариф
          </button>
        </div>
      </form>
    </div>
    <div
      v-if="tariff"
      class="page__container flex-fill tarifchange__container-bottom"
    >
      <div class="form__el-mt24 form__el-grid-2-fluid-min-content">
        <p class="para form__el-header-17">
          Итого к оплате
        </p>
        <div class="service-rate">
          <div class="service-rate__value-box">
            <div class="service-rate__value">
              {{ tariff.discount.price + changeCost }} ₽
            </div>
          </div>
          <div class="service-rate__description" />
        </div>
      </div>
      <div class="form__el-mt24">
        <button
          class="form__submit button button_primary button_fluid"
          @click="showConfirmDialog"
        >
          Сменить тариф
        </button>
      </div>
    </div>
  </div>
  <teleport to="body">
    <AppModal
      v-if="showConfirmModal"
      @close="!isSubmit? cancelConfirmDialog(): onDialogSuccess(); isSubmit = false"
    >
      <TaskCreateRate
        :res-id="resId"
        :tariff-id="tpId"
        :tariff="tariff"
        @ok="onDialogSuccess"
        @cancel="onDialogCancel"
        @close="cancelConfirmDialog"
        @error="onDialogError"
        @onSubmit="isSubmit = true"
      />
    </AppModal>
  </teleport>
  <AppToast
    v-if="showToast"
    :text="'Не удалось сменить тарифный план'"
    :type="toastType"
  />
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useConfirmDialog } from '@/hooks/confirm-dialog';
import { dateFormatter, getFirstDateOnDate, getNewDate, tariffPeriodFormatter } from '@/utils/helpers';
import { isSaleOnTariff } from '@/components/tariffs/helpers';
import { useGTM } from '@/hooks/gtm';

import AppBreadcrumbs from '@/components/ui/AppBreadcrumbs';
import AppModal from '@/components/ui/AppModal';
import TaskCreateRate from '@/components/tariffs/modals/TaskCreateRate';
import AppToast from '@/components/ui/AppToast';
import { useToast } from '@/hooks/toast';
import { openPayWidget } from '@/utils/openPayWidget';

export default {
  components: { AppToast, TaskCreateRate, AppModal, AppBreadcrumbs },
  props: {
    resId: {
      required: true
    },
    tpId: {
      required: true
    }
  },
  setup (props) {
    const store = useStore();
    const router = useRouter();

    const gtm = useGTM();

    const resources = computed(() => store.getters['resource/resources']);
    const tariffs = computed(() => store.getters['tariffs/availableTariffs']);
    const showResName = computed(() => resources.value?.length > 1);
    const userInfo = computed(() => store.getters['user/userInfo']);

    const tariff = ref();
    const resName = ref();
    const changeCost = ref();
    const isSubmit = ref(false);

    onMounted(async () => {
      try {
        if (!resources.value) {
          await store.dispatch('resource/load');
        }
        resName.value = resources.value.find((res) => res.resId.toString() === props.resId)?.resName;

        if (!tariffs.value || tariffs.value?.resId !== props.resId) {
          const payload = { resId: props.resId };
          await store.dispatch('tariffs/loadAvailable', payload);
        }

        changeCost.value = await store.dispatch('tariffs/getPaidChange', { resId: props.resId, tarplanId: props.tpId });
        tariff.value = tariffs.value.data.find((tariff) => tariff.id.toString() === props.tpId);
      } catch (e) {}
    });

    const toast = useToast();
    const dialog = useConfirmDialog({
      sucessCallback: () => {
        gtm.push_events({ event: 'vntTarif', eventCategory: 'tarif', eventAction: 'button_click', eventLabel: 'na_glavnuu' });
        router.push({ name: 'home' });
      },
      cancelCallback: () => {
        gtm.push_events({ event: 'vntTarif', eventCategory: 'tarif', eventAction: 'button_click', eventLabel: 'popolnit_balans' });
        router.push({ name: 'home' });
        openPayWidget(userInfo.value);
      },
      toast
    });

    return { tariffPeriodFormatter, tariff, changeCost, resName, dateFormatter, getFirstDateOnDate, getNewDate, ...dialog, showResName, ...toast, isSubmit, isSaleOnTariff };
  }

};
</script>

<style scoped>

.tariffchange-page {
  flex: 1 1 auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.tarifchange__container-bottom {
  display: none;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  flex: 1 1 auto;
  padding-bottom: 24px;
}

.next__price {
  margin-top: 2px;
}

@media (max-width: 959px) {
  .tarifchange__container-up {
    margin-bottom: 12px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    padding-bottom: 24px;
  }
  .tarifchange__container-bottom {
    display: block;
  }
  .tariffchange-page {
    background-color: #F2F3F7;
  }
  .tariffchange-desktop-elements {
    display: none;
  }
}

</style>
