import axios from 'axios';
import store from '../store';

const mainAxios = axios.create({
  baseURL: process.env.VUE_APP_MAIN_API
});

mainAxios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters['auth/token']}`;
  return config;
});

mainAxios.interceptors.response.use(null, async (error) => {
  if (error.response.status === 401) {
    await store.dispatch('auth/logout');
    window.location.replace(`https://login.mts.ru/amserver/UI/Logout?goto=${process.env.VUE_APP_URI}`);
  }

  return Promise.reject(error);
});

export default mainAxios;
