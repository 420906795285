import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {
    targetGroup: []
  },
  mutations: {
    setUser (state, userInfo) {
      state.user = userInfo;
    },
    clear (state) {
      state.user = null;
    },
    updateContactAndConfirm (state, newContactObj) {
      if (newContactObj.contactType === 'mobile') {
        state.user.mobile = newContactObj.contact;
        state.user.mobileCnfd = true;
      } else if (newContactObj.contactType === 'email') {
        state.user.email = newContactObj.contact;
        state.user.emailCnfd = true;
      }
    },
    setBalance (state, balance) {
      state.user.balance = balance;
    },
    setTargetGroup (state, group) {
      state.targetGroup = group;
    }
  },
  actions: {
    async load ({ commit }) {
      try {
        const { data } = await axios.get('/account');
        commit('setUser', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data, name: 'account' };
      }
    },
    async updateBalance ({ commit }) {
      try {
        const { data } = await axios.get('/account/balance');
        commit('setBalance', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async getTargetGroup ({ commit }) {
      try {
        const { data } = await axios('/account/targetGroups');
        commit('setTargetGroup', data);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    userInfo (state) {
      return state.user;
    },
    getFullName (state) {
      return `${state.user.familyName} ${state.user.firstName}${state.user.fatherName ? ' ' + state.user.fatherName : ''}`;
    },
    recommendedAmount (state) {
      return state.user ? Math.ceil(Math.max(0, state.user.balance.feeDiscount - state.user.balance.value)) : null;
    },
    needShowConvergent (state) {
      return state.user ? state.user.isConvergent && !!state.user.mobileConvergent : false;
    },
    targetGroup (state) {
      return state.targetGroup;
    }
  }
};
