(function(){ try {var elementStyle = document.createElement('style'); elementStyle.appendChild(document.createTextNode(".swipe-modal-takumaru-vue-swipe-modal{position:fixed;scrollbar-width:none;z-index:100}.swipe-modal-takumaru-vue-swipe-modal .modal-background{position:fixed;z-index:11}.swipe-modal-takumaru-vue-swipe-modal .modal-contents{position:fixed;z-index:12;max-height:100vh;left:50%;transform:translate(-50%) translateY(0);overflow-y:scroll;-ms-overflow-style:none;scrollbar-width:none;backface-visibility:hidden;-webkit-backface-visibility:hidden;filter:drop-shadow(0px 16px 40px rgba(0,37,80,.2))}.swipe-modal-takumaru-vue-swipe-modal .modal-contents::-webkit-scrollbar{width:0px}.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip-wrapper{z-index:12;display:flex;justify-items:center;align-items:center;justify-content:center;align-content:center;position:relative;top:0px;height:4px;width:100%;padding-top:8px;padding-bottom:8px;cursor:s-resize}.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip{--tip-color: #c8c8c8;width:40px;height:100%;border-radius:4px;background-color:var(--tip-color)}")); document.head.appendChild(elementStyle);} catch(e) {console.error('vite-plugin-css-injected-by-js', e);} })();import { getCurrentInstance, onMounted, nextTick, ref, getCurrentScope, onScopeDispose, unref, computed, watch, isVue2, install, defineComponent, toRefs, h } from "vue-demi";
var _a;
const isClient = typeof window !== "undefined";
const isDef = (val) => typeof val !== "undefined";
const isFunction = (val) => typeof val === "function";
const isNumber = (val) => typeof val === "number";
const clamp = (n, min, max) => Math.min(max, Math.max(min, n));
const noop = () => {
};
isClient && ((_a = window == null ? void 0 : window.navigator) == null ? void 0 : _a.userAgent) && /iP(ad|hone|od)/.test(window.navigator.userAgent);
function identity(arg) {
  return arg;
}
function tryOnScopeDispose(fn) {
  if (getCurrentScope()) {
    onScopeDispose(fn);
    return true;
  }
  return false;
}
function tryOnMounted(fn, sync = true) {
  if (getCurrentInstance())
    onMounted(fn);
  else if (sync)
    fn();
  else
    nextTick(fn);
}
function useTimeoutFn(cb, interval, options = {}) {
  const {
    immediate = true
  } = options;
  const isPending = ref(false);
  let timer = null;
  function clear() {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  }
  function stop() {
    isPending.value = false;
    clear();
  }
  function start(...args) {
    clear();
    isPending.value = true;
    timer = setTimeout(() => {
      isPending.value = false;
      timer = null;
      cb(...args);
    }, unref(interval));
  }
  if (immediate) {
    isPending.value = true;
    if (isClient)
      start();
  }
  tryOnScopeDispose(stop);
  return {
    isPending,
    start,
    stop
  };
}
function unrefElement(elRef) {
  var _a2;
  const plain = unref(elRef);
  return (_a2 = plain == null ? void 0 : plain.$el) != null ? _a2 : plain;
}
const defaultWindow = isClient ? window : void 0;
isClient ? window.document : void 0;
isClient ? window.navigator : void 0;
isClient ? window.location : void 0;
const _global = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
const globalKey = "__vueuse_ssr_handlers__";
_global[globalKey] = _global[globalKey] || {};
_global[globalKey];
function useCssVar(prop, target, { window: window2 = defaultWindow, initialValue = "" } = {}) {
  const variable = ref(initialValue);
  const elRef = computed(() => {
    var _a2;
    return unrefElement(target) || ((_a2 = window2 == null ? void 0 : window2.document) == null ? void 0 : _a2.documentElement);
  });
  watch([elRef, () => unref(prop)], ([el, prop2]) => {
    var _a2;
    if (el && window2) {
      const value = (_a2 = window2.getComputedStyle(el).getPropertyValue(prop2)) == null ? void 0 : _a2.trim();
      variable.value = value || initialValue;
    }
  }, { immediate: true });
  watch(variable, (val) => {
    var _a2;
    if ((_a2 = elRef.value) == null ? void 0 : _a2.style)
      elRef.value.style.setProperty(unref(prop), val);
  });
  return variable;
}
function useRafFn(fn, options = {}) {
  const {
    immediate = true,
    window: window2 = defaultWindow
  } = options;
  const isActive = ref(false);
  let rafId = null;
  function loop() {
    if (!isActive.value || !window2)
      return;
    fn();
    rafId = window2.requestAnimationFrame(loop);
  }
  function resume() {
    if (!isActive.value && window2) {
      isActive.value = true;
      loop();
    }
  }
  function pause() {
    isActive.value = false;
    if (rafId != null && window2) {
      window2.cancelAnimationFrame(rafId);
      rafId = null;
    }
  }
  if (immediate)
    resume();
  tryOnScopeDispose(pause);
  return {
    isActive,
    pause,
    resume
  };
}
var SwipeDirection;
(function(SwipeDirection2) {
  SwipeDirection2["UP"] = "UP";
  SwipeDirection2["RIGHT"] = "RIGHT";
  SwipeDirection2["DOWN"] = "DOWN";
  SwipeDirection2["LEFT"] = "LEFT";
  SwipeDirection2["NONE"] = "NONE";
})(SwipeDirection || (SwipeDirection = {}));
function createEasingFunction([p0, p1, p2, p3]) {
  const a = (a1, a2) => 1 - 3 * a2 + 3 * a1;
  const b = (a1, a2) => 3 * a2 - 6 * a1;
  const c = (a1) => 3 * a1;
  const calcBezier = (t, a1, a2) => ((a(a1, a2) * t + b(a1, a2)) * t + c(a1)) * t;
  const getSlope = (t, a1, a2) => 3 * a(a1, a2) * t * t + 2 * b(a1, a2) * t + c(a1);
  const getTforX = (x) => {
    let aGuessT = x;
    for (let i = 0; i < 4; ++i) {
      const currentSlope = getSlope(aGuessT, p0, p2);
      if (currentSlope === 0)
        return aGuessT;
      const currentX = calcBezier(aGuessT, p0, p2) - x;
      aGuessT -= currentX / currentSlope;
    }
    return aGuessT;
  };
  return (x) => p0 === p1 && p2 === p3 ? x : calcBezier(getTforX(x), p1, p3);
}
function useTransition(source, options = {}) {
  const {
    delay = 0,
    disabled = false,
    duration = 1e3,
    onFinished = noop,
    onStarted = noop,
    transition = identity
  } = options;
  const currentTransition = computed(() => {
    const t = unref(transition);
    return isFunction(t) ? t : createEasingFunction(t);
  });
  const sourceValue = computed(() => {
    const s = unref(source);
    return isNumber(s) ? s : s.map(unref);
  });
  const sourceVector = computed(() => isNumber(sourceValue.value) ? [sourceValue.value] : sourceValue.value);
  const outputVector = ref(sourceVector.value.slice(0));
  let currentDuration;
  let diffVector;
  let endAt;
  let startAt;
  let startVector;
  const { resume, pause } = useRafFn(() => {
    const now = Date.now();
    const progress = clamp(1 - (endAt - now) / currentDuration, 0, 1);
    outputVector.value = startVector.map((val, i) => {
      var _a2;
      return val + ((_a2 = diffVector[i]) != null ? _a2 : 0) * currentTransition.value(progress);
    });
    if (progress >= 1) {
      pause();
      onFinished();
    }
  }, { immediate: false });
  const start = () => {
    pause();
    currentDuration = unref(duration);
    diffVector = outputVector.value.map((n, i) => {
      var _a2, _b;
      return ((_a2 = sourceVector.value[i]) != null ? _a2 : 0) - ((_b = outputVector.value[i]) != null ? _b : 0);
    });
    startVector = outputVector.value.slice(0);
    startAt = Date.now();
    endAt = startAt + currentDuration;
    resume();
    onStarted();
  };
  const timeout = useTimeoutFn(start, delay, { immediate: false });
  watch(sourceVector, () => {
    if (unref(disabled)) {
      outputVector.value = sourceVector.value.slice(0);
    } else {
      if (unref(delay) <= 0)
        start();
      else
        timeout.start();
    }
  }, { deep: true });
  return computed(() => {
    const targetVector = unref(disabled) ? sourceVector : outputVector;
    return isNumber(sourceValue.value) ? targetVector.value[0] : targetVector.value;
  });
}
function useVModel(props, key, emit, options = {}) {
  var _a2, _b, _c, _d, _e;
  const {
    passive = false,
    eventName,
    deep = false,
    defaultValue
  } = options;
  const vm = getCurrentInstance();
  const _emit = emit || (vm == null ? void 0 : vm.emit) || ((_a2 = vm == null ? void 0 : vm.$emit) == null ? void 0 : _a2.bind(vm)) || ((_c = (_b = vm == null ? void 0 : vm.proxy) == null ? void 0 : _b.$emit) == null ? void 0 : _c.bind(vm == null ? void 0 : vm.proxy));
  let event = eventName;
  if (!key) {
    if (isVue2) {
      const modelOptions = (_e = (_d = vm == null ? void 0 : vm.proxy) == null ? void 0 : _d.$options) == null ? void 0 : _e.model;
      key = (modelOptions == null ? void 0 : modelOptions.value) || "value";
      if (!eventName)
        event = (modelOptions == null ? void 0 : modelOptions.event) || "input";
    } else {
      key = "modelValue";
    }
  }
  event = eventName || event || `update:${key.toString()}`;
  const getValue = () => isDef(props[key]) ? props[key] : defaultValue;
  if (passive) {
    const proxy = ref(getValue());
    watch(() => props[key], (v) => proxy.value = v);
    watch(proxy, (v) => {
      if (v !== props[key] || deep)
        _emit(event, v);
    }, {
      deep
    });
    return proxy;
  } else {
    return computed({
      get() {
        return getValue();
      },
      set(value) {
        _emit(event, value);
      }
    });
  }
}
const useTouchEvent = () => {
  const touchPosition = ref({
    isTouch: false,
    touchStart: 0,
    touchDistance: 0,
    touchX: 0,
    touchY: 0
  });
  const initTouchPosition = () => {
    touchPosition.value = {
      isTouch: false,
      touchStart: 0,
      touchDistance: 0,
      touchX: 0,
      touchY: 0
    };
  };
  const touchStart = (payload) => {
    touchPosition.value.isTouch = true;
    touchPosition.value.touchStart = payload.touches[0].pageY;
  };
  const touchMove = (payload) => {
    if (touchPosition.value.isTouch) {
      touchPosition.value.touchY = payload.touches[0].pageY;
      touchPosition.value.touchDistance = touchPosition.value.touchStart - touchPosition.value.touchY;
      if (touchPosition.value.touchDistance > 0) {
        touchPosition.value.touchDistance = 0;
      }
    }
  };
  const touchEnd = () => {
    touchPosition.value.isTouch = false;
  };
  return {
    touchPosition,
    initTouchPosition,
    touchStart,
    touchMove,
    touchEnd
  };
};
function useMouseEvent() {
  const mousePosition = ref({
    isMouseDown: false,
    downStart: 0,
    mouseDistance: 0,
    mouseX: 0,
    mouseY: 0
  });
  const initMousePosition = () => {
    mousePosition.value = {
      isMouseDown: false,
      downStart: 0,
      mouseDistance: 0,
      mouseX: 0,
      mouseY: 0
    };
  };
  const mouseDown = (payload) => {
    mousePosition.value.isMouseDown = true;
    mousePosition.value.downStart = payload.pageY;
  };
  const mouseMove = (payload) => {
    if (mousePosition.value.isMouseDown) {
      mousePosition.value.mouseY = payload.pageY;
      mousePosition.value.mouseDistance = mousePosition.value.downStart - mousePosition.value.mouseY;
      if (mousePosition.value.mouseDistance > 0) {
        mousePosition.value.mouseDistance = 0;
      }
    }
  };
  const mouseUp = () => {
    mousePosition.value.isMouseDown = false;
  };
  return {
    mousePosition,
    initMousePosition,
    mouseDown,
    mouseMove,
    mouseUp
  };
}
const toPixel = (size, element) => {
  const pxVal = size.indexOf("px");
  const vhVal = size.indexOf("vh");
  const vwVal = size.indexOf("vw");
  const vminVal = size.indexOf("vmin");
  const vmaxVal = size.indexOf("vmax");
  const percentVal = size.indexOf("%");
  if (pxVal > 0) {
    const px = Number(size.slice(0, pxVal));
    return px;
  } else if (vhVal > 0) {
    const vh = Number(size.slice(0, vhVal));
    const px = window.innerHeight * (vh / 100);
    return px;
  } else if (vwVal > 0) {
    const vw = Number(size.slice(0, vwVal));
    const px = window.innerWidth * (vw / 100);
    return px;
  } else if (vminVal > 0) {
    const vmin = Number(size.slice(0, vminVal));
    const px = window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight * (vmin / 100);
    return px;
  } else if (vmaxVal > 0) {
    const vmax = Number(size.slice(0, vmaxVal));
    const px = window.innerWidth < window.innerHeight ? window.innerHeight : window.innerWidth * (vmax / 100);
    return px;
  } else if (percentVal > 0) {
    let px = 0;
    tryOnMounted(() => {
      var _a2, _b;
      const parentHeight = ((_b = (_a2 = element == null ? void 0 : element.value) == null ? void 0 : _a2.parentElement) == null ? void 0 : _b.clientHeight) || document.documentElement.clientHeight;
      const percent = Number(size.slice(0, percentVal));
      px = parentHeight * (percent / 100);
    });
    return px ? px : document.documentElement.clientHeight;
  } else {
    return 0;
  }
};
var swipeModal$1 = "";
install();
var swipeModal = defineComponent({
  name: "swipeModal",
  model: {
    prop: "modelValue",
    event: "update:modelValue"
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "#80808080"
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    noTip: {
      type: Boolean,
      default: false
    },
    contentsWidth: {
      type: String,
      default: "100%"
    },
    contentsHeight: {
      type: String,
      default: "30vh"
    },
    borderTopRadius: {
      type: String,
      default: null
    },
    borderTopLeftRadius: {
      type: String,
      default: "0px"
    },
    borderTopRightRadius: {
      type: String,
      default: "0px"
    },
    contentsColor: {
      type: String,
      default: "white"
    },
    tipColor: {
      type: String,
      default: "#c8c8c8"
    },
    darkContentsColor: {
      type: String,
      default: "#1E1E1E"
    }
  },
  emits: ["update:modelValue", "open", "close"],
  setup(props, context) {
    const {
      mousePosition,
      initMousePosition,
      mouseDown,
      mouseMove,
      mouseUp
    } = useMouseEvent();
    const {
      touchPosition,
      initTouchPosition,
      touchStart,
      touchMove,
      touchEnd
    } = useTouchEvent();
    const modal = useVModel(props, "modelValue", context.emit);
    const propsRef = toRefs(props);
    const swipeModal2 = ref();
    const contentsBottomPositionTransitionDuration = ref(280);
    const el = ref(null);
    const tipColor = useCssVar("--tip-color", el);
    tipColor.value = propsRef.tipColor.value;
    const backgroundColor = ref([
      parseInt(propsRef.backgroundColor.value.slice(1, 3), 16),
      parseInt(propsRef.backgroundColor.value.slice(3, 5), 16),
      parseInt(propsRef.backgroundColor.value.slice(5, 7), 16),
      0
    ]);
    const backgroundColorEnterTransition = useTransition(backgroundColor, {
      duration: 250,
      transition: [0.25, 0.8, 0.25, 1]
    });
    const color = computed(() => {
      const [r, g, b, a] = backgroundColorEnterTransition.value;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    });
    const contentsBottomPosition = ref(-1 * toPixel(propsRef.contentsHeight.value, swipeModal2));
    const contentsBottomPositionTransition = useTransition(contentsBottomPosition, {
      duration: contentsBottomPositionTransitionDuration.value,
      transition: [0.25, 0.8, 0.25, 1]
    });
    const contentsBottomDistance = computed(() => {
      const distance = contentsBottomPositionTransition.value + (Math.abs(touchPosition.value.touchDistance) > Math.abs(mousePosition.value.mouseDistance) ? touchPosition.value.touchDistance : mousePosition.value.mouseDistance);
      return distance;
    });
    const init = () => {
      document.documentElement.style.overflowY = "auto";
      modal.value = false;
      queueMicrotask(()=>{
        if(document.querySelector('.modal-contents')) document.documentElement.style.overflowY = "hidden";
      })
    };
    const open = () => {
      context.emit("open");
      document.documentElement.style.overflowY = "hidden";
      contentsBottomPosition.value = 0;
      backgroundColor.value = [
        parseInt(propsRef.backgroundColor.value.slice(1, 3), 16),
        parseInt(propsRef.backgroundColor.value.slice(3, 5), 16),
        parseInt(propsRef.backgroundColor.value.slice(5, 7), 16),
        parseInt(propsRef.backgroundColor.value.slice(7, 9), 16) / 255
      ];
    };
    const close = () => {
      contentsBottomPosition.value = -1 * toPixel(propsRef.contentsHeight.value, swipeModal2) - (Math.abs(touchPosition.value.touchDistance) > Math.abs(mousePosition.value.mouseDistance) ? touchPosition.value.touchDistance : mousePosition.value.mouseDistance);
      backgroundColor.value = [
        parseInt(propsRef.backgroundColor.value.slice(1, 3), 16),
        parseInt(propsRef.backgroundColor.value.slice(3, 5), 16),
        parseInt(propsRef.backgroundColor.value.slice(5, 7), 16),
        0
      ];
      setTimeout(() => {
        initMousePosition();
        initTouchPosition();
        init();
        context.emit("close");
      }, contentsBottomPositionTransitionDuration.value);
    };
    const onTouchEnd = () => {
      touchEnd();
      if (-1 * touchPosition.value.touchDistance > toPixel(propsRef.contentsHeight.value, swipeModal2) / 8) {
        close();
      } else {
        touchPosition.value.touchDistance = 0;
      }
    };
    const onMouseUp = () => {
      mouseUp();
      if (-1 * mousePosition.value.mouseDistance > toPixel(propsRef.contentsHeight.value, swipeModal2) / 8) {
        close();
      } else {
        mousePosition.value.mouseDistance = 0;
      }
    };
    watch(modal, (newVal, oldVal) => {
      if (modal.value) {
        open();
      }
    });
    return () => {
      var _a2, _b;
      return h("div", {
        class: "swipe-modal-takumaru-vue-swipe-modal",
        on: {
          mousemove: mouseMove,
          mouseup: onMouseUp
        },
        onMousemove: mouseMove,
        onMouseup: onMouseUp
      }, [
        propsRef.modelValue.value ? h("div", {
          class: "modal-background",
          style: {
            backgroundColor: color.value
          },
          on: {
            onMouseup: () => propsRef.persistent.value ? () => null : close(),
            click: () => propsRef.persistent.value ? () => null : close()
          },
          onMouseup: () => {
            propsRef.persistent.value ? () => null : close();
          },
          onClick: () => {
            propsRef.persistent.value ? () => null : close();
          }
        }) : null,
        propsRef.modelValue.value ? h("div", {
          ref: swipeModal2,
          class: "modal-contents",
          style: {
            width: propsRef.contentsWidth.value,
            height: propsRef.contentsHeight.value,
            borderTopLeftRadius: propsRef.borderTopRadius.value ? propsRef.borderTopRadius.value : propsRef.borderTopLeftRadius.value,
            borderTopRightRadius: propsRef.borderTopRadius ? propsRef.borderTopRadius.value : propsRef.borderTopRightRadius.value,
            backgroundColor: propsRef.dark.value ? propsRef.darkContentsColor.value : propsRef.contentsColor.value,
            color: propsRef.dark.value ? "white" : "black",
            bottom: `${contentsBottomDistance.value}px`
          },
        }, [
          !propsRef.noTip.value ? h(
            "div",
            {
              class: "modal-contents-chip-wrapper",
              on: {
                touchstart: touchStart,
                touchmove: touchMove,
                touchend: onTouchEnd
              },
              onTouchstart: touchStart,
              onTouchmove: touchMove,
              onTouchend: onTouchEnd
            },
            [
              h("div", {
                class: "modal-contents-chip"
              })
            ]
          ) : null,
          (_b = (_a2 = context.slots).default) == null ? void 0 : _b.call(_a2)
        ]) : null
      ]);
    };
  }
});
export { swipeModal as default, swipeModal };
