<template>
  <div class="day__week">
    <div
      v-for="(day,index) in dayWeek"
      :key="day"
      :class="['day','mobile__cell',{'weekend':index>4}]"
    >
      {{ day }}
    </div>
  </div>
</template>

<script setup>

const dayWeek = [
  'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'
];

</script>

<style scoped>

.day__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.day {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "MTSCompactRegular", "MTS Sans", serif;
}

.weekend {
  color: #626C77;
}

</style>
