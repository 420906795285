<template>
  <div class="switch-container">
    <label class="switch">
      <input
        v-model="isChecked"
        type="checkbox"
        :disabled="disabled"
        @change="toggleSwitch"
      >
      <div class="slider">
        <span class="round">
          <div
            v-if="disabled"
            class="lock"
          />
        </span>
      </div>
    </label>
  </div>
</template>

<script setup>

import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:modelValue', 'onClick']);
const isChecked = ref(props.modelValue);

watch(() => props.modelValue, newValue => {
  isChecked.value = newValue;
});

const toggleSwitch = () => {
  emit('update:modelValue', isChecked.value);
};

</script>

<style>

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-grey-inactive);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.lock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-image: url("../../assets/svg/Lock.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

input:checked + .slider {
  background-color: var(--green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--green);
}

input:checked + .slider .round {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.slider .round {
  border-radius: 34px;
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider .round:before {
  border-radius: 50%;
}
</style>
