export const isDefinedAndNotNull = function (value) {
  return typeof value !== 'undefined' && value !== null;
};

export const shortTextWithEllipsis = (text, maxLength) => {
  if (text.length > maxLength) {
    const diff = text.length - maxLength;
    return text.slice(0, -3 - diff).concat('...');
  }
  return text;
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
};

// ----- Форматеры:
export const mobileFormatted = (mobile) => {
  mobile = mobile.trim();
  const numbers = mobile;
  let result = '';
  let char;

  if (mobile.length === 10) {
    char = { 0: '+7 ', 3: ' ', 6: '-', 8: '-' };
  } else if (mobile.length === 11) {
    char = { 0: '+', 1: ' ', 4: ' ', 7: '-', 9: '-' };
  } else {
    return numbers;
  }

  for (let i = 0; i < numbers.length; i++) {
    result += (char[i] || '') + numbers[i];
  }
  return result;
};

/// если type === 1: 01.12.2022
/// если type === 2: 01 декабря 2022
/// если type === 3: 01.12.22 (по умолчанию, если не передать type)
/// если type === 4: 2022-12-01
/// если type === 5: 1 декабря
/// если type === 6: 1 декабря 2022
export const dateFormatter = (date, type) => {
  const options = {};
  let result;
  if (!type) {
    type = 3;
  }
  if (type === 1) {
    options.day = '2-digit';
    options.month = '2-digit';
    options.year = 'numeric';

    result = new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
  } else if (type === 2) {
    options.day = '2-digit';
    options.month = 'long';
    options.year = 'numeric';

    result = (new Intl.DateTimeFormat('ru-RU', options).format(new Date(date))).slice(0, -3);
  } else if (type === 3) {
    options.day = '2-digit';
    options.month = '2-digit';
    options.year = '2-digit';

    result = new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
  } else if (type === 4) {
    result = `${(new Date(date)).getFullYear()}-${('0' + ((new Date(date)).getMonth() + 1)).slice(-2)}-${('0' + (new Date(date)).getDate()).slice(-2)}`;
  } else if (type === 5) {
    options.day = '2-digit';
    options.month = 'long';
    // options.year='numeric'

    result = new Intl.DateTimeFormat('ru-RU', options).format(new Date(date)).replace(/^0+/, '');
  } else if (type === 6) {
    options.day = '2-digit';
    options.month = 'long';
    options.year = 'numeric';

    result = (new Intl.DateTimeFormat('ru-RU', options).format(new Date(date))).slice(0, -3).replace(/^0+/, '');
  }
  return result;
};

export const timeFormatter = (date) => {
  const options = {};
  options.hour = '2-digit';
  options.minute = '2-digit';
  return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
};

export const getLastDayOfMonth = (year, month) => {
  const date = new Date(year, month + 1, 0);
  return date.getDate();
};

export const getFirstDateOnDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

/// Получает новую дату относительно переданной
/// num - число (сдвиг)
/// type - тип сдвига (D - день, M - месяц, Y - год)
export const getNewDate = (num, type, parDate) => {
  const date = parDate ? new Date(parDate.getFullYear(), parDate.getMonth(), parDate.getDate()) : new Date();

  if (type === 'D') {
    date.setDate(date.getDate() + num);
  } else {
    lastMonthDayTransform(date, num, type);
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

function lastMonthDayTransform (date, num, period) {
  if (period !== 'M' && period !== 'Y') {
    throw 'error type';
  }
  const currentDay = date.getDate();
  const currentMonth = date.getMonth();
  const lastDayinCurrentMonth = getLastDayOfMonth(date.getFullYear(), currentMonth);
  date.setDate(1);
  if (period === 'M') {
    date.setMonth(date.getMonth() + num);
  } else if (period === 'Y') {
    date.setFullYear(date.getFullYear() + num);
  }
  const lastDayinNewMonth = getLastDayOfMonth(date.getFullYear(), date.getMonth());

  if (currentDay > lastDayinNewMonth) {
    date.setDate(lastDayinNewMonth);
  } else if (currentDay < lastDayinNewMonth && currentDay === lastDayinCurrentMonth) {
    date.setDate(lastDayinNewMonth);
  } else {
    date.setDate(currentDay);
  }
}

// part = 1 - знак
// part = 2 - число
// part = 3 - запятая и значение после запятой с точностью 2 знака
export const currencyFormatter = (value, part) => {
  const number = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2 }).format(value);

  return part === 1
    ? number.match(/^(-?)/)[0]
    : part === 2
      ? number.match(/^-?(.+)\,/)[1]
      : number.match(/\,(\d{2})/)[0];
};

export const tariffPeriodFormatter = (period, periodUnit) => {
  const periods = [{ unit: 'M', value: 'мес' }];
  if (period === 12) return 'год';
  return (period === 1 ? '' : period + ' ') + periods.find(el => el.unit === periodUnit).value;
};

// ------------------------

export const diffInDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / oneDay);
};

export const parseDateDocument = (doc) => { // dd-mm-yyyy hh:mm:ss to new Date
  if (!doc.signDate && !doc.contrDate) return 0;

  const field = doc.signDate ? doc.signDate : doc.contrDate;

  const watch = field.split(' ')[1]?.split(':');
  const days = field.split(' ')[0]?.split('.');

  return new Date(`${days[2]}-${days[1]}-${days[0]}T${watch[0].length === 1 ? `0${watch[0]}` : watch[0]}:${watch[1]}:${watch[2]}`);
};

// ввод только цифр
export const keyPressDigit = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode >= 48 && charCode <= 57) {
    return true;
  }
  event.preventDefault();
};

export const keyPressFullName = (event) => {
  const char = event.key;
  if (/^[а-яА-ЯёЁ-]+$/.test(char) || char === ' ') {
    return true;
  }
  event.preventDefault();
};

export const checkValidPassword = (pass) => {
  return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z_!$%&,()?#@^.':\/;+<\->=*\\}{\[\]|№`~"”]{8,30}$/.test(pass);
};
