import { tariffPeriodFormatter } from '@/utils/helpers';

const listDate = new Map([
  ['D', 1],
  ['M', 30],
  ['Y', 365]
]);

export const tariffDateMonthToString = (periodUnit) => {
  switch (periodUnit) {
    case 'D': return 'дня';
    case 'M': return 'месяца';
    case 'Y': return 'года';
  }
};

export const tariffDateDayToString = (day) => {
  switch (day + 1) {
    case 1: return 'С первого';
    case 2: return 'Со второго';
    case 3: return 'С третьего';
    case 4: return 'С четвёртого';
    case 5: return 'С пятого';
    case 6: return 'С шестого';
    case 7: return 'С седьмого';
    case 8: return 'С восьмого';
    case 9: return 'С девятого';
    case 10: return 'С десятого';
    case 11: return 'С одиннадцатого';
    case 12: return 'С двенадцатого';
    default: return `С ${day + 1}`;
  }
};

export const roundDateTariff = (day, unit) => {
  if (unit === 'D' && day < 365) {
    return `${tariffDateDayToString(Math.ceil(day / 31))} ${tariffDateMonthToString('M')}`;
  }

  if (unit === 'D' && day >= 365) {
    return `${tariffDateDayToString(Math.ceil(day / listDate.get('Y')))} ${tariffDateMonthToString('Y')}`;
  }

  if (unit === 'M' && day >= 12) {
    return `${tariffDateDayToString(Math.ceil(day / 12))} ${tariffDateMonthToString('Y')}`;
  }

  return `${tariffDateDayToString(day)} ${tariffDateMonthToString(unit)}`;
};

export const isSaleOnTariff = (tariff) => {
  const result = {
    isSale: false,
    saleValue: ''
  };

  const sale = tariff.daysOfAction * listDate.get(tariff.daysOfActionUnit);
  const nextTariffSale = tariff.tariffNextLifeDuration * listDate.get(tariff.tariffNextLifeDurationUnit);

  if (!sale && !nextTariffSale) return result;

  result.isSale = true;
  if (sale > nextTariffSale) {
    const dayOfAction = roundDateTariff(tariff.daysOfAction, tariff.daysOfActionUnit);
    result.saleValue = `${dayOfAction} – ${tariff.price} ₽/${tariffPeriodFormatter(tariff.period, tariff.periodUnit)}`;
  } else {
    const dayOfAction = roundDateTariff(tariff.tariffNextLifeDuration, tariff.tariffNextLifeDurationUnit);
    result.saleValue = `${dayOfAction} – ${tariff.tariffNextPrice} ₽/${tariffPeriodFormatter(tariff.period, tariff.periodUnit)}`;
  }

  return result;
};
