<template>
  <form
    class="auth-form_account"
    @submit.prevent="onSubmit"
  >
    <div class="auth-form__pre">
      <p class="para">
        <em
          v-if="stage.is(1)"
          class="auth-form__em"
        >Вход по номеру лицевого счёта</em>
        <em
          v-if="stage.is(2)"
          class="auth-form__em"
        >Введите пароль</em>
        <em
          v-if="stage.is(3)"
          class="auth-form__em"
        >
          {{ errorForbbiden.title }}
        </em>
      </p>
      <p
        v-if="stage.is(2)"
        class="para"
      >
        для ЛС №{{ login }}
      </p>
      <p
        v-if="stage.is(3)"
        class="para"
        v-html="errorForbbiden.description"
      />
    </div>

    <div
      v-show="stage.is(1)"
      class="auth-form__input-field"
    >
      <AppInput
        id="input-field-login"
        v-model="login"
        placeholder="Введите логин"
        type="text"
        maxlength="11"
        autocomplete="username"
        :Error="lError"
        :reset="true"
        @blur="lBlur"
        @keyup.enter="lMeta.valid && stage.next(2)"
      >
        <p class="auth-form__input-label">
          <span class="auth-form__input-label-text">Логин</span>
          <AppTooltipNew :max-width="500">
            Логин – это номер вашего лицевого счета для оплаты, указанный в договоре на подключение услуг связи
          </AppTooltipNew>
        </p>
      </AppInput>
    </div>

    <div
      v-show="stage.is(2)"
      class="auth-form__input-field"
    >
      <AppDropdown
        v-if="isViewChipID"
        v-model="typeLogin"
        :available-items="availableTypeLogin"
        class="type__login"
        @select="password = ''"
      />
      <AppInput
        id="input-field-password"
        v-model="password"
        :placeholder="typeLogin.placeholder"
        :type="typeLogin.typeInput"
        maxlength="30"
        autocomplete="current-password"
        :Error="pError"
        @blur="pBlur"
      />
    </div>

    <div class="auth-form__input-group-post">
      <a
        v-if="stage.is(1)"
        class="link"
        href="#"
        @click.prevent="loginModal = true; gtm.push_events({'event': 'vntLogin', 'eventCategory': 'avtorizaciya', 'eventAction': 'element_click', 'eventLabel': 'zabyli_login'})"
      >Забыли логин?</a>
      <a
        v-if="stage.is(2) || (stage.is(3) && errorForbbiden.temporaryBlocking)"
        class="link"
        href="#"
        @click.prevent="passwordModal = !isSubmitting; gtm.push_events({'event': 'vntLogin', 'eventCategory': 'avtorizaciya', 'eventAction': 'element_click', 'eventLabel': 'zabyli_parol'})"
      >Получить пароль</a>
    </div>

    <div class="auth-form__bottom">
      <AppButton
        v-if="stage.is(1)"
        :disabled="!lMeta.valid"
        class="auth-form__submit"
        fluid
        :loading="isCheckLogin"
        @click.prevent="checkLoginSub"
      >
        Далее
      </AppButton>

      <a
        v-if="stage.is(1)"
        class="link link__arrow link__back-phone"
        :href="authMobile"
      >Вход по номеру телефона</a>

      <AppButton
        v-if="stage.is(2)"
        type="submit"
        fluid
        :loading="isSubmitting"
        :disabled="!formMeta.valid"
        class="auth-form__submit"
        @click="gtm.push_events({'event': 'vntLogin', 'eventCategory': 'avtorizaciya', 'eventAction': 'button_click', 'eventLabel': 'voiti'})"
      >
        Войти
      </AppButton>
    </div>

    <div
      v-if="stage.is(2)"
      class="auth-form__footer"
    >
      <p class="auth-form__footer-para para">
        Нажимая кнопку «Войти», вы подтверждаете, что ознакомлены и согласны с условиями
        <a
          class="link auth-form__footer-link"
          href="https://static.mts.ru/upload/images/soglashenie_o_polzovanii_lk.pdf"
          target="_blank"
        >Соглашения о пользовании системой</a>
      </p>
      <a
        class="auth-form__footer-button auth-form__footer-button_back mts-d-block link link_secondary"
        href="#"
        @click.prevent="!isSubmitting && stage.prev(1)"
      >К выбору аккаунта</a>
    </div>

    <div
      v-if="stage.is(3)"
      class="auth-form__footer"
    >
      <a
        class="auth-form__footer-button auth-form__footer-button_back mts-d-block link link_secondary"
        href="#"
        @click.prevent="!isSubmitting && stage.set(1)"
      >К выбору аккаунта</a>
    </div>

    <teleport to="body">
      <app-modal
        v-if="loginModal"
        title="Восстановление логина"
        @close="loginModal = false"
      >
        <login-recovery-modal />
      </app-modal>
      <app-modal
        v-else-if="passwordModal"
        title="Восстановление доступа"
        @close="passwordModal = false"
      >
        <password-recovery-modal
          :prop-login="authType===loginName ? login: ''"
          @cancel="passwordModal = false"
        />
      </app-modal>
    </teleport>
  </form>
</template>

<script>
import { ref, watch } from 'vue';
import { useLoginForm } from '../hooks/login-form';
import { useToggle } from '../hooks/toggle';
import AppModal from '../components/ui/AppModal.vue';
import LoginRecoveryModal from '../components/shared/modals/LoginRecoveryModal.vue';
import PasswordRecoveryModal from '../components/shared/modals/PasswordRecoveryModal.vue';
import AppInput from '@/components/ui/AppInput';
import AppButton from '@/components/ui/AppButton';
import { useGTM } from '@/hooks/gtm';
import AppTooltipNew from '@/components/ui/AppTooltipNew';
import AppDropdown from '@/components/ui/AppDropdown.vue';

export default {
  components: { AppDropdown, AppTooltipNew, AppButton, AppInput, AppModal, LoginRecoveryModal, PasswordRecoveryModal },
  setup () {
    const loginModal = ref(false);
    const passwordModal = ref(false);

    const { toggle: ddToggle } = useToggle(false);
    const toMTSId = encodeURIComponent(`https://login.mts.ru/amserver/oauth2/authorize?scope=${process.env.VUE_APP_SCOPE}&response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&state=${process.env.VUE_APP_STATE}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}`);
    const authMobile = `https://login.mts.ru/amserver/UI/Logout?goto=${toMTSId}`;

    const {
      loginName,
      authTypes,
      selectedAuthType,
      authType,
      login,
      password,
      lError,
      lBlur,
      lMeta,
      pError,
      pBlur,
      isSubmitting,
      onSubmit,
      formMeta,
      setFieldError,
      onAuthTypeItemClick,
      typeLogin,
      availableTypeLogin,
      checkLogin,
      stage,
      isCheckLogin,
      isViewChipID,
      errorForbbiden
    } = useLoginForm();

    const gtm = useGTM();

    watch(authType, async newValue => {
      if (newValue === 'Лицевой счет') {
        gtm.push_events({ event: 'vntLogin', eventCategory: 'avtorizaciya', eventAction: 'element_click', eventLabel: 'licevoi_schet' });
      } else if (newValue === 'ИНН') {
        gtm.push_events({ event: 'vntLogin', eventCategory: 'avtorizaciya', eventAction: 'element_click', eventLabel: 'inn' });
      }
    });

    watch(lError, async newValue => {
      if (newValue) {
        gtm.push_events({ event: 'vntLogin', eventCategory: 'avtorizaciya', eventAction: 'error_show', eventLabel: 'error', eventContent: 'vvod_logina' });
      }
    });

    watch(pError, async newValue => {
      if (newValue) {
        gtm.push_events({ event: 'vntLogin', eventCategory: 'avtorizaciya', eventAction: 'error_show', eventLabel: 'error', eventContent: 'vvod_parolya' });
      }
    });

    const checkLoginSub = () => {
      checkLogin();
      gtm.push_events({ event: 'vntLogin', eventCategory: 'avtorizaciya', eventAction: 'button_click', eventLabel: 'dalee' });
    };

    return {
      loginName,
      authTypes,
      selectedAuthType,
      authType,
      login,
      password,
      lError,
      lBlur,
      lMeta,
      pError,
      pBlur,
      isSubmitting,
      onSubmit,
      formMeta,
      setFieldError,
      stage,
      ddToggle,
      onAuthTypeItemClick,
      loginModal,
      passwordModal,
      authMobile,
      gtm,
      typeLogin,
      availableTypeLogin,
      checkLoginSub,
      isCheckLogin,
      isViewChipID,
      errorForbbiden
    };
  }
};
</script>
<style scoped>
.auth-form__input-label {
  display: flex;
  align-items: center;
}
.auth-form__input-label-text {
  margin-right: 4px;
}

.link__back-phone{
  margin-top: 20px;
}

.type__login {
  margin-bottom: 15px;
}
</style>
