import { ref } from 'vue';

export const useLocalStorage = (key, initialValue) => {
  const item = window.localStorage.getItem(key);
  const storage = ref(null);

  const setValue = (value) => {
    storage.value = value;
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  if (item) {
    storage.value = JSON.parse(item);
  } else {
    setValue(initialValue);
  }

  return { storage, setValue };
};
