import { ref } from 'vue';

export function usePaginate (refreshCallback, pageSizeProps = 10) {
  const page = ref(1); // номер страницы
  const pageSize = ref(pageSizeProps); // кол-во записей на странице
  const pageCount = ref(0); // кол-во страниц (нужно вычислять)
  const allItems = ref([]); // данные с сервера, преобразованные постранично
  const items = ref([]); // данные для отображения на конкретной странице
  const totalRows = ref(0);

  const setupPagination = (values) => {
    for (let i = 0; i < values.length; i += pageSize.value) {
      allItems.value.push(values.slice(i, i + pageSize.value));
    }

    pageCount.value = allItems.value.length;
    items.value = allItems.value[page.value - 1] || allItems.value[0];
    totalRows.value = values.length;

    refreshCallback?.(items.value);
  };

  const reset = (rows, newPageSize) => {
    pageSize.value = newPageSize ?? 10;
    page.value = 1;
    allItems.value = [];
    items.value = [];

    setupPagination(rows);
  };

  const pageChangeHandler = (newPage) => {
    items.value = allItems.value[newPage - 1] || allItems.value[0];
    refreshCallback?.(items.value);
  };

  const pageSizeChangeHandler = (newPageSize) => {
    const rows = [];
    allItems.value.forEach(subarray => subarray.forEach(
      row => rows.push(row))
    );
    reset(rows, newPageSize);
  };

  return { setupPagination, reset, items, pageCount, page, pageSize, pageChangeHandler, pageSizeChangeHandler, totalRows };
}
