import { createStore, createLogger } from 'vuex';
import auth from './modules/auth.module';
import user from './modules/user.module';
import settings from './modules/settings.module';
import installment from './modules/installment.module';
import resource from './modules/resource.module';
import payments from './modules/payments.module';
import tariffs from './modules/tariffs.module';
import finance from './modules/finance.module';
import services from './modules/services.module';
import cms from './modules/cms.module';

const plugins = [];

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger());
}

export default createStore({
  plugins,
  state () {
    return {
      startAppDate: new Date()
    };
  },
  getters: {
    startAppDate (state) {
      return state.startAppDate;
    }
  },
  mutations: {
    clear (state) {
      state.startDateApp = null;
    }

  },
  actions: {
    reset ({ commit }) {
      commit('clear');
      commit('auth/clear');
      commit('user/clear');
      commit('settings/clear');
      commit('installment/clear');
      commit('resource/clear');
      commit('payments/clear');
      commit('tariffs/clear');
      commit('finance/clear');
      commit('services/clear');
    }
  },
  modules: {
    auth,
    user,
    settings,
    installment,
    resource,
    payments,
    tariffs,
    finance,
    services,
    cms
  }
});
