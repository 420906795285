<template>
  <div class="popup__body">
    <p class="para popup__para">
      Логин – это номер вашего лицевого счета, указанный в договоре. Если у вас установлено оборудование, вы можете
      <a
        class="link"
        href="https://lk.ug.mts.ru/#/satellite_account"
        target="_blank"
        @click="gtm.push_events({'event': 'vntVoss', 'eventCategory': 'vosstanovlenie_logina', 'eventAction': 'element_click', 'eventLabel': 'uznat_nomer_licevogo_scheta'})"
      >узнать номер лицевого счета </a>самостоятельно.
    </p>
    <p class="para popup__para">
      Для восстановления логина можно обратиться в контактный центр 8 800 250 2320, при этом будьте готовы назвать паспортные данные владельца договора
    </p>
    <a
      class="mts-d-none-t button button_primary button_small"
      href="tel:88002502320"
      @click="gtm.push_events({'event': 'vntVoss', 'eventCategory': 'vosstanovlenie_logina', 'eventAction': 'button_click', 'eventLabel': 'pozvonit_v_kontaktnyi_centr'})"
    >Позвонить в контактный центр</a>
  </div>
</template>

<script setup>
import { useGTM } from '@/hooks/gtm';

const gtm = useGTM();
</script>

<style>

</style>
