<template>
  <div class="auth__form">
    <div class="auth__form-logo">
      <img
        src="../assets/svg/MTS-ID-Logo.svg"
        alt="Логотип"
      >
    </div>
    <h2 class="auth__form-title">
      Системе не удалось найти данные профиля
    </h2>
    <p class="auth__form-description">
      Вы можете войти в личный кабинет Спутникового ТВ по номеру лицевого счета
    </p>
    <AppButton
      fluid
      class="auth__form-button"
      @click="router.push('/auth')"
    >
      Войти по лицевому счету
    </AppButton>
    <a
      class="link auth__form-another-tel"
      :href="authMobile"
    >
      Войти с другим номером
    </a>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import AppButton from '@/components/ui/AppButton';

const authMobile = `https://login.mts.ru/amserver/UI/Logout?goto=${process.env.VUE_APP_URI}`;
const router = useRouter();
</script>

<style scoped>

.auth__form-button {
  padding: 14px;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 32px;
}

</style>
