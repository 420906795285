import axios from '../../axios/mainAxios';

export default {
  namespaced: true,
  state: {

  },
  mutations: {
    setAvailable (state, data) {
      state.availableTariffs = data;
    },
    setPlannedChanges (state, data) {
      state.plannedChanges = data;
    },
    addCreatedTask (state, data) {
      if (state.plannedChanges?.resId.toString() === data.resId.toString()) {
        state.plannedChanges.data.push(data.task);
      }
    },
    deleteTask (state, payload) {
      if (state.plannedChanges?.data?.length) {
        state.plannedChanges.data = state.plannedChanges.data.filter(t => t.taskId !== payload.taskId);
      }
    },
    clear (state) {
      state.availableTariffs = null;
      state.plannedChanges = null;
    }
  },
  actions: {
    async loadAvailable ({ commit }, payload) {
      try {
        const { data } = await axios.get('/tariff', { params: payload });
        commit('setAvailable', { resId: payload.resId, data });
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async getPaidChange (_, payload) {
      try {
        const { data } = await axios.get('/tariff/change_cost', { params: payload });
        return data;
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async plannedTariffChangesLoad ({ commit }, payload) {
      try {
        const { data } = await axios.get('/tariff/tasks', { params: payload });
        commit('setPlannedChanges', { resId: payload.resId, data });
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async tariffChangeCreate ({ commit }, payload) {
      try {
        const { data } = await axios.post('/tariff/tasks', payload);
        commit('addCreatedTask', { resId: payload.resId, task: data });
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    },
    async tariffChangeDelete ({ commit }, payload) {
      try {
        await axios.delete('/tariff/tasks', { params: payload });
        commit('deleteTask', payload);
      } catch (e) {
        throw { status: e.response.status, data: e.response.data };
      }
    }
  },
  getters: {
    availableTariffs (state) {
      return state.availableTariffs;
    },
    plannedChanges (state) {
      return state.plannedChanges;
    }
  }
};
