<template>
  <div
    v-if="breadcrumb?.length"
    class="page__breadcrumbs"
  >
    <a
      v-for="item in breadcrumb"
      :key="item.name"
      href=""
      :class="['page__breadcrumbs-item', { 'page__breadcrumbs-item_current': item.active }]"
      @click.prevent="onClicked(item)"
    >{{ item.name }}</a>
  </div>
</template>

<script setup>

import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/dist/vue-router';
const route = useRoute();
const router = useRouter();
const breadcrumb = computed(() => route.meta.breadcrumb);

const onClicked = (item) => {
  router.push({ name: item.routeName });
};

</script>

<style scoped>

</style>
