import { reactive, onBeforeMount } from 'vue';
import { isDefinedAndNotNull } from '../utils/helpers';

export function useToggle (state) {
  const toggle = reactive({
    state: false,

    init: function (state) {
      this.state = state || false;

      return this;
    },

    is: function (state) {
      return isDefinedAndNotNull(state) ? state === this.state : this.state;
    },

    on: function () {
      this.state = true;

      return this;
    },

    off: function () {
      this.state = false;

      return this;
    },

    switch: function () {
      this.state = !this.state;

      return this;
    }
  });

  onBeforeMount(() => {
    toggle.init(state);
  });

  return { toggle };
}
